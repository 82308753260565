import React from 'react';

import PropTypes from 'prop-types';

import { Text, Title } from '@asteria/component-core/typography';

import { Action as BaseAction } from '@asteria/component-actionbar';

import { Translation } from '@asteria/language';

const ActionLink = React.memo(function ActionLink(props) {
	const { id, loading, icon, data, onClick, className } = props;

	return (
		<BaseAction
			variant="link"
			icon={icon}
			onClick={onClick}
			loading={loading}
			className={className}
			title={
				<Translation
					translationKey={[
						'actions.action.title',
						`actions.action.${id}.title`,
						'actions.action.link.title',
						`actions.action.link.${id}.title`,
					]}
					translationOptions={{ data: data }}
					Component={Title}
				/>
			}
			content={
				<Translation
					translationKey={[
						'actions.action.content',
						`actions.action.${id}.content`,
						'actions.action.link.content',
						`actions.action.link.${id}.content`,
					]}
					translationOptions={{ data: data }}
					Component={Text}
				/>
			}
		/>
	);
});

ActionLink.propTypes = {
	id: PropTypes.string,
	loading: PropTypes.bool,
	icon: PropTypes.string,
	data: PropTypes.object,
	onClick: PropTypes.func,
	className: PropTypes.string,
};

export default ActionLink;
