import React from 'react';

import PropTypes from 'prop-types';

import { Title } from '@asteria/component-core/typography';

import IntegrationBox, {
	StatusIntegrationBox,
} from '@asteria/component-integrations-v2/components/box';
import MissingIntegrationSection from '@asteria/component-integrations-v2/components/missing';
import { SearchWrapper as OnboardingSearchWrapper } from '@asteria/component-integrations-v2/components/search';

import { Translation } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const Connected = React.memo(function Connected(props) {
	const { integrations, onAction, onSubmit, onOpen } = props;

	if (!integrations.length) {
		return null;
	}

	return (
		<div className="flex flex-col gap-4">
			<Translation
				translationKey="view.integrations.section.title"
				translationOptions={{
					postfix: { section: 'connected' },
					data: { count: integrations.length },
				}}
				Component={Title}
				size="xs"
			/>
			<div className="grid md:grid-cols-2 gap-4">
				{integrations.map((object) => (
					<StatusIntegrationBox
						key={object?._id ?? object?.id}
						id={object?._id ?? object?.id}
						type={object?.type}
						name={object?.key ?? object?.name}
						onAction={onAction}
						onSubmit={onSubmit}
						onOpen={onOpen}
						variant="status"
					/>
				))}
			</div>
		</div>
	);
});

Connected.propTypes = {
	integrations: PropTypes.arrayOf(PropTypes.object),
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onOpen: PropTypes.func,
};

const Available = React.memo(function Available(props) {
	const { connected, available, onAction, onSubmit, onOpen } = props;

	const exists = !!connected?.length;

	if (!connected?.length && !available?.length) {
		return null;
	}

	return (
		<div className="flex flex-col gap-4">
			{exists ? (
				<Translation
					translationKey="view.integrations.section.title"
					translationOptions={{ postfix: { section: 'available' } }}
					Component={Title}
					size="xs"
				/>
			) : null}

			<div className="grid md:grid-cols-2 gap-4">
				{available.map((object) => (
					<IntegrationBox
						key={[object?.type, object?.key].join('-')}
						type={object?.type}
						name={object?.key}
						onAction={onAction}
						onSubmit={onSubmit}
						onOpen={onOpen}
						variant="connect"
					/>
				))}
			</div>
		</div>
	);
});

Available.propTypes = {
	available: PropTypes.arrayOf(PropTypes.object),
	connected: PropTypes.arrayOf(PropTypes.object),
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onOpen: PropTypes.func,
};

const OnboardingGrid = React.memo(function OnboardingGrid(props) {
	const { className, onAction, onSubmit } = props;

	const onOpenConnected = React.useCallback(
		({ integration }) => {
			const id = integration?._id ?? integration?.id;

			return onAction?.('go', `/onboarding/${id}`);
		},
		[onAction],
	);

	const onOpenAvailable = React.useCallback(
		({ flow }) => {
			const type = flow?.type;
			const key = flow?.key;

			return onAction?.('go', `/onboarding/${type}/${key}`);
		},
		[onAction],
	);

	return (
		<div className={cn('asteria-component__onboarding-grid', className)}>
			<OnboardingSearchWrapper
				onAction={onAction}
				onSubmit={onSubmit}
				type="erp"
			>
				{({ available, connected }) => (
					<div className="flex flex-col gap-8">
						<Connected
							integrations={connected}
							onAction={onAction}
							onSubmit={onSubmit}
							onOpen={onOpenConnected}
						/>
						<Available
							available={available}
							connected={connected}
							onAction={onAction}
							onSubmit={onSubmit}
							onOpen={onOpenAvailable}
						/>
						<MissingIntegrationSection
							onAction={onAction}
							onSubmit={onSubmit}
						/>
					</div>
				)}
			</OnboardingSearchWrapper>
		</div>
	);
});

OnboardingGrid.displayName = 'OnboardingGrid';

OnboardingGrid.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default OnboardingGrid;
