import React from 'react';

import { ViewportList } from 'react-viewport-list';

import PropTypes from 'prop-types';

import { getItemBoundingClientRect } from '@asteria/component-core/utils/viewportList';

import { cn } from '@asteria/utils-funcs/classes';

import Row from './row';

/**
 * @typedef Props
 * @property { unknown[] } data
 * @property { unknown[] } columns
 * @property { unknown } viewportRef
 * @property { 'sm' | 'md' | 'lg' } size
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 */

function renderSpacer({ ref, style }) {
	return (
		<div
			className={cn(
				'col-span-full',
				'asteria-component__viewport-spacer',
				'asteria-component__prints-table-spacer',
			)}
			ref={ref}
			style={style}
		/>
	);
}

/** @type { React.FC<Props> } */
const PrintsTableContent = React.memo(function PrintsTableContent(props) {
	const { data, viewportRef, onAction, onSubmit, columns, size } = props;

	return (
		<ViewportList
			items={data}
			viewportRef={viewportRef}
			itemSize={60}
			overscan={10}
			scrollThreshold={60}
			getItemBoundingClientRect={getItemBoundingClientRect}
			renderSpacer={renderSpacer}
		>
			{(object) => (
				<Row
					key={object?._id ?? object.id}
					value={object}
					onAction={onAction}
					onSubmit={onSubmit}
					columns={columns}
					size={size}
				/>
			)}
		</ViewportList>
	);
});

PrintsTableContent.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object),
	columns: PropTypes.arrayOf(PropTypes.object),
	viewportRef: PropTypes.object,
	size: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default PrintsTableContent;
