import React from 'react';

import { useStore } from 'react-redux';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Spinner from '@asteria/component-core/spinner';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';

import LayoutContext from '../../../layout/context';

import './styles.scss';

const VariantLoading = () => {
	const { onSubmit } = React.useContext(LayoutContext);

	const store = useStore();

	const onRemove = React.useCallback(() => {
		const object = IntegrationStore.selectors.integrations(
			store.getState(),
			{
				type: 'erp',
				status: 'INITIATING',
			},
		)?.[0];

		return onSubmit?.('integrations:delete', object);
	}, [onSubmit, store]);

	return [
		<TextGroup key="title" className="mb-4">
			<Title size="page-title">
				{TranslationService.get([
					`page.invoices.title`,
					`page.invoices.loading.title`,
				])}
			</Title>
			<Text size="lg">
				{TranslationService.get([
					`page.invoices.content`,
					`page.invoices.loading.content`,
				])}
			</Text>
		</TextGroup>,
		<div key="content" className="asteria-page__wrapper">
			<div className="asteria-page__placeholder">
				<Group
					direction="horizontal"
					verticalAlign="center"
					horizontalAlign="center"
				>
					<Title size="sm" align="center">
						{TranslationService.get([
							'page.invoices.placeholder.title',
							'page.invoices.loading.placeholder.title',
						])}
					</Title>
				</Group>
				<Text align="center">
					{TranslationService.get([
						'page.invoices.placeholder.content',
						'page.invoices.loading.placeholder.content',
					])}
				</Text>
				<div className="asteria-page__invoices__action-loading">
					<Group flex verticalAlign="center" horizontalAlign="center">
						<Spinner />
						<Text size="xs">
							{TranslationService.get([
								'actions.action.loading',
								'actions.action.integration.loading',
								'invoices.actions.loading',
							])}
						</Text>
					</Group>

					<Button
						icon="close"
						variant="tertiary"
						size="sm"
						onClick={onRemove}
					/>
				</div>
			</div>
		</div>,
	];
};

VariantLoading.displayName = 'VariantLoading';

VariantLoading.propTypes = {};

export default VariantLoading;
