import React from 'react';

import { useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, { Content, Header } from '@asteria/component-core/wrapper';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';

import Actions from '../../../Actions';
import ServerError from '../../../server-error';
import ReviewTable from '../../Table/ReviewTable';
import { INVOICE_FIELDS } from '../constants';
import { useBatchFilters, useFilters } from '../hooks';
import { useInvoiceFilters } from '../hooks';

import ChipFilter from './chip-filter';
import ReviewContentFooter from './footer';

import '../styles.scss';

const ReviewModalContent = React.memo(function ReviewModalContent(props) {
	const { onClose, onAction, onSubmit, id } = props;

	const [excluded, setExcluded] = React.useState([]);

	const batch = useSelector(
		(store) => InvoiceStore.selectors.batch(store, id),
		(a, b) => isEqual(a, b),
	);

	const { data: invoices, isFetching } = useQuery({
		queryKey: ['batch', id, 'invoices'],
		queryFn: async () =>
			onSubmit?.('invoices:available', {
				filters: {
					ids: (batch?.invoices ?? []).map(
						({ invoiceId }) => invoiceId,
					),
				},
				pageFilters: { first: 0 },
			}),

		select: (response) => (response?.edges ?? []).map(({ node }) => node),

		// refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		retry: false,

		placeholderData: [],
	});

	const excludedInvoices = React.useMemo(() => {
		return invoices?.filter((object) => !excluded?.includes(object?.id));
	}, [excluded, invoices]);

	const { filters, setFilters } = useFilters();

	const filteredInvoices = useInvoiceFilters({
		invoices: excludedInvoices,
		filters: filters,
	});

	const filteredBatch = useBatchFilters({
		batch: batch,
		invoices: filteredInvoices,
	});

	const actions = React.useMemo(
		() => [
			{
				id: 'batch',
				variant: 'link',
				path: `/invoices/batch/${id}`,
			},
		],
		[id],
	);

	const handleAction = React.useCallback(
		async (action, data) => {
			if (action === 'invoices:batch:remove') {
				const response = await onAction?.(action, data);

				onClose?.();

				return response;
			}

			if (action === 'transaction:exclude') {
				return setExcluded((prev) => [...prev, data]);
			}

			if (action === 'filter:status') {
				return setFilters({ status: data });
			}

			return onAction?.(action, data);
		},
		[onAction, onClose, setFilters],
	);

	return (
		<Wrapper scroll>
			<Header onClose={onClose}>
				{TranslationService.get(
					[
						'invoice.history.row.title',
						`invoice.history.row.${batch?.status?.toLowerCase?.()}.title`,
					],
					undefined,
					filteredBatch,
				)}
			</Header>
			<Content scroll>
				{batch?.status === 'SENT' ? (
					<Actions
						onAction={onAction}
						onSubmit={onSubmit}
						actions={actions}
					/>
				) : null}

				<div className="asteria-page__placeholder">
					<Group
						direction="horizontal"
						verticalAlign="center"
						horizontalAlign="center"
					>
						<Icon icon="document" />
						<Title size="sm" align="center">
							{TranslationService.get(
								[
									'page.invoices.placeholder.title',
									'page.invoices.review.placeholder.title',
									`page.invoices.review.modal.placeholder.title`,
									`page.invoices.review.modal.${batch?.status?.toLowerCase?.()}.placeholder.title`,
								],
								null,
								filteredBatch,
							)}
						</Title>
					</Group>
					<Text align="center">
						{TranslationService.get(
							[
								'page.invoices.placeholder.subtext',
								'page.invoices.review.placeholder.subtext',
								`page.invoices.review.modal.placeholder.subtext`,
								`page.invoices.review.modal.${batch?.status?.toLowerCase?.()}.placeholder.subtext`,
							],
							null,
							filteredBatch,
						)}
					</Text>
					<Text align="center">
						{TranslationService.get(
							[
								'page.invoices.placeholder.content',
								'page.invoices.review.placeholder.content',
								`page.invoices.review.modal.placeholder.content`,
								`page.invoices.review.modal.${batch?.status?.toLowerCase?.()}.placeholder.content`,
							],
							null,
							filteredBatch,
						)}
					</Text>
					{/* <Button
							variant="link"
							label={TranslationService.get(
								[
									'page.invoices.placeholder.link',
									'page.invoices.review.placeholder.link',
									`page.invoices.review.modal.placeholder.link`,
									`page.invoices.review.modal.${batch?.status?.toLowerCase?.()}.placeholder.link`,
								],
								null,
								batch,
							)}
						/> */}
				</div>
				<div className="asteria-page__invoices_review__content">
					<Title size="xxs" align="left">
						{TranslationService.get(
							[
								'page.invoices.review.title',
								'page.invoices.review.modal.title',
								`page.invoices.review.modal.${batch?.status?.toLowerCase?.()}.title`,
							],
							null,
							filteredBatch,
						)}
					</Title>

					<ServerError
						type="batch"
						errors={filteredBatch?.data?.errors}
						onAction={handleAction}
						onSubmit={onSubmit}
						extra={{ batch: filteredBatch }}
					/>

					<div className="flex flex-row gap-2 py-2">
						<ChipFilter
							invoices={excludedInvoices}
							type="ALL"
							onAction={handleAction}
							filters={filters}
						/>
						<ChipFilter
							invoices={excludedInvoices}
							type="ERROR"
							onAction={handleAction}
							filters={filters}
						/>
					</div>

					<ReviewTable
						invoices={filteredInvoices}
						onAction={handleAction}
						fields={INVOICE_FIELDS}
						loading={isFetching}
					/>
				</div>
			</Content>
			<ReviewContentFooter
				id={id}
				batch={filteredBatch}
				invoices={invoices}
				excludedInvoices={excludedInvoices}
				onClose={onClose}
				onSubmit={onSubmit}
				onAction={handleAction}
			/>
		</Wrapper>
	);
});

ReviewModalContent.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	invoices: PropTypes.arrayOf(PropTypes.object),
	id: PropTypes.string,
};

export default ReviewModalContent;
