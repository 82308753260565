import React from 'react';

import PropTypes from 'prop-types';

import Icon from '@asteria/component-core/icon';
import Slider from '@asteria/component-core/slider';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';
import { isPossibleToClick } from '@asteria/component-core/utils';

import { Translation } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import PrintPage from './page';

import './styles.scss';

/**
 * @typedef Print
 * @property { string } id
 * @property { string } status
 * @property { string } createdAt
 *
 * @typedef Props
 * @property { string } className
 * @property { Print[] } prints
 * @property { boolean } description
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 */

/** @type { React.FC<Pick<Props, 'onAction' | 'onSubmit'> & { print: Print, status: Print['status'] | 'WARNING' }> } */
const PrintPageWrapper = React.memo(function PrintPageWrapper(props) {
	const { print, onAction, status } = props;

	const ref = React.useRef(null);

	const id = print?._id ?? print?.id;

	const onPrintClick = React.useCallback(
		(event) => {
			if (!isPossibleToClick(event, ref.current)) {
				return;
			}

			return onAction?.('print:open', { id });
		},
		[id, onAction],
	);

	return (
		<>
			<div
				key={id}
				className={cn(
					'overflow-hidden shrink-0 flex flex-col gap-4 items-center justify-center w-24 cursor-pointer py-8',
					'asteria-component__print-pages-column',
					{ [`asteria--status-${status}`]: status },
				)}
				onClick={onPrintClick}
				ref={ref}
			>
				<PrintPage status={status} className="py-8" />
				<TextGroup className="truncate w-full">
					<div className="flex gap-1 items-center">
						<Icon
							icon="document"
							className="asteria-component__print-pages-column-icon"
						/>
						<Translation
							translationKey="print.page.title"
							translationOptions={{
								postfix: { status: status },
							}}
							data={print}
							Component={Title}
							align="center"
							className={cn('truncate text-content-page-title')}
							size="xxs"
						/>
					</div>
					<Translation
						translationKey="print.page.date"
						translationOptions={{
							postfix: { status: status },
						}}
						data={print}
						Component={Text}
						align="center"
						className="truncate"
						size="xs"
					/>
					<Translation
						translationKey="print.page.content"
						translationOptions={{
							postfix: { status: status },
						}}
						data={print}
						Component={Text}
						align="center"
						className="truncate"
						size="xs"
					/>
				</TextGroup>
			</div>
		</>
	);
});

PrintPageWrapper.propTypes = {
	print: PropTypes.object,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	status: PropTypes.string,
};

function usePrintStatus(prints) {
	let status = 'PROCESSED';

	for (const print of prints) {
		const invoices = (print?.invoices ?? []).filter(
			(invoice) => !invoice?.discard?.length,
		);

		if (print?.status === 'ERROR') {
			const isError = invoices.every(
				(invoice) => invoice?.errors?.length,
			);

			if (isError) {
				status = 'ERROR';
				break;
			}

			status = 'WARNING';
		}

		if (status !== 'WARNING') {
			if (print?.status !== 'PROCESSED') {
				status = 'LOADING';
			}
		}
	}

	return React.useMemo(() => status, [status]);
}

/** @type { React.FC<Props> } */
const PrintPages = React.memo(function PrintPages(props) {
	const { className, prints, description, onAction, onSubmit } = props;

	const status = usePrintStatus(prints);

	if (!prints?.length) {
		return null;
	}

	return (
		<div
			className={cn(
				'bg-[#F8F8F4] overflow-hidden',
				'asteria-component__print-pages',
				className,
			)}
		>
			{description ? (
				<TextGroup className="px-12 pt-4">
					<Translation
						translationKey="print.page.wrapper.description"
						translationOptions={{
							postfix: { type: 'title', status },
						}}
						Component={Title}
						size="sm"
					/>
					<Translation
						translationKey="print.page.wrapper.description"
						translationOptions={{
							postfix: { type: 'content', status },
						}}
						Component={Text}
					/>
				</TextGroup>
			) : null}

			<Slider className={cn('px-12 gap-4')}>
				{prints.map((print) => {
					let status = print?.status;

					if (status === 'ERROR') {
						if (
							(print?.invoices ?? []).some(
								(invoice) => !invoice?.errors?.length,
							)
						) {
							status = 'WARNING';
						}
					}

					return (
						<PrintPageWrapper
							key={print?._id ?? print?.id}
							print={print}
							status={status}
							onAction={onAction}
							onSubmit={onSubmit}
						/>
					);
				})}
			</Slider>
		</div>
	);
});

PrintPages.displayName = 'PrintPages';

PrintPages.propTypes = {
	className: PropTypes.string,
	description: PropTypes.bool,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	prints: PropTypes.arrayOf(PropTypes.object),
};

export default PrintPages;
