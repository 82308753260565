import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';
import Group from '@asteria/component-core/group';
import { isPossibleToClick } from '@asteria/component-core/utils';

import { Checkbox } from '@asteria/component-form';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

/**
 * @typedef PropsItem
 * @property { string } currency
 * @property { (event: { value: string }) => void } onChange
 */

/** @type { React.FC<PropsItem> } */
const SearchCurrencyItem = React.memo(function SearchCurrencyItem(props) {
	const { currency, onChange } = props;

	const ref = React.useRef(null);

	const selected = useSelector(
		(store) =>
			!!InvoiceStore.selectors.filters(store, {
				type: 'CURRENCY',
				value: currency,
			}).length,
	);

	const onClick = React.useCallback(
		(event) => {
			if (!isPossibleToClick(event, ref.current)) {
				return;
			}

			return onChange?.({ value: currency });
		},
		[currency, onChange],
	);

	return (
		<DropdownItem
			prefix={
				<Group
					verticalAlign="center"
					horizontalAlign="center"
					className="pointer-events-none"
				>
					<Checkbox uncontrolled checked={selected} />
				</Group>
			}
			onClick={onClick}
			ref={ref}
		>
			{currency}
		</DropdownItem>
	);
});

SearchCurrencyItem.propTypes = {
	currency: PropTypes.string,
	onChange: PropTypes.func,
};

function useAvailable(type, data) {
	if (type === 'invoices') {
		return Array.from(
			new Set(
				(data ?? []).map(
					(invoice) => invoice?.sums?.original?.currency,
				),
			),
		);
	}

	if (type === 'prints') {
		return Array.from(
			new Set(
				(data ?? [])
					.flatMap((print) => print?.invoices ?? [])
					.map((invoice) => invoice?.sums?.original?.currency),
			),
		);
	}

	return [];
}

/** @type { React.FC<import('./types').BaseProps> } */
const SearchCurrency = React.memo(function SearchCurrency(props) {
	const { className, type, data } = props;

	const dispatch = useDispatch();

	const currencies = useAvailable(type, data);

	const filters = useSelector(
		(store) => InvoiceStore.selectors.filters(store, { type: 'CURRENCY' }),
		isEqual,
	);

	const handleChange = React.useCallback(
		(event) => {
			dispatch(
				InvoiceStore.filter({ type: 'CURRENCY', value: event.value }),
			);
		},
		[dispatch],
	);

	const ToggleProps = React.useMemo(
		() => ({
			variant: 'select',
			label: TranslationService.get(
				[
					'invoices.search.currencies.placeholder',
					filters.length
						? 'invoices.search.currencies.value.placeholder'
						: null,
				],
				undefined,
				{ filters: filters },
			),
			icon: 'chevron-down',
			iconPosition: 'last',
		}),
		[filters],
	);

	if (currencies.length < 2) {
		return null;
	}

	return (
		<div
			className={cn(
				'asteria-component__invoice-search-part',
				'asteria--type-currency',
				className,
			)}
		>
			<Dropdown toggle={ToggleProps} scroll>
				{currencies.map((currency) => (
					<SearchCurrencyItem
						key={currency}
						currency={currency}
						onChange={handleChange}
					/>
				))}
			</Dropdown>
		</div>
	);
});

SearchCurrency.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	data: PropTypes.arrayOf(PropTypes.object),
};

export default SearchCurrency;
