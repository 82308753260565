import React from 'react';

import PropTypes from 'prop-types';

import { TranslationService } from '@asteria/language';

/**
 * @typedef Props
 * @property { import('react').ReactNode } as
 * @property { string } name
 * @property { boolean } disabled
 * @property { unknown } invoice
 * @property { boolean } v2
 * @property { string | string[] } label
 */

/** @type { React.FC<Props> } */
const Field = React.memo(function Field(props) {
	const { as: As, name, disabled, invoice, v2, label, ...rest } = props;

	const error = (invoice?.errors ?? []).find(({ path }) => path === name);

	const nextLabel = TranslationService.get(
		[]
			.concat(label)
			.concat([`invoice.edit.field.${name}.label`])
			.filter(Boolean),
	);

	return (
		<As
			name={`service.invoice.${name}`}
			label={v2 ? { position: 'left', label: nextLabel } : nextLabel}
			disabled={disabled}
			error={error ? { ...error, type: 'error' } : null}
			{...rest}
		/>
	);
});

Field.propTypes = {
	as: PropTypes.node,
	name: PropTypes.string,
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
	disabled: PropTypes.bool,
	invoice: PropTypes.object,
	v2: PropTypes.bool,
};

export default Field;
