import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Icon from '@asteria/component-core/icon';
import { Text, Title } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { usePrintsFilters, usePrintsQuery, usePrintsStatus } from './hooks';
import PrintPages from './pages';
import PrintStatus from './status';
import { getPrintsTimestamp } from './utils';

/**
 * @typedef Props
 * @property { unknown } data
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 */

/** @type { React.FC<Props> } */
const PrintStatusList = React.memo(function PrintStatusList(props) {
	const { onAction, onSubmit } = props;

	const [done, setDone] = React.useState(false);

	const timestamp = useSelector((store) => getPrintsTimestamp(store));

	const query = usePrintsQuery({ onAction, onSubmit, timestamp });
	const data = usePrintsFilters(query.data, true);

	const handleSubmit = React.useCallback(
		(action, data) => {
			if (action === 'print:status:done') {
				setDone(true);
			}

			return onSubmit?.(action, data);
		},
		[onSubmit],
	);

	const status = usePrintsStatus(query.data);

	if (done || !query?.data?.length) {
		return (
			<div className="flex flex-col p-8 bg-alert-success-normal-background items-center justify-center">
				<div className="flex gap-2 items-center justify-center">
					<div
						className={cn(
							'asteria-component__alert-icon',
							'asteria--status-success',
						)}
					>
						<Icon icon="printer" />
					</div>
					<Translation
						translationKey="page.prints.alert.title"
						Component={Title}
						align="center"
						className="text-content-page-title"
					/>
				</div>
				<Translation
					translationKey="page.prints.alert.content"
					Component={Text}
					align="center"
				/>
			</div>
		);
	}

	return (
		<>
			<PrintPages
				onAction={onAction}
				onSubmit={handleSubmit}
				prints={data}
				filterable
				description
			/>
			<PrintStatus
				className="border-0 border-y border-solid border-border-normal"
				onAction={onAction}
				onSubmit={handleSubmit}
				from="page"
				status={status}
				done
			/>
		</>
	);
});

PrintStatusList.displayName = 'PrintStatusList';

PrintStatusList.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object),
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default PrintStatusList;
