import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { TableCell } from '@asteria/component-core/table';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { getSortingIcon } from './utils';

/**
 * @typedef Props
 * @property { string } type
 * @property { { key: string, direction: string } } sorting
 * @property { (id: string) => void } sortBy
 * @property { unknown[] } data
 * @property { boolean } sortable
 * @property { 'sm' | 'md' | 'lg' } size
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 */

/** @type { React.FC<Props> } */
const TableHeaderCell = React.memo(function TableHeaderCell(props) {
	const { type, sorting, sortBy, data, sortable, size } = props;

	let btnProps = {
		label: TranslationService.getV2(['prints.table.header.cell'], {
			postfix: { type: type },
			data: { data: data },
		}),
	};

	const onClick = React.useCallback(() => sortBy(type), [sortBy, type]);

	if (sortable) {
		btnProps = {
			...btnProps,
			onClick: onClick,
			icon: 'chevron-down',
			iconActive: getSortingIcon(sorting),
			active: sorting?.key === type,
			iconPosition: 'last',
		};
	}

	if (type === 'actions') {
		return (
			<TableCell className={cn({ [`asteria--type-${type}`]: type })} />
		);
	}

	return (
		<TableCell className={cn({ [`asteria--type-${type}`]: type })}>
			<Button {...btnProps} size={size} />
		</TableCell>
	);
});

TableHeaderCell.propTypes = {
	type: PropTypes.string,
	sorting: PropTypes.object,
	sortBy: PropTypes.func,
	data: PropTypes.object,
	sortable: PropTypes.bool,
	size: PropTypes.string,
};

export default TableHeaderCell;
