import React from 'react';

import { useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import { get, isEqual, merge, set } from 'lodash-es';
import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import Button from '@asteria/component-core/button';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Form from '@asteria/component-form';
import Modal from '@asteria/component-modal';

import * as AppStore from '@asteria/datalayer/stores/app';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import ServerError from '../../server-error';
import { useInvoice } from '../hooks';

import InvoiceEditTabs from './components/tabs';

import './styles.scss';

const FIELDS = [
	'meta.invoiceNumber',
	'meta.clientNumber',
	'dates.invoiceSent',
	'dates.invoiceDue',
	'clientId',
	'type',

	'contact.general.name',
	'contact.general.email',
	'contact.general.street',
	'contact.general.street2',
	'contact.general.city',
	'contact.general.zipcode',
	'contact.general.country',
	'contact.general.phone',
	'contact.general.mobile',

	'contact.shipping.name',
	'contact.shipping.email',
	'contact.shipping.street',
	'contact.shipping.street2',
	'contact.shipping.city',
	'contact.shipping.zipcode',
	'contact.shipping.country',
	'contact.shipping.phone',
	'contact.shipping.mobile',

	'contact.billing.name',
	'contact.billing.email',
	'contact.billing.street',
	'contact.billing.street2',
	'contact.billing.city',
	'contact.billing.zipcode',
	'contact.billing.country',
	'contact.billing.phone',
	'contact.billing.mobile',

	'isCreditInvoice',
];

const InvoiceInformation = React.memo((props) => {
	const { id, onSubmit, onAction } = props;

	const invoice = useInvoice({ id, onSubmit, onAction });

	return (
		<TextGroup
			className={cn('relative', {
				'asteria--state-error': invoice?.errors?.length,
			})}
		>
			<Translation
				translationKey="invoice.modal.details.placeholder.label"
				translationOptions={{
					postfix: { type: invoice?.type },
				}}
				Component={Text}
				className="absolute top-2 left-2 p-2 bg-white border border-solid border-border-normal pointer-events-none"
			/>
			<Text size="sm">
				{TranslationService.get(
					[
						'invoice.modal.details.placeholder.before',
						`invoice.modal.details.${invoice?.type}.placeholder.before`,
						'invoice.modal.edit.placeholder.before',
						`invoice.modal.edit.${invoice?.type}.placeholder.before`,
					],
					undefined,
					{ invoice: invoice },
				)}
			</Text>
			<Title>
				{TranslationService.get(
					[
						'invoice.modal.details.placeholder.title',
						`invoice.modal.details.${invoice?.type}.placeholder.title`,
						'invoice.modal.edit.placeholder.title',
						`invoice.modal.edit.${invoice?.type}.placeholder.title`,
					],
					undefined,
					{ invoice: invoice },
				)}
			</Title>
			<Text size="sm">
				{TranslationService.get(
					[
						'invoice.modal.details.placeholder.after',
						`invoice.modal.details.${invoice?.type}.placeholder.after`,
						'invoice.modal.edit.placeholder.after',
						`invoice.modal.edit.${invoice?.type}.placeholder.after`,
					],
					undefined,
					{ invoice: invoice },
				)}
			</Text>

			{invoice?.invoiceLayoutDetails?.pdfUri ? (
				<Button
					variant="link"
					label={TranslationService.get(
						['invoice.modal.details.placeholder.invoice.pdf.label'],
						undefined,
						{ invoice: invoice },
					)}
					icon="external"
					iconSize="md"
					href={TranslationService.get(
						'page.invoices.pending.table.open',
						undefined,
						{ uri: invoice?.invoiceLayoutDetails?.pdfUri },
					)}
					target="__blank"
				/>
			) : null}
		</TextGroup>
	);
});

InvoiceInformation.displayName = 'InvoiceInformation';
InvoiceInformation.propTypes = {
	id: PropTypes.string,
	onSubmit: PropTypes.func,
	onAction: PropTypes.func,
};

const InvoiceEditModalContent = (props) => {
	const { onClose, onSubmit, onAction, id } = props;

	const [loading, setLoading] = React.useState(false);

	const countries = useSelector(
		(store) => store?.invoices?.country?.candidates,
		(a, b) => isEqual(a, b),
	);

	const { data: invoice } = useQuery({
		queryKey: ['invoices', id],
		queryFn: async () =>
			onSubmit?.('invoices:details', { id, changes: true, countries }),

		placeholderData: null,
		// refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		keepPreviousData: true,
		enabled: !!id,
	});

	const partnerId = useSelector(AppStore.selectors.partnerId, (a, b) =>
		isEqual(a, b),
	);

	const handleSubmit = React.useCallback(
		async (form) => {
			setLoading(true);

			const isCreditInvoice =
				form?.service?.invoice?.isCreditInvoice ?? false;

			delete form?.service?.invoice?.isCreditInvoice;

			const invoice = Object.entries(
				AsteriaCore.utils.flatObject(form?.service?.invoice ?? {}),
			)
				.filter(([, value]) => value)
				.reduce((acc, [key, value]) => set(acc, key, value), {});

			if (isCreditInvoice) {
				invoice.type = 'credit';
			} else {
				invoice.type = 'invoice';
			}

			await onSubmit?.('invoices:service:update', {
				id: id,
				data: {
					...form,
					service: { ...form.service, invoice: invoice },
				},
			});

			setLoading(false);

			onClose?.();
		},
		[id, onClose, onSubmit],
	);

	const values = React.useMemo(() => {
		if (!invoice) {
			return {};
		}

		const service = merge(
			{},
			(invoice?.modifications ?? []).find(
				({ serviceId }) => serviceId === partnerId,
			) ?? {},
		);

		if (service?.invoice) {
			service.invoice.isCreditInvoice = service.invoice.type === 'credit';
		}

		return {
			...invoice,
			_invoice: invoice,
			service: merge(
				{},
				FIELDS.reduce((acc, field) => {
					const value = get(invoice, field);

					if (!value) {
						return acc;
					}

					return set(acc, ['invoice', field].join('.'), value);
				}, {}),
				service,
			),
		};
	}, [invoice, partnerId]);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'server:error:action') {
				onClose?.(null, { size: Infinity });
			}

			return onAction?.(action, data);
		},
		[onAction, onClose],
	);

	return (
		<Form onSubmit={handleSubmit} values={values}>
			<Wrapper scroll>
				<Header onClose={onClose}>
					{TranslationService.get(
						[
							'invoice.modal.details.title',
							`invoice.modal.details.${invoice?.type}.title`,
							'invoice.modal.edit.title',
							`invoice.modal.${invoice?.type}.edit.title`,
						],
						undefined,
						{ invoice: invoice },
					)}
				</Header>
				<Content scroll>
					{invoice?.errors?.length ? (
						<ServerError
							className="mb-6"
							type="invoice"
							errors={invoice?.errors}
							onAction={handleAction}
							onSubmit={handleSubmit}
							contact={(invoice?.errors ?? []).some(
								({ path }) => !path,
							)}
							extra={{ invoice }}
						/>
					) : null}
					<InvoiceInformation
						id={id}
						onSubmit={onSubmit}
						onAction={handleAction}
					/>

					<InvoiceEditTabs
						id={id}
						onSubmit={onSubmit}
						onAction={handleAction}
					/>
				</Content>
				<Footer>
					<FooterSection position="first">
						<Button
							variant="tertiary"
							label={TranslationService.get([
								'button.cancel',
								'action.cancel',
								'invoice.modal.edit.cancel',
							])}
							onClick={onClose}
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							label={TranslationService.get([
								'button.save',
								'action.save',
								'invoice.modal.edit.cancel',
							])}
							type="submit"
							loading={loading}
							disabled={loading}
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</Form>
	);
};

InvoiceEditModalContent.displayName = 'InvoiceEditModalContent';

InvoiceEditModalContent.propTypes = {
	className: PropTypes.string,

	open: PropTypes.bool,
	onClose: PropTypes.func,

	onSubmit: PropTypes.func,
	onAction: PropTypes.func,

	id: PropTypes.string,
};

const InvoiceEditModal = (props) => {
	const { className, open, onClose } = props;

	return (
		<Modal
			className={cn(
				'asteria-component__invoice-modal',
				'asteria--type-edit',
				className,
			)}
			size="lg"
			open={open}
			onClose={onClose}
			scroll
		>
			<InvoiceEditModalContent {...props} />
		</Modal>
	);
};

InvoiceEditModal.displayName = 'InvoiceEditModal';

InvoiceEditModal.propTypes = {
	className: PropTypes.string,

	open: PropTypes.bool,
	onClose: PropTypes.func,

	onSubmit: PropTypes.func,
	onAction: PropTypes.func,

	id: PropTypes.string,
};

export default InvoiceEditModal;
