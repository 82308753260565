import React from 'react';

import PropTypes from 'prop-types';

import { Actions as BaseActions } from '@asteria/component-actionbar';

import { cn } from '@asteria/utils-funcs/classes';

import Action from './action';
import Pagination from './components/pagination';
import { useImportantActions, usePagination } from './hooks';

const ITEMS_PER_PAGE = 5;

const Actions = React.memo(function Actions(props) {
	const { className, actions, onAction, onSubmit } = props;

	const { onNext, onPrev, page, onPageClick } = usePagination();

	if (!actions?.length) {
		return null;
	}

	return (
		<div
			className={cn(
				'flex flex-col gap-4 w-full items-center',
				'asteria-component__introduction-actions',
			)}
		>
			<BaseActions className={cn('w-full', className)}>
				{actions
					.slice(
						page * ITEMS_PER_PAGE,
						page * ITEMS_PER_PAGE + ITEMS_PER_PAGE,
					)
					.map((object, index) => (
						<Action
							key={
								`${object?.key}-${index}` ??
								`${object?.id}-${index}`
							}
							{...object}
							onAction={onAction}
							onSubmit={onSubmit}
						/>
					))}
			</BaseActions>
			{actions?.length > ITEMS_PER_PAGE ? (
				<Pagination
					length={actions?.length}
					size={ITEMS_PER_PAGE}
					page={page}
					onNext={onNext}
					onPrev={onPrev}
					onPageClick={onPageClick}
				/>
			) : null}
		</div>
	);
});

Actions.propTypes = {
	className: PropTypes.string,
	actions: PropTypes.arrayOf(PropTypes.object),
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

const ImportantActions = React.memo(function ImportantActions(props) {
	const { ignore, onSubmit } = props;

	const actions = useImportantActions({ ignore: ignore, onSubmit: onSubmit });

	return <Actions {...props} actions={actions} />;
});

ImportantActions.propTypes = {
	className: PropTypes.string,
	ignore: PropTypes.string,
	onSubmit: PropTypes.func,
};

export default Actions;
export { Action, ImportantActions };
