import React from 'react';

import { useSelector } from 'react-redux';

import { useQueries } from '@tanstack/react-query';
import { compareDesc, isAfter, parseISO } from 'date-fns';
import { isEqual } from 'lodash-es';

import { getIntegrationName } from '@asteria/component-integrations-v2/utils';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';

import { useImportantActions } from '../../components/Actions/hooks';
import { useCompanyVersionQuery } from '../../components/CompanyVersion';

function useAvailableActions({ onSubmit }) {
	const hasConnectedIntegration = useSelector(
		(store) =>
			IntegrationStore.selectors.integrations(store, {
				type: 'erp',
				filters: [
					{ status: 'IDLE' },
					{ status: 'IMPORTING', connected: true },
				],
			}).length > 0,
	);

	const hasPrinterIntegration = useSelector((store) =>
		IntegrationStore.selectors
			.integrations(store, {
				type: 'erp',
				filters: [
					{ status: 'IDLE' },
					{ status: 'IMPORTING', connected: true },
				],
			})
			.some((object) => object?.key === 'printer'),
	);

	const hasErrorIntegration = useSelector(
		(store) =>
			IntegrationStore.selectors.integrations(store, {
				type: 'erp',
				status: 'ERROR',
			}).length > 0,
	);

	const hasBatches = useSelector(
		(store) => InvoiceStore.selectors.batches(store).length > 0,
	);

	const [{ data: hasUnpaidInvoices }] = useQueries({
		queries: [
			{
				queryKey: ['invoices', 'unpaid'],
				queryFn: async () =>
					onSubmit?.('invoices:available', {
						filters: { services: { status: null } },
						pageFilters: { first: 1 },
						fields: `_id`,
					}),
				select: (response) => !!response?.pageInfo?.count,

				placeholderData: false,

				// refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				retry: false,
			},
		],
	});

	return React.useMemo(() => {
		let actions = [];

		if (!hasConnectedIntegration && !hasErrorIntegration) {
			actions.push({
				id: 'connection',
				variant: 'integration',
				type: 'erp',
			});
		}

		if (!hasBatches) {
			if (hasPrinterIntegration && !hasUnpaidInvoices) {
				actions = actions.concat([
					{
						id: 'layouts',
						variant: 'link',
						path: '/layouts',
						disabled: !hasConnectedIntegration,
					},
				]);
			}

			actions = actions.concat([
				{
					id: 'invoices',
					variant: 'link',
					path: '/invoices',
					disabled:
						!hasConnectedIntegration ||
						(hasPrinterIntegration && !hasUnpaidInvoices),
				},
				{
					id: 'review',
					variant: 'link',
					path: 'https://google.com',
					external: true,
					disabled: true,
				},
			]);
		}

		return actions;
	}, [
		hasConnectedIntegration,
		hasErrorIntegration,
		hasBatches,
		hasPrinterIntegration,
		hasUnpaidInvoices,
	]);
}

function useClientActions() {
	return useSelector(
		(store) =>
			AppStore.selectors
				.clients(store)
				.filter((object) => object?.errors?.length)
				.map((object) => ({
					id: 'client-error',
					key: object?._id ?? object?.id,
					variant: 'error',
					data: {
						type: 'client',
						client: object,
						errors: object?.errors,
					},
				})),
		isEqual,
	);
}

function useIntegrationActions() {
	return useSelector(
		(store) =>
			IntegrationStore.selectors
				.integrations(store)
				.filter((object) =>
					(object?.actions ?? []).some(
						({ status }) => status === 'PENDING',
					),
				)
				.map((integration) => ({
					id: 'integration-error',
					key: integration?._id ?? integration?.id,
					variant: 'error',
					data: {
						type: 'integration',
						integration: integration,
						name: getIntegrationName(
							store,
							integration?.type,
							integration?.key,
						),
						errors: (integration?.actions ?? []).map(
							({ action: event, data }) => ({
								code: event,
								title: TranslationService.getV2(
									[
										`integrations.actions.${event}`,
										`integrations.${integration.type}.actions.${event}`,
										`integrations.${integration.type}.${integration.key}.actions.${event}`,
									],
									{ data: { integration } },
								),
								content: TranslationService.getV2(
									[
										`integrations.actions.message`,
										`integrations.actions.${event}.message`,
										`integrations.${integration.type}.actions.${event}.message`,
										`integrations.${integration.type}.${integration.key}.actions.${event}.message`,
										data?.message,
									],
									{
										data: {
											integration: integration,
											message: data?.message,
										},
										default: data?.message,
									},
								),
							}),
						),
					},
				})),
		isEqual,
	);
}

function usePrintActions() {
	return useSelector(
		(store) =>
			InvoiceStore.selectors
				.layouts(store, { status: 'ERROR' })
				.map((print) => ({
					id: 'print-error',
					key: print?._id ?? print?.id,
					variant: 'error',
					data: { type: 'print', object: print },
				})),
		isEqual,
	);
}

function useRecommendedActions({ onSubmit }) {
	const hasCompanyVersionTwo = useCompanyVersionQuery({ gte: 2 });

	const importantActions = useImportantActions();
	const integrationActions = useIntegrationActions();
	const clientActions = useClientActions();
	const printActions = usePrintActions();

	const reportTimestamp = useSelector(
		InvoiceStore.selectors.reports.timestamp,
	);

	const hasBatches = useSelector(
		(store) => InvoiceStore.selectors.batches(store).length > 0,
	);

	const [
		{ data: hasUnpaidInvoices },
		{ data: errorInvoices },
		{ data: lastReport },
	] = useQueries({
		queries: [
			{
				queryKey: ['invoices', 'unpaid'],
				queryFn: async () =>
					onSubmit?.('invoices:available', {
						filters: { services: { status: null } },
						pageFilters: { first: 1 },
						fields: `_id`,
					}),
				select: (response) => !!response?.pageInfo?.count,

				placeholderData: false,

				// refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				retry: false,
			},
			{
				queryKey: ['invoices', 'error'],
				queryFn: async () =>
					onSubmit?.('invoices:available', {
						filters: { errors: true },
						pageFilters: { first: 1_000 },
					}),
				select: (response) =>
					response?.edges?.map?.((object) => object?.node) ?? [],

				placeholderData: null,

				// refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				retry: false,
			},
			{
				queryKey: ['reports', 'last'],
				queryFn: async () => {
					const response = await onSubmit?.('reports:list');

					return (
						[]
							.concat(response)
							.filter(Boolean)
							.sort(({ createdAt: a }, { createdAt: b }) =>
								compareDesc(parseISO(a), parseISO(b)),
							)
							.find((object) =>
								isAfter(
									parseISO(object?.createdAt),
									parseISO(reportTimestamp),
								),
							) ?? null
					);
				},
				placeholderData: false,
				// refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,
				enabled: !!reportTimestamp,

				retry: false,
			},
		],
	});

	const deliveryClients = useSelector(
		(store) =>
			AppStore.selectors.clients(store, {
				filters: [{ type: 'DELIVERY:MISSING' }],
			}).length,
	);

	const companyService = useSelector((store) =>
		AppStore.selectors
			.company(store)
			?.service?.data?.service?.toUpperCase?.(),
	);

	const errorBatches = useSelector(InvoiceStore.selectors.batches).filter(
		(object) => object.status === 'ERROR',
	);

	return React.useMemo(() => {
		let actions = [].concat(importantActions);

		if (errorInvoices.length) {
			errorInvoices.forEach((object) => {
				actions.push({
					id: 'invoice-error',
					variant: 'error',
					type: 'invoice-error',
					data: {
						type: 'invoice-error',
						invoice: object,
						errors: object?.errors,
					},
				});
			});
		}

		if (hasBatches && hasUnpaidInvoices) {
			actions = actions.concat({
				id: 'invoices',
				variant: 'link',
				path: '/invoices',
			});
		}

		if (deliveryClients && companyService === 'INVOICE') {
			actions = actions.concat({
				id: 'action-client-list',
				variant: 'link',
				data: { count: deliveryClients },
			});
		}

		if (lastReport && hasCompanyVersionTwo) {
			actions = actions.concat({
				id: 'action-reports',
				variant: 'link',
				path: '/reports',
				data: { report: lastReport },
			});
		}

		if (errorBatches?.length) {
			errorBatches.forEach((object) => {
				actions.push({
					id: 'error',
					variant: 'error',
					type: 'error',
					data: {
						type: 'batch',
						batch: object,
						errors: object?.data?.errors,
					},
				});
			});
		}

		if (errorInvoices?.length && hasCompanyVersionTwo) {
			const serviceInvoice = errorInvoices?.[0].lastService?.invoice;

			actions = actions.concat({
				id: 'action-invoice-error',
				variant: 'link',
				data: {
					count: errorInvoices?.length,
					last: errorInvoices?.[0],
					errors: serviceInvoice?.errors,
				},
			});
		}

		actions = actions
			.concat(clientActions)
			.concat(printActions)
			.concat(integrationActions);

		return actions;
	}, [
		clientActions,
		companyService,
		deliveryClients,
		errorBatches,
		errorInvoices,
		hasBatches,
		hasCompanyVersionTwo,
		hasUnpaidInvoices,
		importantActions,
		integrationActions,
		lastReport,
		printActions,
	]);
}

export function useActions({ onSubmit }) {
	const available = useAvailableActions({ onSubmit });
	const recommended = useRecommendedActions({ onSubmit });

	return React.useMemo(
		() => ({ available: available, recommended: recommended }),
		[available, recommended],
	);
}
