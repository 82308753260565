import React from 'react';

import PropTypes from 'prop-types';

import Chip from '@asteria/component-chip/chip';

import { TranslationService } from '@asteria/language';

import '../styles.scss';

const ChipFilter = React.memo(function ChipFilter(props) {
	const { className, filters, type, onAction, invoices } = props;

	const handleClick = React.useCallback(() => {
		onAction?.('filter:status', type);
	}, [onAction, type]);

	const errorInvoices = invoices?.filter(
		(object) => !!object?.errors?.length,
	)?.length;

	if (!errorInvoices) {
		return null;
	}

	return (
		<Chip
			className={className}
			active={filters?.status === type}
			label={TranslationService.getV2(
				'page.invoices.review.modal.filter.label',
				{
					postfix: { type: type },
					data: { errors: errorInvoices, invoices: invoices?.length },
				},
			)}
			onClick={handleClick}
		/>
	);
});

ChipFilter.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	invoices: PropTypes.arrayOf(PropTypes.object),
	filters: PropTypes.object,
	onAction: PropTypes.func,
};

export default ChipFilter;
