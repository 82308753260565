import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

/** @type { React.FC<import('./types').BaseProps> } */
const SearchReset = React.memo(function SearchReset(props) {
	const { className } = props;

	const { resetField } = useFormContext();

	const dispatch = useDispatch();

	const search = useSelector(InvoiceStore.selectors.search, isEqual);
	const filters = useSelector(InvoiceStore.selectors.filters, isEqual);

	const onReset = React.useCallback(() => {
		dispatch(InvoiceStore.filter({ type: null, value: null }));
		dispatch(InvoiceStore.search(''));

		resetField('search-value');
		resetField('date');
	}, [dispatch, resetField]);

	if (!filters.length && !search) {
		return null;
	}

	return (
		<div
			className={cn(
				'flex items-center justify-center',
				'asteria-component__invoice-search-part',
				'asteria--type-reset',
				className,
			)}
		>
			<Button
				variant="link"
				label={TranslationService.get(['invoices.search.reset.label'])}
				onClick={onReset}
			/>
		</div>
	);
});

SearchReset.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default SearchReset;
