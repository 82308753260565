import React from 'react';

import PropTypes from 'prop-types';

import { Input } from '@asteria/component-form';

import CountrySelect from '../inputs/country';

import Field from './field';

import '../styles.scss';

const Billing = ({ invoice }) => {
	return (
		<>
			<Field
				as={Input}
				name="contact.billing.name"
				invoice={invoice}
				disabled
				direction="horizontal"
				label="invoice.edit.field.contact.name.label"
			/>
			<Field
				as={Input}
				name="contact.billing.street"
				invoice={invoice}
				disabled
				direction="horizontal"
				label="invoice.edit.field.contact.street.label"
			/>
			<Field
				as={Input}
				name="contact.billing.street2"
				invoice={invoice}
				disabled
				direction="horizontal"
				label="invoice.edit.field.contact.street2.label"
			/>
			<Field
				as={Input}
				name="contact.billing.zipcode"
				invoice={invoice}
				disabled
				direction="horizontal"
				label="invoice.edit.field.contact.zipcode.label"
			/>
			<Field
				as={Input}
				name="contact.billing.city"
				invoice={invoice}
				disabled
				direction="horizontal"
				label="invoice.edit.field.contact.city.label"
			/>
			<Field
				as={CountrySelect}
				name="contact.billing.country"
				invoice={invoice}
				disabled
				direction="horizontal"
				label="invoice.edit.field.contact.country.label"
				className="asteria--type-country"
			/>
		</>
	);
};

Billing.displayName = 'Billing';

Billing.propTypes = { invoice: PropTypes.object };

export default Billing;
