import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import {
	Content,
	Footer,
	FooterSection,
} from '@asteria/component-core/wrapper';

import { MessageBoxToggle } from '@asteria/component-support/MessageBox';

import { TranslationService } from '@asteria/language';

import '../styles.scss';

const RemoveButton = React.memo(function RemoveButton(props) {
	const { onSubmit, id } = props;

	const onRemoveSubmit = React.useCallback(
		() => onSubmit?.('invoice:remove', id),
		[id, onSubmit],
	);

	const toggle = React.useMemo(
		() => ({
			label: TranslationService.get(['invoice.modal.details.remove']),
			iconTooltip: TranslationService.get([
				'invoice.modal.details.remove.tooltip',
			]),
			variant: 'link',
			icon: 'help',
			iconPosition: 'last',
		}),
		[],
	);

	return (
		<MessageBoxToggle
			toggle={toggle}
			analyticsKey="invoice.modal.details.remove.message-box"
			scrollIntoView
		>
			{({ onClose }) => [
				<Content key="content">
					<Text>
						{TranslationService.get([
							'invoice.modal.details.remove.confirm',
						])}
					</Text>
				</Content>,
				<Footer key="footer">
					<FooterSection position="first">
						<Button
							variant="tertiary"
							size="sm"
							label={TranslationService.get([
								'action.abort',
								'action.cancel',
							])}
							onClick={onClose}
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							size="sm"
							label={TranslationService.get([
								'action.submit',
								'action.remove',
							])}
							onClick={onRemoveSubmit}
						/>
					</FooterSection>
				</Footer>,
			]}
		</MessageBoxToggle>
	);
});

RemoveButton.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	id: PropTypes.string,
};

export default RemoveButton;
