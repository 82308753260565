import { TranslationService } from '@asteria/language';
import { selector } from '@asteria/utils-hooks/useConfig';

export function getFlow(store, type, key, callback) {
	const config = selector(store?.getState?.() ?? store, {
		key: 'integrations',
	});

	const flow = (config ?? []).find(
		(object) => object?.type === type && object?.key === key,
	);

	if (callback) {
		return callback(flow);
	}

	return flow;
}

export function getIntegrationName(store, type, key) {
	const flow = getFlow(store, type, key);

	return TranslationService.get(
		[
			'integration.title',
			`integration.title.${key}`,
			`integration.title.${type}.${key}`,
			`integration.${key}.title`,
			`integration.${type}.${key}.title`,
		],
		flow?.name,
	);
}
