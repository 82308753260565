import { useCallback, useState } from 'react';

function updateSearch(name, value) {
	const url = new URL(window.location);

	if (!value) {
		url.searchParams.delete(name);
	} else {
		url.searchParams.set(name, value);
	}

	if (window?.history?.pushState) {
		window.history.pushState({}, '', url);
	}
}

/**
 *
 * @returns {[object, (name: string, value: unknown) => void]}
 */
function useSearchQuery() {
	const [query, setQuery] = useState(() =>
		Object.fromEntries(new URLSearchParams(window.location.search)),
	);

	const update = useCallback((name, value) => {
		setQuery((query) => {
			const target = { ...query };

			if (value) {
				target[name] = value;
			} else {
				delete target[name];
			}

			updateSearch(name, value);

			return target;
		});
	}, []);

	return [query, update];
}

export { useSearchQuery };
export default useSearchQuery;
