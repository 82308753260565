import React from 'react';

import { useQueries } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { BadgeWrapper } from '@asteria/component-core/badge';
import Icon from '@asteria/component-core/icon';
import { TooltipWrapper } from '@asteria/component-core/tooltip';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

/** @type { React.FC<Pick<import('./types').BaseProps, 'onAction' | 'onSubmit'> & { active: boolean, type: 'invoices' | 'prints', badge?: boolean }> } */
const Switch = React.memo(function Switch(props) {
	const { active, type, onAction, badge } = props;

	const onClick = React.useCallback(
		() => onAction?.('invoice:search:switch', type),
		[onAction, type],
	);

	return (
		<BadgeWrapper
			size="sm"
			icon={badge ? 'warning' : null}
			tooltip={TranslationService.getV2(
				[
					'page.invoices.switcher.switch',
					'page.invoices.switcher.switch.tooltip',
					'page.invoices.switcher.switch.badge',
					'page.invoices.switcher.switch.badge.tooltip',
				],
				{ postfix: { type }, data: {} },
			)}
		>
			<TooltipWrapper
				tooltip={TranslationService.getV2(
					[
						'page.invoices.switcher.switch',
						'page.invoices.switcher.switch.tooltip',
					],
					{ postfix: { type }, data: {} },
				)}
			>
				<div
					className={cn(
						'flex items-center justify-center h-10 w-10 border border-solid border-chip-normal-border bg-chip-normal-background cursor-pointer',
						{
							[cn(
								'border-chip-focus-border bg-chip-focus-background',
							)]: active,
						},
						'asteria-component__invoice-search-switch-handle',
						{ 'asteria--state-active': active },
					)}
					onClick={onClick}
				>
					{type === 'invoices' ? <Icon icon="document" /> : null}
					{type === 'prints' ? <Icon icon="printer" /> : null}
				</div>
			</TooltipWrapper>
		</BadgeWrapper>
	);
});

Switch.propTypes = {
	type: PropTypes.string,
	active: PropTypes.bool,
	badge: PropTypes.bool,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

/** @type { React.FC<import('./types').BaseProps> } */
const SearchSwitcher = React.memo(function SearchSwitcher(props) {
	const { className, type, onAction, onSubmit } = props;

	const [invoiceErrorQuery, printsAvailableQuery, printsErrorQuery] =
		useQueries({
			queries: [
				{
					queryKey: ['invoices', 'switcher', 'invoices', 'error'],
					queryFn: async () =>
						onSubmit?.('invoices:available', {
							filters: { errors: true },
							pageFilters: { first: 1 },
							fields: `_id`,
						}),
					select: (response) => !!response?.pageInfo?.count,

					refetchOnMount: true,
					refetchOnReconnect: false,
					refetchOnWindowFocus: false,
				},
				{
					queryKey: ['invoices', 'switcher', 'prints', 'available'],
					queryFn: async () =>
						onSubmit?.('invoice-layout:list', {
							skipDispatch: true,
							skipInvalidate: true,
							skipPagination: true,
							raw: true,

							pageFilters: { first: 1 },
							fields: `_id`,
						}),

					select: (response) => !!response?.pageInfo?.count,

					refetchOnMount: true,
					refetchOnReconnect: false,
					refetchOnWindowFocus: false,
				},
				{
					queryKey: ['invoices', 'switcher', 'prints', 'error'],
					queryFn: async () =>
						onSubmit?.('invoice-layout:list', {
							skipDispatch: true,
							skipInvalidate: true,
							skipPagination: true,
							raw: true,

							pageFilters: { first: 1 },
							fields: `_id`,
							filters: {
								status: ['ERROR'],
							},
						}),

					select: (response) => !!response?.pageInfo?.count,

					refetchOnMount: true,
					refetchOnReconnect: false,
					refetchOnWindowFocus: false,
				},
			],
		});

	if (!printsAvailableQuery.data) {
		return null;
	}

	return (
		<div
			className={cn(
				'flex gap-4 items-center',
				'asteria-component__invoice-search-part',
				'asteria--type-switcher',
				className,
			)}
		>
			<Switch
				type="invoices"
				active={type === 'invoices'}
				onAction={onAction}
				onSubmit={onSubmit}
				badge={invoiceErrorQuery.data}
			/>
			<Switch
				type="prints"
				active={type === 'prints'}
				onAction={onAction}
				onSubmit={onSubmit}
				badge={printsErrorQuery.data}
			/>
		</div>
	);
});

SearchSwitcher.propTypes = {
	className: PropTypes.string,

	type: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default SearchSwitcher;
