import React from 'react';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';

import { Translation, TranslationService } from '@asteria/language';

/**
 * @typedef Props
 * @property { string } className
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 * @property { boolean } open
 * @property { import('react').MouseEventHandler } onClose
 */

/** @type { React.FC<Props}> } */
const RemovePrintDialog = React.memo(function RemovePrintDialog(props) {
	const { open, onClose, onSubmit } = props;

	const agree = useMutation({
		mutationFn: async () => onSubmit?.('print:dialog:close'),
		onSuccess: (data, variables) => onClose(variables),
	});

	return (
		<Modal size="sm" open={open} onClose={onClose}>
			<Wrapper>
				<Header onClose={onClose}>
					{TranslationService.getV2([
						'print.dialog.remove.modal.title',
					])}
				</Header>
				<Content>
					<Translation
						translationKey="print.dialog.remove.modal.content"
						Component={Text}
					/>
				</Content>
				<Footer>
					<FooterSection position="last">
						<Button
							variant="secondary"
							label={TranslationService.getV2(
								['print.dialog.remove.modal.action'],
								{ postfix: { type: 'discard' } },
							)}
							onClick={onClose}
						/>
						<Button
							variant="primary"
							label={TranslationService.getV2(
								['print.dialog.remove.modal.action'],
								{ postfix: { type: 'submit' } },
							)}
							onClick={agree.mutate}
							loading={agree.isLoading}
							disabled={agree.isLoading}
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</Modal>
	);
});

RemovePrintDialog.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	className: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default RemovePrintDialog;
