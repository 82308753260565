import React from 'react';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Icon from '@asteria/component-core/icon';
import { Circle } from '@asteria/component-core/progress';
import { Text } from '@asteria/component-core/typography';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { TABS } from '../dialog/constants';

import './styles.scss';

/**
 * @typedef Props
 * @property { string } className
 * @property { 'PENDING' | 'ERROR' | 'SUCCESS' | 'WARNING' } status
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 * @property { string } from
 * @property { boolean } done
 */

/** @type { React.FC<Props> } */
const StatusPending = React.memo(function StatusPending() {
	return (
		<div
			className={cn(
				'flex gap-2 items-center justify-center',
				'asteria-component__print-status-content',
			)}
		>
			<Circle progress={-1} />
			<Translation
				translationKey="print.status.content"
				translationOptions={{ postfix: { status: 'PENDING' } }}
				Component={Text}
			/>
		</div>
	);
});

StatusPending.propTypes = {
	className: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	status: PropTypes.string,
	from: PropTypes.string,
	done: PropTypes.bool,
};

/** @type { React.FC<Props> } */
const StatusError = React.memo(function StatusError(props) {
	const { onAction, from } = props;

	const showError = React.useCallback(() => {
		if (from === 'dialog') {
			return onAction?.('print:dialog:switch', TABS.ERROR);
		}

		if (from === 'page') {
			return onAction?.('print:page:switch', 'ERROR');
		}

		return null;
	}, [from, onAction]);

	return (
		<div
			className={cn(
				'flex gap-2 items-center justify-center',
				'asteria-component__print-status-content',
			)}
		>
			<Icon icon="alert" />
			<Translation
				translationKey="print.status.content"
				translationOptions={{ postfix: { status: 'ERROR' } }}
				Component={Text}
				className="text-alert-error-normal-text"
			/>
			<Button
				label={TranslationService.getV2('print.status.button', {
					postfix: { status: 'ERROR' },
				})}
				variant="primary"
				size="sm"
				onClick={showError}
			/>
		</div>
	);
});

StatusError.propTypes = {
	className: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	status: PropTypes.string,
	from: PropTypes.string,
	done: PropTypes.bool,
};

/** @type { React.FC<Props> } */
const StatusWarning = React.memo(function StatusWarning(props) {
	const { onAction, from } = props;

	const showWarning = React.useCallback(() => {
		if (from === 'dialog') {
			return onAction?.('print:dialog:switch', TABS.WARNING);
		}

		if (from === 'page') {
			return onAction?.('print:page:switch', 'WARNING');
		}

		return null;
	}, [from, onAction]);

	return (
		<div
			className={cn(
				'flex gap-2 items-center justify-center',
				'asteria-component__print-status-content',
			)}
		>
			<Icon icon="alert" />
			<Translation
				translationKey="print.status.content"
				translationOptions={{ postfix: { status: 'WARNING' } }}
				Component={Text}
				className="text-alert-warning-normal-text"
			/>
			<Button
				label={TranslationService.getV2('print.status.button', {
					postfix: { status: 'WARNING' },
				})}
				variant="primary"
				size="sm"
				onClick={showWarning}
			/>
		</div>
	);
});

StatusWarning.propTypes = {
	className: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	status: PropTypes.string,
	from: PropTypes.string,
	done: PropTypes.bool,
};

/** @type { React.FC<Props> } */
const StatusSuccess = React.memo(function StatusSuccess(props) {
	const { onSubmit, from, done } = props;

	const mutation = useMutation({
		mutationFn: async () => onSubmit?.('print:status:done', { from }),
	});

	return (
		<div
			className={cn(
				'flex gap-2 items-center justify-center',
				'asteria-component__print-status-content',
			)}
		>
			<Icon icon="check" />
			<Translation
				translationKey="print.status.content"
				translationOptions={{ postfix: { status: 'SUCCESS' } }}
				Component={Text}
				className="text-alert-success-normal-text"
			/>

			{done ? (
				<Button
					variant="primary"
					label={TranslationService.getV2(
						['print.status.content.action'],
						{ postfix: { status: 'SUCCESS' } },
					)}
					size="sm"
					onClick={mutation.mutate}
					loading={mutation.isLoading}
					disabled={mutation.isLoading}
				/>
			) : null}
		</div>
	);
});

StatusSuccess.propTypes = {
	className: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	status: PropTypes.string,
	from: PropTypes.string,
	done: PropTypes.bool,
};

/** @type { React.FC<Props> } */
const PrintStatus = React.memo(function PrintStatus(props) {
	const { className, status = 'PENDING' } = props;

	return (
		<div
			className={cn(
				'p-4',
				'asteria-component__print-status',
				{ [`asteria--status-${status}`]: status },
				className,
			)}
		>
			{status === 'PENDING' ? <StatusPending {...props} /> : null}
			{status === 'ERROR' ? <StatusError {...props} /> : null}
			{status === 'WARNING' ? <StatusWarning {...props} /> : null}
			{status === 'SUCCESS' ? <StatusSuccess {...props} /> : null}
		</div>
	);
});

PrintStatus.displayName = 'PrintStatus';

PrintStatus.propTypes = {
	className: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	status: PropTypes.string,
	from: PropTypes.string,
	done: PropTypes.bool,
};

export default PrintStatus;
