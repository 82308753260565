import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { BadgeWrapper } from '@asteria/component-core/badge';
import { TooltipWrapper } from '@asteria/component-core/tooltip';

import Chip from '@asteria/component-chip';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';

const SearchStatusChip = React.memo(function SearchStatusChip(props) {
	const { status, prints, badge } = props;

	const dispatch = useDispatch();

	const active = useSelector(
		(store) =>
			!!InvoiceStore.selectors.filters(store, {
				type: 'SERVICE:STATUS',
				value: status,
			}).length,
	);

	const onClick = React.useCallback(() => {
		dispatch(
			InvoiceStore.filter({
				type: 'SERVICE:STATUS',
				value: status,
			}),
		);
	}, [dispatch, status]);

	return (
		<BadgeWrapper
			size="sm"
			icon={badge ? 'warning' : null}
			tooltip={TranslationService.getV2(
				[
					'invoices.search.filters.status',
					'invoices.search.filters.status.tooltip',
					'invoices.search.filters.status.badge',
					'invoices.search.filters.status.badge.tooltip',
				],
				{
					postfix: { type: 'prints', status, active },
					data: { data: prints },
				},
			)}
		>
			<TooltipWrapper
				tooltip={TranslationService.getV2(
					[
						'invoices.search.filters.status',
						'invoices.search.filters.status.tooltip',
					],
					{
						postfix: { type: 'prints', status, active },
						data: { data: prints },
					},
				)}
			>
				<Chip
					size="sm"
					label={TranslationService.getV2(
						['invoices.search.filters.status'],
						{
							postfix: { type: 'prints', status, active },
							data: { data: prints },
							default: status,
						},
					)}
					onClick={onClick}
					active={active}
				/>
			</TooltipWrapper>
		</BadgeWrapper>
	);
});

SearchStatusChip.propTypes = {
	status: PropTypes.string,
	prints: PropTypes.arrayOf(PropTypes.object),
	badge: PropTypes.bool,
};

const SearchFilterPrintStatuses = React.memo(function SearchFilterPrintStatuses(
	props,
) {
	const { data } = props;

	const statuses = data.reduce((acc, object) => {
		let status = object.status;

		if (status === 'ERROR') {
			const invoices = object?.invoices ?? [];

			if (invoices.some((invoice) => !invoice?.errors?.length)) {
				status = 'WARNING';
			}
		}

		if (!acc[status]) {
			acc[status] = [];
		}

		acc[status].push(object);

		return acc;
	}, {});

	return Object.entries(statuses).map(([status, prints]) => (
		<SearchStatusChip
			key={status}
			status={status}
			prints={prints}
			badge={status === 'ERROR'}
		/>
	));
});

export default SearchFilterPrintStatuses;
