import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import { TooltipWrapper } from '@asteria/component-core/tooltip';

import Chip from '@asteria/component-chip/chip';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';

function useAmounts(data) {
	if (!data?.length || data?.length < 3) {
		return [];
	}

	return AsteriaCore.utils
		.chunk(
			data
				.map((object) => object?.sums?.original?.total)
				.filter(Boolean)
				.sort((a, b) => a - b),
			data.length / 3,
		)
		.map((values) => ({
			from: values?.[0],
			to: values.slice(-1)[0],
		}));
}

/** @type { React.FC<{ from: number, to: number }> } */
const SearchAmountChip = React.memo(function SearchAmountChip(props) {
	const { from, to } = props;

	const dispatch = useDispatch();

	const active = useSelector(
		(store) =>
			!!InvoiceStore.selectors.filters(store, {
				type: 'AMOUNT',
				value: { from: from, to: to },
			}).length,
	);

	const onClick = React.useCallback(() => {
		dispatch(
			InvoiceStore.filter({
				type: 'AMOUNT',
				value: { from: from, to: to },
			}),
		);
	}, [dispatch, from, to]);

	return (
		<TooltipWrapper
			tooltip={TranslationService.get(
				[
					'invoices.search.quick.amount.tooltip',
					from === to
						? 'invoices.search.quick.amount.same.tooltip'
						: null,
				],
				undefined,
				props,
			)}
		>
			<Chip
				size="sm"
				label={TranslationService.get(
					[
						'invoices.search.quick.amount.label',
						from === to
							? 'invoices.search.quick.amount.same.label'
							: null,
					],
					undefined,
					props,
				)}
				onClick={onClick}
				active={active}
			/>
		</TooltipWrapper>
	);
});

SearchAmountChip.propTypes = {
	from: PropTypes.number,
	to: PropTypes.number,
};

/** @type { React.FC<import('../types').BaseProps> } */
const SearchFiltersAmounts = React.memo(function SearchFiltersAmounts(props) {
	const { data } = props;

	const amounts = useAmounts(data);

	return amounts.map((object, index) => (
		<SearchAmountChip key={index} {...object} />
	));
});

export default SearchFiltersAmounts;
