import React from 'react';

import PropTypes from 'prop-types';

import Modal from '@asteria/component-modal';

import { cn } from '@asteria/utils-funcs/classes';

import InvoiceDetailsModalContent from './components/content';

import './styles.scss';

const InvoiceDetailsModal = React.memo(function InvoiceDetailsModal(props) {
	const { className, open, onClose } = props;

	return (
		<Modal
			open={open}
			onClose={onClose}
			className={cn(
				'asteria-component__invoice-modal',
				'asteria--type-details',
				className,
			)}
			scroll
			size="sm"
		>
			<InvoiceDetailsModalContent {...props} />
		</Modal>
	);
});

InvoiceDetailsModal.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default InvoiceDetailsModal;
