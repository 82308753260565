import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useQueries } from '@tanstack/react-query';
import { get } from 'lodash-es';
import PropTypes from 'prop-types';

import { BadgeWrapper } from '@asteria/component-core/badge';
import { TooltipWrapper } from '@asteria/component-core/tooltip';

import Chip from '@asteria/component-chip';
import { useFormValues } from '@asteria/component-form';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';

const FIELDS = `
_id
clientId
companyId
meta {
	invoiceNumber
	clientNumber
	message
}

sums {
	original {
		total
		currency
	}
}
`;

function useSearch(query, search) {
	const objects = query?.data ?? [];

	if (!search) {
		return objects;
	}

	return objects.filter((object) =>
		[
			'client.name',
			'meta.invoiceNumber',
			'meta.clientNumber',
			'meta.message',
			'sums.original.total',
			'sums.original.currency',
		].some((key) => {
			const value = get(object, key)?.toString?.()?.toLowerCase?.();
			return value?.includes(search);
		}),
	);
}

/** @type { React.FC<{ status: string, badge?: boolean, query: import('@tanstack/react-query').UseQueryResult }> } */
const SearchStatusChip = React.memo(function SearchStatusChip(props) {
	const { status, badge, query } = props;

	const dispatch = useDispatch();

	const search = useFormValues({ name: 'search-value' });

	const active = useSelector(
		(store) =>
			!!InvoiceStore.selectors.filters(store, {
				type: 'SERVICE:STATUS',
				value: status,
			}).length,
	);

	const onClick = React.useCallback(() => {
		dispatch(
			InvoiceStore.filter({
				type: 'SERVICE:STATUS',
				value: status,
			}),
		);
	}, [dispatch, status]);

	const data = useSearch(query, search?.toLowerCase?.() ?? '');

	if (!data?.length) {
		return null;
	}

	return (
		<BadgeWrapper size="sm" icon={badge ? 'warning' : null}>
			<TooltipWrapper
				tooltip={TranslationService.get(
					[
						'invoices.search.quick.status.tooltip',
						`invoices.search.quick.status.${status.toLowerCase()}.tooltip`,
					].concat(
						active
							? [
									'invoices.search.quick.status.active.tooltip',
									`invoices.search.quick.status.${status.toLowerCase()}.active.tooltip`,
							  ]
							: [],
					),
					undefined,
					{ invoices: { length: data?.length } },
				)}
			>
				<Chip
					size="sm"
					label={TranslationService.get(
						[
							'invoices.search.quick.status.label',
							`invoices.search.quick.status.${status.toLowerCase()}.label`,
						].concat(
							active
								? [
										'invoices.search.quick.status.active.label',
										`invoices.search.quick.status.${status.toLowerCase()}.active.label`,
								  ]
								: [],
						),
						undefined,
						{ invoices: { length: data?.length } },
					)}
					onClick={onClick}
					active={active}
				/>
			</TooltipWrapper>
		</BadgeWrapper>
	);
});

SearchStatusChip.propTypes = {
	badge: PropTypes.bool,
	query: PropTypes.object,
	status: PropTypes.string,
};

/** @type { React.FC<import('../types').BaseProps> } */
const SearchFilterStatuses = React.memo(function SearchFiltersStatus(props) {
	const { statuses, onSubmit } = props;

	const queries = useQueries({
		queries: statuses.map(({ status }) => ({
			queryKey: ['invoices', 'search', 'status', { status: status }],
			queryFn: async () => {
				if (status === 'ERROR') {
					return onSubmit?.('invoices:available', {
						filters: { errors: true },
						pageFilters: { first: 1_000 },
						fields: FIELDS,
					});
				}

				return onSubmit?.('invoices:available', {
					filters: { services: { status: status } },
					pageFilters: { first: 1_000 },
					fields: FIELDS,
				});
			},
			select: (response) =>
				(response?.edges ?? []).map(({ node }) => node),

			placeholderData: {},

			refetchOnMount: true,
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,
		})),
	});

	return statuses.map(({ status, badge }, index) => (
		<SearchStatusChip
			key={status}
			status={status}
			badge={badge}
			query={queries[index]}
		/>
	));
});

export default SearchFilterStatuses;
