import { useMemo } from 'react';

import { useSelector, useStore } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import { compareAsc, isDate } from 'date-fns';
import { isEqual } from 'lodash-es';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

const useInvoiceSorting = ({ objects, sorting, direction }) => {
	const store = useStore();

	return useMemo(() => {
		if (!sorting) {
			return objects;
		}

		let getter = () => null;

		switch (sorting) {
			case 'client-number':
				getter = (object) => object?.meta?.clientNumber;
				break;

			case 'client-name':
				getter = (object) =>
					AppStore.selectors.client(
						store.getState(),
						object?.clientId,
					)?.name;

				break;

			case 'total':
				getter = (object) => object?.sums?.original?.total;
				break;

			case 'currency':
				getter = (object) => object?.sums?.original?.currency;
				break;

			case 'message':
				getter = (object) => object?.meta?.message;
				break;

			case 'invoice-date-sent':
				// eslint-disable-next-line react/display-name
				getter = (object) =>
					object?.dates?.invoiceSent
						? new Date(object?.dates?.invoiceSent)
						: null;
				break;

			case 'invoice-date-due':
				// eslint-disable-next-line react/display-name
				getter = (object) =>
					object?.dates?.invoiceDue
						? new Date(object?.dates?.invoiceDue)
						: null;
				break;

			default:
				getter = (object) => object?.meta?.invoiceNumber;
				break;
		}

		const response = Array.from(objects).sort((a, b) => {
			const source = getter(a) ?? null;
			const target = getter(b) ?? null;

			if (source === null && target === null) {
				return 0;
			}

			if (source === null) {
				return -1;
			}

			if (target === null) {
				return 1;
			}

			if (
				!isNaN(Number.parseFloat(source)) &&
				!isNaN(Number.parseFloat(target))
			) {
				return Number.parseFloat(target) - Number.parseFloat(source);
			}

			if (isDate(source) && isDate(target)) {
				return compareAsc(target, source);
			}

			if (typeof source === 'string' && typeof target === 'string') {
				return source.localeCompare(target);
			}

			return 0;
		});

		if (direction === 'DESC') {
			response.reverse();
		}

		return response;
	}, [sorting, direction, objects, store]);
};

export function useInvoices({ onSubmit }) {
	const search = useSelector(InvoiceStore.selectors.search);

	const statuses = useSelector(
		(store) =>
			InvoiceStore.selectors
				.filters(store, {
					type: 'SERVICE:STATUS',
				})
				.map(({ value }) => value),
		(a, b) => isEqual(a, b),
	);

	const hasImportingIntegrations = useSelector(
		(store) =>
			!!IntegrationStore.selectors.integrations(store, {
				filters: [{ status: 'IMPORTING' }, { status: 'INITIATING' }],
			}).length,
	);

	const filters = useMemo(() => {
		if (statuses?.length && !statuses.includes('ERROR')) {
			return { services: { status: statuses } };
		}

		if (statuses.includes('ERROR')) {
			return { errors: true };
		}

		if (search?.length) {
			return null;
		}

		return { services: { status: null } };
	}, [search?.length, statuses]);

	return useQuery({
		queryKey: [
			'invoices',
			'table',
			{ statuses: statuses, filters: filters },
		],
		queryFn: async () =>
			onSubmit?.('invoices:available', {
				filters: filters,
				pageFilters: { first: 1_000 },
				changes: true,
				withPagination: true,
			}),

		placeholderData: [],

		// refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		retry: false,

		keepPreviousData: true,
		enabled: !hasImportingIntegrations,
	});
}

export { useInvoiceSorting };
