import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';

import { Translation, TranslationService } from '@asteria/language';

import '../styles.scss';

const InvoiceDetailsCellInfo = React.memo(function InvoiceDetailsCellInfo(
	props,
) {
	const { value, invoice, field, onAction } = props;

	const id = invoice?._id ?? invoice?.id;
	const hasErrors = (invoice?.errors ?? []).some(
		({ path }) => path === field,
	);

	const handleEdit = React.useCallback(
		() => onAction?.('invoice:edit', id),
		[onAction, id],
	);

	if (value) {
		if (typeof value === 'object' && React.isValidElement(value)) {
			return value;
		}

		return <Text size="sm">{value}</Text>;
	}

	if (hasErrors) {
		return (
			<Button
				size="sm"
				variant="link"
				label={TranslationService.getV2(
					[
						'invoice.modal.details.section.item.value',
						`invoice.modal.details.section.item.${field}.value`,
						`invoice.modal.details.${invoice?.type}.section.item.value`,
						`invoice.modal.details.${invoice?.type}.section.item.${field}.value`,
					],
					{
						data: { invoice: invoice },
						postfix: { multi: invoice?.errors?.length },
					},
				)}
				onClick={handleEdit}
			/>
		);
	}

	return (
		<Translation
			translationKey={[
				'invoice.modal.details.section.item.value',
				`invoice.modal.details.section.item.${field}.value`,
				`invoice.modal.details.${invoice?.type}.section.item.value`,
				`invoice.modal.details.${invoice?.type}.section.item.${field}.value`,
			]}
			translationOptions={{ data: { invoice: invoice } }}
			size="sm"
			Component={Text}
		/>
	);
});

InvoiceDetailsCellInfo.propTypes = {
	field: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	invoice: PropTypes.object,
	onAction: PropTypes.func,
};

export default InvoiceDetailsCellInfo;
