import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Chip from '@asteria/component-chip';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';

/** @type { React.FC<{ id: string }> } */
const SearchCurrencyChip = React.memo(function SearchCurrencyChip(props) {
	const { currency } = props;

	const dispatch = useDispatch();

	const onDismiss = React.useCallback(() => {
		dispatch(
			InvoiceStore.filter({
				type: 'CURRENCY',
				value: currency,
			}),
		);
	}, [currency, dispatch]);

	return (
		<Chip
			size="sm"
			label={TranslationService.get(
				['invoices.search.quick.currency.label'],
				undefined,
				{ currency: currency },
			)}
			dismiss
			onDismiss={onDismiss}
			active
		/>
	);
});

SearchCurrencyChip.propTypes = { currency: PropTypes.string };

const SearchFiltersCurrencies = React.memo(function SearchFiltersCurrencies() {
	const filters = useSelector(
		(store) => InvoiceStore.selectors.filters(store, { type: 'CURRENCY' }),
		isEqual,
	);

	return filters.map((object, index) => (
		<SearchCurrencyChip key={index} currency={object?.value} />
	));
});

export default SearchFiltersCurrencies;
