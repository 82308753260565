import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';

import Chip from '@asteria/component-chip';

import { TranslationService } from '@asteria/language';

/**
 * @typedef Props
 * @property { import('@tanstack/react-query').UseQueryResult } query
 * @property { string } [active]
 * @property { (id: string) => void } onChange
 * @property { { postfix?: unknown, data?: unknown } } translationOptions
 */

/** @type { React.FC<Props> } */
const Switcher = React.memo(function Switcher(props) {
	const { query, active, onChange, translationOptions } = props;

	const invoices = query?.data?.invoices ?? [];

	const errors = invoices
		.filter((invoice) => !invoice?.discard?.length)
		.filter((invoice) => invoice?.errors?.length);

	const ignored = invoices.filter((invoice) => invoice?.discard?.length);

	return (
		<Group
			className="asteria-component__chip-group"
			direction="horizontal"
			verticalAlign="center"
			horizontalAlign="left"
		>
			<Chip
				size="sm"
				label={TranslationService.getV2(
					['print.details.switcher.chip.label'],
					{
						postfix: {
							type: 'all',
							...translationOptions.postfix,
						},
						data: {
							...translationOptions.data,
							count: invoices.length,
						},
					},
				)}
				active={active === null}
				onClick={() => onChange(null)}
			/>

			{errors.length ? (
				<Chip
					size="sm"
					label={TranslationService.getV2(
						['print.details.switcher.chip.label'],
						{
							postfix: {
								type: 'error',
								...translationOptions.postfix,
							},
							data: {
								...translationOptions.data,
								count: errors.length,
							},
						},
					)}
					active={active === 'ERROR'}
					onClick={() => onChange('ERROR')}
				/>
			) : null}

			{ignored.length ? (
				<Chip
					size="sm"
					label={TranslationService.getV2(
						['print.details.switcher.chip.label'],
						{
							postfix: {
								type: 'ignored',
								...translationOptions.postfix,
							},
							data: {
								...translationOptions.data,
								count: ignored.length,
							},
						},
					)}
					active={active === 'IGNORED'}
					onClick={() => onChange('IGNORED')}
				/>
			) : null}
		</Group>
	);
});

Switcher.propTypes = {
	query: PropTypes.object,
	active: PropTypes.string,
	onChange: PropTypes.func,
	translationOptions: PropTypes.object,
};

export default Switcher;
