import React from 'react';

import PropTypes from 'prop-types';

import Form from '@asteria/component-form/form';

import { cn } from '@asteria/utils-funcs/classes';

import SearchClients from './clients';
import SearchCurrency from './currencies';
import SearchDate from './date';
import SearchFilters from './filters';
import SearchInput from './input';
import SearchReset from './reset';
import SearchSwitcher from './switcher';

import './styles.scss';

/** @type { React.FC<import('./types').BaseProps> } */
const SearchContent = React.memo(function SearchContent(props) {
	const { className } = props;

	const next = { ...props, className: null };

	return (
		<div
			className={cn(
				'flex flex-col gap-4 overflow-x-auto',
				'asteria-component__invoice-search-wrapper',
				className,
			)}
		>
			<div className="flex flex-col md:flex-row gap-4 items-center">
				<div className="flex-1 flex gap-4 items-center">
					<SearchSwitcher {...next} />
					<SearchInput {...next} className="flex-1" />
				</div>
				<div className="flex gap-4 items-center">
					<SearchClients {...next} className="flex-1 min-w-[120px]" />
					<SearchCurrency
						{...next}
						className="flex-1 min-w-[120px]"
					/>
					<SearchDate {...next} />
				</div>
			</div>

			<SearchFilters {...next} />
			<SearchReset {...next} />
		</div>
	);
});

SearchContent.displayName = 'SearchContent';

SearchContent.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

/** @type { React.FC<import('./types').BaseProps> } */
const SearchWrapper = React.memo(function SearchWrapper(props) {
	return (
		<Form className="overflow-x-auto">
			<SearchContent {...props} />
		</Form>
	);
});

SearchWrapper.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default SearchWrapper;
