import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import SearchFilterClients from './clients';
import { INVOICE_STATUSES, INVOICE_TYPES } from './constants';
import SearchFilterCurrencies from './currencies';
import SearchFilterDate from './dates';
import SearchFilterInvoiceAmounts from './invoice-amounts';
import SearchFilterInvoiceStatuses from './invoice-statuses';
import SearchFilterInvoiceTypes from './invoice-types';
import SearchFilterPrintStatuses from './print-statuses';

/** @type { React.FC<import('../types').BaseProps> } */
const SearchFilters = React.memo(function SearchFilters(props) {
	const { className, type } = props;

	const next = { ...props };

	return (
		<div
			className={cn(
				'flex gap-2 overflow-x-auto md:overflow-x-visible',
				'asteria-component__invoice-search-part',
				'asteria--type-filters',
				className,
			)}
		>
			<SearchFilterClients {...next} />
			<SearchFilterCurrencies {...next} />
			<SearchFilterDate {...next} />

			{type === 'invoices' ? (
				<>
					<SearchFilterInvoiceStatuses
						{...next}
						statuses={INVOICE_STATUSES}
					/>
					<SearchFilterInvoiceTypes {...next} types={INVOICE_TYPES} />
					<SearchFilterInvoiceAmounts
						{...next}
						types={INVOICE_TYPES}
					/>
				</>
			) : null}

			{type === 'prints' ? (
				<>
					<SearchFilterPrintStatuses {...next} />
				</>
			) : null}
		</div>
	);
});

SearchFilters.propTypes = {
	className: PropTypes.string,

	type: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default SearchFilters;
