import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { ViewportList } from 'react-viewport-list';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Table, { TableCell, TableRow } from '@asteria/component-core/table';
import { Text, Title } from '@asteria/component-core/typography';
import { getItemBoundingClientRect } from '@asteria/component-core/utils/viewportList';

import Form from '@asteria/component-form';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import SearchReset from '../search/reset';

import Filters from './Filters';
import Row from './Row';
import { useInvoiceSorting } from './hooks';
import RemoveButton from './remove-button';
import { getGridColumn } from './utils';

import './styles.scss';

const Invoices = React.memo(
	React.forwardRef((props, ref) => {
		const {
			objects,
			onAction,
			fields,
			useViewport = true,
			loading,
		} = props;

		const renderSpacer = React.useCallback(
			({ ref, style }) => (
				<div
					className={cn(
						'asteria-component__viewport-spacer',
						'asteria-component__invoice-table__spacer',
					)}
					ref={ref}
					style={style}
				/>
			),
			[],
		);

		if (loading) {
			return Array.from({ length: 5 }).map((_, index) => (
				<Row
					key={index}
					onAction={onAction}
					fields={fields}
					loading={loading}
				/>
			));
		}

		if (!useViewport) {
			return objects.map((object) => (
				<Row
					key={object?._id ?? object.id}
					object={object}
					onAction={onAction}
					fields={fields}
				/>
			));
		}

		return (
			<ViewportList
				items={objects}
				viewportRef={ref}
				itemSize={40}
				overscan={10}
				scrollThreshold={40}
				getItemBoundingClientRect={getItemBoundingClientRect}
				renderSpacer={renderSpacer}
			>
				{(object) => (
					<Row
						key={object?._id ?? object.id}
						object={object}
						onAction={onAction}
						fields={fields}
					/>
				)}
			</ViewportList>
		);
	}),
);

Invoices.displayName = 'Invoices';
Invoices.propTypes = {
	objects: PropTypes.arrayOf(PropTypes.object),
	onAction: PropTypes.func,
	useViewport: PropTypes.bool,

	fields: PropTypes.arrayOf(
		PropTypes.shape({ name: PropTypes.string, tooltip: PropTypes.bool }),
	),
	loading: PropTypes.bool,
};

function useVariant() {
	const filtersIsEmpty = useSelector((store) => {
		const search = InvoiceStore.selectors.search(store);
		const filters = InvoiceStore.selectors.filters(store);

		return !search && !filters?.length;
	});

	const hasIntegration = useSelector(
		(store) =>
			!!IntegrationStore.selectors.integrations(store, {
				type: 'erp',
				filters: [
					{ status: 'IDLE' },
					{ status: 'IMPORTING', connected: true },
				],
			}).length,
	);

	const hasPrinters = useSelector(
		(store) =>
			!!IntegrationStore.selectors.integrations(store, {
				type: 'erp',
				filters: [{ key: 'printer' }],
			}).length,
	);

	const hasLayouts = useSelector(
		(store) =>
			!!InvoiceStore.selectors.layouts(store, {
				status: 'PENDING',
			}).length,
	);

	return React.useMemo(() => {
		if (!hasIntegration) {
			return 'connection';
		}

		if (hasPrinters && hasLayouts && filtersIsEmpty) {
			return 'printer';
		}

		if (filtersIsEmpty) {
			return 'available';
		}

		return 'filters';
	}, [filtersIsEmpty, hasIntegration, hasLayouts, hasPrinters]);
}

const NoInvoices = React.memo((props) => {
	const { onAction } = props;

	const layouts = useSelector(
		(store) =>
			InvoiceStore.selectors.layouts(store, {
				status: 'PENDING',
			}),
		(a, b) => isEqual(a, b),
	);

	const variant = useVariant();

	const handleConnectionOpen = React.useCallback(
		() => onAction?.('go', '/onboarding'),
		[onAction],
	);

	return (
		<TableRow
			className={cn(
				'asteria--state-empty',
				`asteria--variant-${variant}`,
			)}
		>
			<TableCell>
				<Group
					direction="horizontal"
					verticalAlign="center"
					horizontalAlign="center"
				>
					{variant === 'connection' ? (
						<Button
							icon="integrations"
							variant="primary"
							size="sm"
						/>
					) : null}
					{variant === 'printer' ? (
						<Button icon="document" variant="primary" size="sm" />
					) : null}
					<Title size="sm">
						{TranslationService.get(
							[
								'invoices.table.empty.title',
								`invoices.table.empty.${variant}.title`,
							],
							undefined,
							{ layouts: layouts },
						)}
					</Title>
				</Group>
				<Text align="center">
					{TranslationService.get(
						[
							'invoices.table.empty.content',
							`invoices.table.empty.${variant}.content`,
						],
						undefined,
						{ layouts: layouts },
					)}
				</Text>
				{variant === 'printer' ? (
					<Text align="center">
						{TranslationService.get(
							[
								'invoices.table.empty.content.1',
								`invoices.table.empty.${variant}.content.1`,
							],
							undefined,
							{ layouts: layouts },
						)}
					</Text>
				) : null}
				{variant === 'connection' ? (
					<Button
						variant="link"
						label={TranslationService.get(
							[
								'invoices.table.empty.action.label',
								`invoices.table.empty.${variant}.action.label`,
							],
							undefined,
							{ layouts: layouts },
						)}
						onClick={handleConnectionOpen}
					/>
				) : null}
				{variant === 'filters' ? <SearchReset /> : null}
			</TableCell>
		</TableRow>
	);
});

NoInvoices.displayName = 'NoInvoices';
NoInvoices.propTypes = { onAction: PropTypes.func };

function useData({ data: objects, sorting, direction }) {
	const filters = useSelector(InvoiceStore.selectors.filters);
	const selected = useSelector(InvoiceStore.selectors.selected);
	const search = useSelector(InvoiceStore.selectors.search);

	return useInvoiceSorting({
		objects: InvoiceStore.utils.applyFilters({
			objects,
			search,
			filters,
			selected,
		}),
		sorting: sorting,
		direction: direction,
	});
}

const InvoiceTable = React.memo((props) => {
	const { className, onAction, onSubmit, fields, data } = props;

	const dispatch = useDispatch();
	const ref = React.useRef(null);

	const [{ sorting, direction }, setSorting] = React.useState({
		sorting: null,
		direction: 'ASC',
	});

	const objects = useData({ data, sorting, direction });

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'invoices:select') {
				dispatch(InvoiceStore.select(data));
			}

			return onAction?.(action, data);
		},
		[dispatch, onAction],
	);

	const handleSorting = React.useCallback((key) => {
		setSorting((current) => {
			if (current.sorting === key) {
				if (current.direction === 'ASC') {
					return { ...current, direction: 'DESC' };
				}

				return { sorting: null, direction: null };
			}

			return { sorting: key, direction: 'ASC' };
		});
	}, []);

	const style = React.useMemo(() => {
		const available = (fields ?? []).map(({ name }) => name);

		const hasMessage = available.includes('message');

		return {
			'--size': available.length,
			'--invoice-table-template': available
				.map((name) =>
					getGridColumn(
						!hasMessage && name === 'client-name'
							? 'flexible'
							: name,
					),
				)
				.join(' '),
		};
	}, [fields]);

	return (
		<>
			<Form className="asteria-component__invoice-table__form">
				<RemoveButton onAction={onAction} onSubmit={onSubmit} />
				<Table
					className={cn(
						'asteria-component__invoice-table',
						{ 'asteria--state-empty': !objects.length },
						className,
					)}
					ref={ref}
					style={style}
				>
					<Filters
						onAction={handleAction}
						onSorting={handleSorting}
						objects={objects}
						sorting={sorting}
						direction={direction}
						fields={fields}
					/>
					{objects.length ? (
						<Invoices
							ref={ref}
							objects={objects}
							onAction={handleAction}
							fields={fields}
						/>
					) : (
						<NoInvoices onAction={onAction} />
					)}
				</Table>
			</Form>
		</>
	);
});

InvoiceTable.displayName = 'InvoiceTable';

InvoiceTable.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	fields: PropTypes.arrayOf(
		PropTypes.shape({ name: PropTypes.string, tooltip: PropTypes.bool }),
	),

	data: PropTypes.arrayOf(PropTypes.object),
	loading: PropTypes.bool,
};

export default InvoiceTable;
export { Invoices };
