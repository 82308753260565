import React from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';

import { Translation, TranslationService } from '@asteria/language';

/**
 * @typedef Print
 * @property { string } id
 * @property { string } status
 * @property { string } createdAt
 *
 * @typedef Props
 * @property { Print } print
 * @property { boolean } open
 * @property { import('react').MouseEventHandler } onClose
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 */

/** @type { React.FC<Props> } */
const PrintIgnoreInvoicesModal = React.memo(function PrintIgnoreInvoicesModal(
	props,
) {
	const { open, print, onClose, onSubmit } = props;

	const id = print?._id ?? print?.id;
	const invoices = (print?.invoices ?? [])
		.filter((invoice) => !invoice?.discard?.length)
		.filter((invoice) => invoice?.errors?.length)
		.map((invoice) => invoice?._id ?? invoice?.id);

	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationFn: async () =>
			onSubmit?.('print:invoices:ignore', { id, invoices: invoices }),
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				predicate: ({ queryKey }) =>
					queryKey.includes(id) || queryKey.includes('prints'),
			});

			return onClose();
		},
	});

	const translationOptions = React.useMemo(
		() => ({
			postfix: { status: print?.status },
			data: { id, data: print },
		}),
		[id, print],
	);

	return (
		<Modal open={open} onClose={onClose} size="sm">
			<Wrapper>
				<Header onClose={onClose}>
					{TranslationService.getV2(
						['print.invoices.ignore.title'],
						translationOptions,
					)}
				</Header>
				<Content>
					<Translation
						translationKey="print.invoices.ignore.content"
						translationOptions={translationOptions}
						Component={Text}
					/>
				</Content>
				<Footer>
					<FooterSection position="first">
						<Button
							variant="tertiary"
							label={TranslationService.getV2(
								['print.invoices.ignore.action'],
								{
									...translationOptions,
									postfix: {
										...translationOptions?.postfix,
										type: 'cancel',
									},
								},
							)}
							onClick={onClose}
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							label={TranslationService.getV2(
								['print.invoices.ignore.action'],
								{
									...translationOptions,
									postfix: {
										...translationOptions?.postfix,
										type: 'submit',
									},
								},
							)}
							onClick={mutation.mutate}
							loading={mutation.isLoading}
							disabled={mutation.isLoading}
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</Modal>
	);
});

PrintIgnoreInvoicesModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	print: PropTypes.object,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default PrintIgnoreInvoicesModal;
