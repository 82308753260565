import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const ClientDetailsOverview = (props) => {
	const { className, client } = props;

	const { currency, total } = useMemo(
		() => ({
			currency:
				client?.statistics?.invoices?.total?.display?.[0]?.currency ??
				'SEK',
			total:
				client?.statistics?.invoices?.total?.display?.[0]?.total ?? 0,
		}),
		[client?.statistics?.invoices?.total?.display],
	);

	return (
		<div
			className={cn(
				'asteria-component__client-overview__details__overview',
				{ 'asteria--state-error': !!client?.errors?.length },
				className,
			)}
		>
			<Title size="sm">
				{TranslationService.get(
					[
						'client.overview.details.header',
						`client.overview.details.${client?.type}.header`,
					],
					undefined,
					{ client: client },
				)}
			</Title>
			<TextGroup>
				<Text size="sm">
					{TranslationService.get(
						[
							'client.overview.details.revenue',
							`client.overview.details.${client?.type}.revenue`,
						],
						// eslint-disable-next-line spellcheck/spell-checker
						'Omsättning 2021',
						{ client: client },
					)}
				</Text>
				<Text size="sm">
					{TranslationService.get(
						[
							'client.overview.details.revenue.value',
							`client.overview.details.${client?.type}.revenue.value`,
						],
						'{{total : number}} {{currency}}',
						{ client: client, currency, total },
					)}
				</Text>
			</TextGroup>
		</div>
	);
};

ClientDetailsOverview.propTypes = {
	className: PropTypes.string,
	client: PropTypes.object,

	onFilterLinkClick: PropTypes.func,
};

export default ClientDetailsOverview;
