import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Icon from '@asteria/component-core/icon';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

/**
 * @typedef Props
 * @property { string } className
 * @property { string } type
 * @property { { code: string, message: string }[] } errors
 * @property { boolean } contact
 * @property { unknown } extra
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 * @property { 'error' | 'warning' | 'success' | 'info' } variant
 */

/** @type { React.FC<Props> } */
const ServerError = React.memo(function ServerError(props) {
	const {
		className,
		type,
		errors,
		contact,
		onAction,
		extra,
		variant = 'error',
	} = props;

	const postfix = { type, multi: errors?.length > 1, contact };

	const onActionClick = React.useCallback(
		() => onAction?.('server:error:action', { type }),
		[onAction, type],
	);

	if (!errors?.length) {
		return null;
	}

	return (
		<div
			className={cn(
				'relative p-4',
				{ 'bg-alert-error-normal-background': variant === 'error' },
				{ 'bg-alert-warning-normal-background': variant === 'warning' },
				{ 'bg-alert-success-normal-background': variant === 'success' },
				{ 'bg-alert-info-normal-background': variant === 'info' },
				'asteria-component__server-error',
				{ [`asteria--variant-${variant}`]: variant },
				className,
			)}
		>
			<TextGroup className="pr-7">
				<Translation
					translationKey="server.alert.title"
					translationOptions={{
						postfix: postfix,
						data: { ...extra, errors },
					}}
					Component={Title}
					className={cn(
						{
							'text-alert-error-normal-title':
								variant === 'error',
						},
						{
							'text-alert-warning-normal-title':
								variant === 'warning',
						},
						{
							'text-alert-success-normal-title':
								variant === 'success',
						},
						{
							'text-alert-info-normal-title': variant === 'info',
						},
					)}
				/>
				{(errors ?? []).map((error, index) => {
					let type = null;

					if (error?.customerNo) {
						type = 'customer';
					}

					if (error?.sellerOrderID) {
						type = 'invoice';
					}

					return (
						<Translation
							translationKey={[
								'server.alert.content',
								error?.message,
							]}
							translationOptions={{
								default: error?.message,
								data: { ...extra, errors, error },
								postfix: {
									type: type,
									code: error?.code,
								},
							}}
							key={index}
							Component={Text}
							className={cn(
								{
									'text-alert-error-normal-text':
										variant === 'error',
								},
								{
									'text-alert-warning-normal-text':
										variant === 'warning',
								},
								{
									'text-alert-success-normal-text':
										variant === 'success',
								},
								{
									'text-alert-info-normal-text':
										variant === 'info',
								},
							)}
						/>
					);
				})}
			</TextGroup>
			<div
				className={cn(
					'absolute top-4 right-4',
					'asteria-component__alert-icon',
					{ [`asteria--status-${variant}`]: variant },
				)}
			>
				<Icon size="sm" icon="warning" />
			</div>

			{contact ? (
				<Button
					variant="link"
					label={TranslationService.getV2(['server.alert.action'], {
						postfix: postfix,
						data: { ...extra, errors },
					})}
					onClick={onActionClick}
				/>
			) : null}
		</div>
	);
});

ServerError.displayName = 'ServerError';

ServerError.propTypes = {
	className: PropTypes.string,

	type: PropTypes.string,
	errors: PropTypes.arrayOf(PropTypes.object),
	contact: PropTypes.bool,
	extra: PropTypes.object,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	variant: PropTypes.string,
};

export default ServerError;
