import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useQueries } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { TooltipWrapper } from '@asteria/component-core/tooltip';

import Chip from '@asteria/component-chip';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';

/** @type { React.FC<{ type: string, query: import('@tanstack/react-query').UseQueryResult }> } */
const SearchTypeChip = React.memo(function SearchTypeChip(props) {
	const { type, query } = props;

	const dispatch = useDispatch();

	const count = query?.data ?? 0;

	const active = useSelector(
		(store) =>
			!!InvoiceStore.selectors.filters(store, {
				type: 'TYPE',
				value: type,
			}).length,
	);

	const onClick = React.useCallback(() => {
		dispatch(
			InvoiceStore.filter({
				type: 'TYPE',
				value: type,
			}),
		);
	}, [dispatch, type]);

	if (!count) {
		return null;
	}

	return (
		<TooltipWrapper
			tooltip={TranslationService.get(
				[
					'invoices.search.quick.type.tooltip',
					`invoices.search.quick.type.${type.toLowerCase()}.tooltip`,
				].concat(
					active
						? [
								'invoices.search.quick.type.active.tooltip',
								`invoices.search.quick.type.${type.toLowerCase()}.active.tooltip`,
						  ]
						: [],
				),
				undefined,
				{ invoices: { length: count } },
			)}
		>
			<Chip
				size="sm"
				label={TranslationService.get(
					[
						'invoices.search.quick.type.label',
						`invoices.search.quick.type.${type.toLowerCase()}.label`,
					].concat(
						active
							? [
									'invoices.search.quick.type.active.label',
									`invoices.search.quick.type.${type.toLowerCase()}.active.label`,
							  ]
							: [],
					),
					undefined,
					{ invoices: { length: count } },
				)}
				onClick={onClick}
				active={active}
			/>
		</TooltipWrapper>
	);
});

SearchTypeChip.propTypes = {
	type: PropTypes.string,
	query: PropTypes.object,
};

/** @type { React.FC<import('../types').BaseProps> } */
const SearchFilterTypes = React.memo(function SearchFilterTypes(props) {
	const { types, onSubmit } = props;

	const statuses = useSelector(
		(store) =>
			InvoiceStore.selectors
				.filters(store, {
					type: 'SERVICE:STATUS',
				})
				.map(({ value }) => value),
		isEqual,
	);

	const queries = useQueries({
		queries: types.map(({ type }) => ({
			queryKey: [
				'invoices',
				'search',
				'type',
				{ type: type },
				{ statuses: statuses },
			],
			queryFn: async () => {
				return onSubmit?.('invoices:available', {
					filters: {
						type: type,
						services: {
							status: statuses.length ? statuses : null,
						},
						errors: statuses.includes('ERROR'),
					},
					pageFilters: { first: 1 },
					fields: `_id`,
				});
			},
			select: (response) => response?.pageInfo?.count ?? 0,

			placeholderData: 0,

			refetchOnMount: true,
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,
		})),
	});

	return types.map(({ type }, index) => (
		<SearchTypeChip key={type} type={type} query={queries[index]} />
	));
});

export default SearchFilterTypes;
