import { CompanyService } from '@asteria/backend-utils-services';

import store from '@asteria/datalayer';

import Analytics from '@asteria/utils-analytics';
import * as BaseCompanyAPI from '@asteria/widget-base/api/company';

export async function updateService({ accessToken, dispatch, id, input }) {
	await CompanyService.service
		.update(
			{ id: id, input: input, fields: `ok data { id serviceId }` },
			{ token: accessToken },
		)
		.catch((err) => {
			// eslint-disable-next-line no-console
			console.warn('company.service.update', err, { id, input });
		});

	return BaseCompanyAPI.fetch({
		accessToken: accessToken,
		dispatch: dispatch,
	});
}

export async function addService({ accessToken, dispatch, id, input }) {
	await CompanyService.service
		.create({ serviceId: id, input: input }, { token: accessToken })
		.catch((err) => {
			// eslint-disable-next-line no-console
			console.warn('company.service.create', err, { id, input });
		});

	return BaseCompanyAPI.fetch({
		accessToken: accessToken,
		dispatch: dispatch,
	});
}

export async function refreshSettingsFlags({ accessToken, dispatch }) {
	const company = store.getState()?.app?.company;
	const flags = company?.settings?.flags ?? {};

	Analytics.event('company.settings.flags.refresh', {
		company: company,
		data: flags,
	});

	await BaseCompanyAPI.updateMyCompany({
		accessToken,
		input: { settings: { flags: flags } },
	});

	return BaseCompanyAPI.fetch({ accessToken, dispatch });
}

export async function clean({ accessToken, id }) {
	return CompanyService.company.clean(
		{ filters: { _id: id } },
		{ token: accessToken },
	);
}
