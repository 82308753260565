import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { Input, useFormValues } from '@asteria/component-form';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const SearchInputPostfix = React.memo(function SearchInputPostfix() {
	const dispatch = useDispatch();

	const { resetField } = useFormContext();
	const value = useFormValues({ name: 'search-value' });

	const onReset = React.useCallback(() => {
		resetField('search-value');
		dispatch(InvoiceStore.search(''));
	}, [dispatch, resetField]);

	if (!value) {
		return null;
	}

	return <Button icon="close" size="sm" onClick={onReset} />;
});

/** @type { React.FC<import('./types').BaseProps> } */
const SearchInput = React.memo(function SearchInput(props) {
	const { className } = props;

	const dispatch = useDispatch();

	const onChange = React.useCallback(
		({ value }) => {
			dispatch(InvoiceStore.search(value));
		},
		[dispatch],
	);

	return (
		<div
			className={cn(
				'asteria-component__invoice-search-part',
				'asteria--type-input',
				className,
			)}
		>
			<Input
				name="search-value"
				placeholder={TranslationService.get([
					'invoices.search.placeholder',
				])}
				icon="magnifier"
				iconSize="md"
				onChange={onChange}
				debounce={500}
				postfix={<SearchInputPostfix />}
			/>
		</div>
	);
});

SearchInput.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default SearchInput;
