import React from 'react';

import PropTypes from 'prop-types';

import Modal from '@asteria/component-modal';

import { cn } from '@asteria/utils-funcs/classes';

import ReviewModalContent from './components/content';
import BatchErrorDetails from './components/error';

import './styles.scss';

const ReviewModal = React.memo(function ReviewModal(props) {
	const { open, onClose } = props;

	return (
		<Modal
			open={open}
			onClose={onClose}
			className={cn(
				'asteria-component__invoice-modal',
				'asteria--type-review',
			)}
			size="lg"
			scroll
		>
			<ReviewModalContent {...props} />
		</Modal>
	);
});

ReviewModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	invoices: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string,
	id: PropTypes.string,
};

export default ReviewModal;
export { BatchErrorDetails };
