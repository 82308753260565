export const INVOICE_STATUSES = [
	{ status: 'SENT' },
	{ status: 'ERROR', badge: true },
	{ status: 'COMPLETED' },
	{ status: 'DISCARD' },
];

export const INVOICE_TYPES = [
	{ type: 'credit' },
	// { type: 'invoice' },
];
