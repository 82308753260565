import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

/**
 * @typedef Props
 * @property { import('@tanstack/react-query').UseQueryResult } query
 * @property { { postfix?: unknown, data?: unknown } } translationOptions
 * @property { (action: string, data: unknown) => Promise<unknown> } onSubmit
 * @property { (action: string, data: unknown) => Promise<unknown> } onAction
 * @property { 'error' | 'warning' } [status]
 */

/** @type { React.FC<Props> } */
const PrintDetailsInfo = React.memo(function PrintDetailsInfo(props) {
	const { query, translationOptions, status } = props;

	return (
		<div
			className={cn(
				'-mx-6 p-6 bg-alert-success-normal-background flex flex-col gap-2 items-center justify-center',
				'asteria-component__print-details-alert',
				{ [`asteria--state-${status}`]: status },
			)}
		>
			<Translation
				translationKey="print.details.alert.prefix"
				translationOptions={translationOptions}
				Component={Text}
				align="center"
			/>
			<TextGroup className="flex flex-col items-center justify-center gap-1">
				<Translation
					translationKey="print.details.alert.title"
					translationOptions={translationOptions}
					Component={Title}
					align="center"
				/>
				<Translation
					translationKey="print.details.alert.content"
					translationOptions={translationOptions}
					Component={Text}
					align="center"
					loading={query.isFetching && !query.isRefetching}
				/>
			</TextGroup>

			{query?.data?.pdfUri ? (
				<Button
					variant="link"
					icon="external"
					label={TranslationService.getV2(
						['print.details.alert.action'],
						translationOptions,
					)}
					href={TranslationService.get(
						'page.invoices.pending.table.open',
						undefined,
						{ uri: query?.data?.pdfUri },
					)}
					target="_blank"
				/>
			) : null}
		</div>
	);
});

PrintDetailsInfo.propTypes = {
	query: PropTypes.object,
	translationOptions: PropTypes.object,
	status: PropTypes.string,
};

export default PrintDetailsInfo;
