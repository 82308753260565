/* eslint-disable spellcheck/spell-checker */
import React from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Text, Title } from '@asteria/component-core/typography';

import { useFAQ } from '@asteria/component-faq';
import { useFlow } from '@asteria/component-integrations-v2/hooks';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import './styles.scss';

function useBreadcrumbs() {
	const { pathname, search } = useLocation();

	const params = useParams();
	const navigate = useNavigate();

	const onFAQClick = React.useCallback(
		() => navigate(`/faq${search}`),
		[navigate, search],
	);

	const onFAQSectionClick = React.useCallback(
		() => navigate(`/faq/${params.name}${search}`),
		[navigate, params.name, search],
	);

	const onIntegrationsClick = React.useCallback(
		() => navigate(`/onboarding${search}`),
		[navigate, search],
	);

	// const onGuideClick = React.useCallback(
	// 	() => navigate(`/guide${search}`),
	// 	[navigate, search],
	// );

	const faqSections = useConfig('pages.faq.sections', { deep: true });
	// const guides = useConfig('pages.common.guides', { deep: true });

	const flow = useFlow(params?.type, params?.key);
	const faq = useFAQ(faqSections, { name: params?.name, id: params?.id });

	// const guide = (guides ?? []).find((object) => object?.id === params?.id);

	if (pathname.startsWith('/onboarding')) {
		if (params?.key) {
			return [
				<Button
					key="integrations"
					variant="link"
					onClick={onIntegrationsClick}
				>
					<Title size="xxs">
						{TranslationService.get(['breadcrumbs.integrations'])}
					</Title>
				</Button>,
				<Title key="connection" size="xxs">
					{TranslationService.get(
						[
							flow?.name,
							'breadcrumbs.connection',
							`breadcrumbs.connection.${flow?.key}`,
							`breadcrumbs.connection.${flow?.type}.${flow?.key}`,
						],
						flow?.name,
					)}
				</Title>,
			];
		}
	}

	if (pathname.startsWith('/faq')) {
		if (params?.id) {
			return [
				<Button key="faq" variant="link" onClick={onFAQClick}>
					<Title size="xxs">
						{TranslationService.get(['breadcrumbs.faq'])}
					</Title>
				</Button>,
				<Button
					key="faq-section"
					variant="link"
					onClick={onFAQSectionClick}
				>
					<Title size="xxs">
						{TranslationService.get([
							'breadcrumbs.faq',
							'faq.content.title',
							`faq.content.title.${params?.name}`,
						])}
					</Title>
				</Button>,
				<Title key="faq-details" size="xxs">
					{TranslationService.get(
						[
							faq?.title,
							`breadcrumbs.faq.${params?.name}`,
							`breadcrumbs.faq.${params?.name}.${params?.id}`,
						],
						faq?.title,
					)}
				</Title>,
			];
		}

		if (params?.name) {
			return [
				<Button key="faq" variant="link" onClick={onFAQClick}>
					<Title size="xxs">
						{TranslationService.get(['breadcrumbs.faq'])}
					</Title>
				</Button>,
				<Title key="faq-details" size="xxs">
					{TranslationService.get([
						'breadcrumbs.faq',
						'faq.content.title',
						`faq.content.title.${params?.name}`,
					])}
				</Title>,
			];
		}
	}

	// if (pathname.startsWith('/guide')) {
	// 	if (guide) {
	// 		return [
	// 			<Button key="guide" variant="link" onClick={onGuideClick}>
	// 				<Title size="xxs">
	// 					{TranslationService.get(['breadcrumbs.guide'])}
	// 				</Title>
	// 			</Button>,
	// 			<Title key="guide-details" size="xxs">
	// 				{TranslationService.get([
	// 					'breadcrumbs.guide',
	// 					`guide.box.title`,
	// 					`guide.box.${guide?.id}.title`,
	// 					`breadcrumbs.guide.${guide?.id}`,
	// 				])}
	// 			</Title>,
	// 		];
	// 	}
	// }

	return [];
}

const Breadcrumbs = (props) => {
	const { className } = props;

	const parts = useBreadcrumbs();

	if (!parts?.length) {
		return null;
	}

	return (
		<Group
			className={cn('asteria-component__breadcrumbs', className)}
			direction="horizontal"
			horizontalAlign="left"
			verticalAlign="center"
		>
			{parts.flatMap((link, index) =>
				[
					link,
					index !== parts.length - 1 ? (
						<Text
							size="xs"
							className="asteria-component__breadcrumbs-spacer"
						>
							/
						</Text>
					) : null,
				].filter(Boolean),
			)}
		</Group>
	);
};

Breadcrumbs.displayName = 'Breadcrumbs';

Breadcrumbs.propTypes = { className: PropTypes.string };

export default Breadcrumbs;
