import React from 'react';

export function useBatchFilters({ batch, invoices }) {
	return React.useMemo(() => {
		if (!invoices?.length) {
			return batch;
		}

		return {
			...batch,
			invoices: new Array(invoices?.length).fill().map((_, index) => ({
				invoiceId: invoices[index]._id ?? invoices[index].id,
			})),
		};
	}, [batch, invoices]);
}

export function useInvoiceFilters({ invoices = [], filters }) {
	return React.useMemo(() => {
		return invoices?.filter((object) => {
			if (filters?.status === 'ERROR' && !object?.errors?.length) {
				return false;
			}

			return true;
		});
	}, [filters, invoices]);
}

export function useFilters(
	$filters = {
		status: 'ALL',
	},
) {
	const [filters, setFilter] = React.useState($filters);

	const setFilters = React.useCallback((data) => {
		return setFilter((prev) => ({ ...prev, ...data }));
	}, []);

	return { setFilters, filters };
}
