import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { AuthProfileChangeModal } from '@asteria/view-auth';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import ClientOverviewModal from '../components/Clients/Details';
import ClientEditModal from '../components/Clients/Edit';
import ClientListModal from '../components/Clients/List';
import InvoiceDetailsModal from '../components/Invoices/DetailsModal';
import InvoiceEditModal from '../components/Invoices/EditModal';
import ReviewModal from '../components/Invoices/ReviewModal';
import InvoiceSelectedConfirmModal from '../components/Invoices/SelectedConfirmModal';
import { PrintDetailsModal } from '../components/prints/modals/details';

const ModalCollection = (props) => {
	const current = useSelector(ModalStore.selectors.current, (a, b) =>
		isEqual(a, b),
	);

	const type = current?.type;

	const options = React.useMemo(
		() => ({ ...props, ...(current?.data ?? {}) }),
		[current?.data, props],
	);

	if (!type) {
		return null;
	}

	return (
		<>
			<ClientEditModal
				open={type === ModalStore.MODAL_WINDOWS.ClientEdit}
				className="asteria-widget__modal__client-overview"
				{...options}
			/>
			<ClientListModal open={type === 'CLIENT_LIST'} {...options} />
			<ClientOverviewModal
				open={type === ModalStore.MODAL_WINDOWS.ClientOverview}
				className="asteria-widget__modal__client-overview"
				{...options}
			/>
			<InvoiceDetailsModal
				open={type === 'INVOICE_DETAILS'}
				{...options}
			/>
			<InvoiceEditModal
				open={type === ModalStore.MODAL_WINDOWS.InvoiceEdit}
				{...options}
			/>
			<InvoiceSelectedConfirmModal
				open={type === 'INVOICE_SELECTED_CONFIRM'}
				{...options}
			/>
			<AuthProfileChangeModal
				open={type === ModalStore.MODAL_WINDOWS.AuthProfileChange}
				{...options}
			/>
			<ReviewModal open={type === 'BATCH_REVIEW'} {...options} />
			<PrintDetailsModal open={type === 'PRINT_DETAILS'} {...options} />
		</>
	);
};

ModalCollection.displayName = 'ModalCollection';
ModalCollection.propTypes = {
	onAction: PropTypes.func,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default React.memo(ModalCollection);
