import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector, useStore } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { Datepicker, Input, Option, Select } from '@asteria/component-form';
import { Switch } from '@asteria/component-form-v2';
import { useFeature } from '@asteria/component-tools/featureflag';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';

import Field from './field';

import '../styles.scss';

const ClientEditButton = React.memo(function ClientEditButton(props) {
	const { id } = props;

	const dispatch = useDispatch();

	const onEdit = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.ClientEdit,
				data: { _id: id },
			}),
		);
	}, [dispatch, id]);

	return <Button icon="edit" onClick={onEdit} />;
});

ClientEditButton.displayName = 'ClientEditButton';

ClientEditButton.propTypes = { id: PropTypes.string };

const ClientSelector = React.memo(function ClientSelector(props) {
	const store = useStore();
	const { setValue, getValues } = useFormContext();
	const dispatch = useDispatch();

	const clients = useSelector(AppStore.selectors.clients);

	const editButtonFeature = useFeature('show-invoice-edit-client-button');

	const onChange = React.useCallback(
		({ value: id }) => {
			const client = AppStore.selectors.client(store.getState(), id);

			const clientNumber = client?.meta?.clientNumber;

			setValue('service.invoice.meta.clientNumber', clientNumber);
		},
		[setValue, store],
	);

	const onEdit = React.useCallback(() => {
		const id = getValues('service.invoice.clientId');

		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.ClientEdit,
				data: { _id: id },
			}),
		);
	}, [dispatch, getValues]);

	return (
		<>
			<Select {...props} onChange={onChange} disabled>
				{clients.map((object) => (
					<Option
						key={object?._id ?? object?.id}
						value={object?._id ?? object?.id}
						postfix={
							<ClientEditButton id={object?._id ?? object?.id} />
						}
					>
						{object?.name}
					</Option>
				))}
			</Select>
			{editButtonFeature ? (
				<Button
					className="asteria--type-edit"
					size="sm"
					variant="link"
					label={TranslationService.get(
						'invoice.edit.field.clientId.button',
					)}
					onClick={onEdit}
				/>
			) : null}
		</>
	);
});

const Details = React.memo(function Details({ invoice }) {
	return (
		<>
			<Field
				key="meta.invoiceNumber"
				as={Input}
				name="meta.invoiceNumber"
				invoice={invoice}
				disabled
				direction="horizontal"
			/>
			<Field
				key="meta.clientNumber"
				as={Input}
				name="meta.clientNumber"
				invoice={invoice}
				disabled
				direction="horizontal"
			/>
			<Field
				key="clientId"
				as={ClientSelector}
				name="clientId"
				invoice={invoice}
				disabled
				direction="horizontal"
			/>
			<Field
				key="dates.invoiceSent"
				as={Datepicker}
				name="dates.invoiceSent"
				invoice={invoice}
				iconPosition="first"
				disabled
				direction="horizontal"
			/>
			<Field
				key="dates.invoiceDue"
				as={Datepicker}
				name="dates.invoiceDue"
				invoice={invoice}
				iconPosition="first"
				disabled
				direction="horizontal"
			/>
			<Field
				key="dates.invoiceDue"
				v2
				as={Switch}
				name="isCreditInvoice"
				invoice={invoice}
				disabled
			/>
		</>
	);
});

Details.displayName = 'Details';

Details.propTypes = { invoice: PropTypes.object };

export default Details;
