import React from 'react';

import { useDispatch } from 'react-redux';

import { useQuery } from '@tanstack/react-query';

import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';
import LoadingScreen from '@asteria/layout/loading';
import { cn } from '@asteria/utils-funcs/classes';

import InvoiceActionBar from '../../../components/Invoices/ActionBar';
import InvoiceHistoryDropdown from '../../../components/Invoices/History/history-dropdown';
import InvoiceTable from '../../../components/Invoices/Table';
import InvoiceSearch from '../../../components/Invoices/search';
import TourButton from '../../../components/TourButton';
import PrintStatusList from '../../../components/prints/status-list';
import PrintsTable from '../../../components/prints/table';
import LayoutContext from '../../../layout/context';

import LayoutInfo from './LayoutsInfo';
import { useData, useType } from './hooks';

import './styles.scss';

const INVOICE_TABLE_FIELDS = [
	{ name: 'selector' },
	{ name: 'invoice-number', tooltip: true },
	{ name: 'client-number', tooltip: true },
	{ name: 'client-name' },
	// { name: 'message' },
	{ name: 'invoice-date-sent' },
	{ name: 'invoice-date-due' },
	{ name: 'batch' },
	{ name: 'message' },
	{ name: 'currency' },
	{ name: 'tax' },
	{ name: 'total' },
	{ name: 'details' },
];

const VariantDetails = () => {
	const { onSubmit, onAction } = React.useContext(LayoutContext);

	const dispatch = useDispatch();

	const [type, setType] = useType();

	const { data: hasInvoices } = useQuery({
		queryKey: ['invoices', 'exist'],
		queryFn: async () =>
			onSubmit?.('invoices:available', {
				pageFilters: { first: 1 },
				fields: `_id`,
			}),
		select: (response) => !!response?.pageInfo?.count,

		placeholderData: false,

		// refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});

	const [invoiceQuery, printsQuery] = useData({ type, onSubmit, onAction });

	React.useLayoutEffect(() => {
		dispatch(
			AppStore.setCompanySettingsFlags({
				'freja:invoices:timestamp': new Date().toISOString(),
			}),
		);

		onSubmit?.('company:settings:flags:refresh');
	}, [dispatch, onSubmit]);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'invoice:search:switch') {
				setType(data);

				return;
			}

			if (action === 'print:page:switch') {
				dispatch(
					InvoiceStore.filter([
						{
							type: 'SERVICE:STATUS',
							value: null,
						},
						{
							type: 'SERVICE:STATUS',
							value: data,
						},
					]),
				);
			}

			return onAction?.(action, data);
		},
		[dispatch, onAction, setType],
	);

	return [
		<div
			key="title"
			className="flex flex-col md:flex-row mb-4 gap-2 justify-between"
		>
			<TextGroup>
				<Title size="page-title">
					{TranslationService.get([
						`page.invoices.title`,
						`page.invoices.details.title`,
					])}
				</Title>
				<Text size="lg">
					{TranslationService.get([
						`page.invoices.content`,
						`page.invoices.details.content`,
					])}
				</Text>
			</TextGroup>
			<div className="flex items-center justify-center">
				<InvoiceHistoryDropdown
					onAction={handleAction}
					onSubmit={onSubmit}
				/>
				{hasInvoices ? (
					<TourButton
						name="page:invoices:details"
						onAction={handleAction}
					/>
				) : null}
			</div>
		</div>,

		<div key="content" className="asteria-page__wrapper">
			<LayoutInfo type="pending" />
			<div
				className={cn(
					'flex flex-col relative',
					'asteria-page__invoices__content',
				)}
			>
				{invoiceQuery.isFetching || printsQuery.isFetching ? (
					<LoadingScreen type="freja.invoice.table" blur />
				) : null}

				<InvoiceSearch
					onAction={handleAction}
					onSubmit={onSubmit}
					data={
						type === 'invoices'
							? invoiceQuery.data
							: printsQuery.data
					}
					type={type}
					className="p-8"
				/>

				{type === 'invoices' ? (
					<>
						<InvoiceActionBar
							className="px-8 border-x-0"
							onAction={handleAction}
						/>
						<div className="p-8 overflow-x-auto">
							<InvoiceTable
								fields={INVOICE_TABLE_FIELDS}
								data={invoiceQuery.data}
								onAction={handleAction}
								onSubmit={onSubmit}
							/>
						</div>
					</>
				) : null}

				{type === 'prints' ? (
					<>
						<PrintStatusList
							onAction={handleAction}
							onSubmit={onSubmit}
							data={printsQuery.data}
						/>

						<div className="p-8 overflow-x-auto">
							<PrintsTable
								className={cn('max-h-[400px]')}
								onAction={handleAction}
								onSubmit={onSubmit}
								data={printsQuery.data}
								filterable
							/>
						</div>
					</>
				) : null}
			</div>
		</div>,
	];
};

VariantDetails.displayName = 'VariantDetails';

VariantDetails.propTypes = {};

export default VariantDetails;
