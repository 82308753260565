import React from 'react';

import { useSelector } from 'react-redux';

import { get, isEqual, merge, set } from 'lodash-es';
import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import Button from '@asteria/component-core/button';
import Wrapper, { Content, Header } from '@asteria/component-core/wrapper';
import Footer, { FooterSection } from '@asteria/component-core/wrapper/footer';

import Form from '@asteria/component-form';
import Modal from '@asteria/component-modal';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import ServerError from '../../server-error';
import ClientDetailsOverview from '../Details/ClientDetailsOverview';
import { useClient } from '../hooks';

import ClientDetailsEdit from './ClientDetailsEdit';

const ClientEdit = (props) => {
	const { className, _id, onClose, onSubmit, onAction } = props;

	const handleClose = React.useCallback(
		(event) => onClose?.(event, { size: Infinity }),
		[onClose],
	);

	const [loading, setLoading] = React.useState(false);

	const client = useSelector(
		(store) => AppStore.selectors.client(store, _id),
		isEqual,
	);

	const form = useClient(client);

	const handleSave = React.useCallback(
		async (form) => {
			setLoading(true);

			const client = Object.entries(
				AsteriaCore.utils.flatObject(form?.service?.client ?? {}),
			)
				.filter(([, value]) => value)
				.reduce((acc, [key, value]) => set(acc, key, value), {});

			await onSubmit?.('clients:service:update', {
				id: _id,
				data: { ...form, service: { ...form.service, client: client } },
			});

			setLoading(false);

			onClose?.();
		},
		[_id, onClose, onSubmit],
	);

	const handleReset = React.useCallback(async () => {
		setLoading(true);

		await onSubmit?.('clients:service:remove', {
			id: client?.service?.id,
		});

		setLoading(false);
	}, [client?.service?.id, onSubmit]);

	const values = React.useMemo(() => {
		return {
			...client,
			service: merge(
				{},
				form.reduce((acc, { name, fields }) => {
					if (fields?.length) {
						for (const { name } of fields) {
							set(
								acc,
								['client', name].join('.'),
								get(client, name),
							);
						}

						return acc;
					}

					return set(
						acc,
						['client', name].join('.'),
						get(client, name),
					);
				}, {}),
				client?.service ?? {},
			),
		};
	}, [client, form]);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'server:error:action') {
				onClose?.(null, { size: Infinity });
			}

			return onAction?.(action, data);
		},
		[onAction, onClose],
	);

	return (
		<Form values={values} onSubmit={handleSave}>
			<Wrapper
				className={cn(
					'asteria-component__client',
					'asteria-component__invoices__client',
				)}
				scroll
			>
				<Header onClose={handleClose} onBack={onClose}>
					{TranslationService.get(
						[
							'client.overview.title.details',
							`client.overview.title.details.${client?.type}`,
						],
						'client.overview.title.details',
						{ client: client },
					)}
				</Header>
				<Content className="gap-4" scroll>
					<ServerError
						className="mx-6"
						type="client"
						errors={client?.errors}
						contact={(client?.errors ?? []).some(
							({ path }) => !path,
						)}
						extra={{ client }}
						onAction={handleAction}
						onSubmit={onSubmit}
					/>

					<div
						className={cn(
							'asteria-component__client-overview',
							'asteria-component__client-overview__details',
							className,
						)}
					>
						<ClientDetailsOverview
							client={client}
							onAction={handleAction}
							onSubmit={onSubmit}
						/>
						<div className="asteria-component__client-overview__details__wrapper">
							<ClientDetailsEdit
								client={client}
								onAction={handleAction}
								onSubmit={onSubmit}
							/>
						</div>
					</div>
				</Content>
				<Footer>
					<FooterSection position="first">
						<Button
							variant="tertiary"
							label={TranslationService.get([
								'button.cancel',
								'action.cancel',
								'modal.client.edit.action.cancel',
							])}
							onClick={onClose}
						/>
					</FooterSection>
					<FooterSection position="last">
						{client?.services?.[0]?.id ? (
							<Button
								variant="secondary"
								label={TranslationService.get([
									'button.reset',
									'action.reset',
									'modal.client.edit.action.reset',
								])}
								onClick={handleReset}
								loading={loading}
								disabled={loading}
							/>
						) : null}
						<Button
							variant="primary"
							label={TranslationService.get([
								'button.save',
								'action.save',
								'modal.client.edit.action.save',
							])}
							type="submit"
							loading={loading}
							disabled={loading}
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</Form>
	);
};

ClientEdit.propTypes = {
	className: PropTypes.string,
	_id: PropTypes.string,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

const ClientEditModal = (props) => {
	const { className, open = true, onClose, _id, onAction, onSubmit } = props;

	const handleClose = React.useCallback(
		(event) => onClose?.(event, { size: Infinity }),
		[onClose],
	);

	return (
		<Modal open={open} onClose={handleClose} size="md">
			<ClientEdit
				_id={_id}
				className={className}
				onAction={onAction}
				onSubmit={onSubmit}
				onClose={onClose}
			/>
		</Modal>
	);
};

ClientEditModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	className: PropTypes.string,
	_id: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

ClientEditModal.defaultProps = {};

export default ClientEditModal;
