import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { Datepicker } from '@asteria/component-form';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { cn } from '@asteria/utils-funcs/classes';

/** @type { React.FC<import('./types').BaseProps> } */
const SearchDate = React.memo(function SearchDate(props) {
	const { className } = props;

	const dispatch = useDispatch();

	const onChange = React.useCallback(
		({ value }) => {
			dispatch(
				InvoiceStore.filter([
					{ type: 'DATE:SENT', value: null },
					{ type: 'DATE:SENT', value: value },
				]),
			);
		},
		[dispatch],
	);

	return (
		<div
			className={cn(
				'asteria-component__invoice-search-part',
				'asteria--type-date',
				className,
			)}
		>
			<Datepicker
				name="date"
				variant="range"
				onChange={onChange}
				skipVisibleValue
				iconOnly
			/>
		</div>
	);
});

SearchDate.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default SearchDate;
