import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { useCompanyVersion } from '../CompanyVersion';

export function useImportantActions({ ignore } = {}) {
	const version = useCompanyVersion();
	const errorId = useSelector((store) => {
		const object = InvoiceStore.selectors.batches(store, {
			status: 'ERROR',
		})?.[0];

		return object?._id ?? object?.id;
	});

	const batchID = useSelector((store) => {
		const objects = InvoiceStore.selectors.batches(store, {
			status: 'SENT',
		})?.[0];

		return objects?._id ?? objects?.id;
	});

	const ignoreErrorBatch = ignore?.includes?.('errorBatch');

	return React.useMemo(() => {
		const actions = [];

		if (errorId && !ignoreErrorBatch) {
			actions.push({
				id: 'errorBatch',
				variant: 'link',
				path: `/invoices`,
			});
		}

		if (batchID && version === 1) {
			actions.push({
				id: 'batch',
				variant: 'link',
				path: `/invoices/batch/${batchID}`,
			});
		}

		return actions;
	}, [batchID, errorId, version, ignoreErrorBatch]);
}

export function useIntegration({ type }) {
	const integrations = useSelector(
		(store) =>
			IntegrationStore.selectors.integrations(store, { type: type }),
		(a, b) => isEqual(a, b),
	);

	return React.useMemo(() => {
		if (!integrations.length) {
			return { status: 'create' };
		}

		let integration = integrations.find(
			(object) =>
				object?.status?.state === 'ERROR' ||
				object?.config?.errors?.length,
		);

		if (integration) {
			return { status: 'error', integration: integration };
		}

		integration = integrations.find((object) =>
			['INITIATING'].includes(object?.status?.state),
		);

		if (integration) {
			return { status: 'importing', integration: integration };
		}

		return { status: 'connected', integration: integrations[0] };
	}, [integrations]);
}

export function usePagination() {
	const [page, setPage] = React.useState(0);

	const onNext = React.useCallback(() => setPage((prev) => prev + 1), []);
	const onPrev = React.useCallback(() => setPage((prev) => prev - 1), []);

	const onPageClick = React.useCallback((page) => setPage(page), []);

	return { page, onNext, onPrev, onPageClick };
}
