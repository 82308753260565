export const INVOICE_FIELDS = [
	{ name: 'invoice-number', tooltip: true },
	{ name: 'client-number', tooltip: true },
	{ name: 'client-name' },
	{ name: 'invoice-date-sent' },
	{ name: 'invoice-date-due' },
	{ name: 'currency' },
	{ name: 'message' },
	{ name: 'total' },
	{ name: 'tax' },
	{ name: 'remove-details' },
];
