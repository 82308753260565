import React from 'react';

import PropTypes from 'prop-types';

import { Input } from '@asteria/component-form';

import CountrySelect from '../inputs/country';

import Field from './field';

import '../styles.scss';

const Contact = ({ invoice }) => {
	return (
		<>
			<Field
				as={Input}
				invoice={invoice}
				name="contact.general.name"
				label="invoice.edit.field.contact.name.label"
				disabled
				direction="horizontal"
			/>
			<Field
				as={Input}
				invoice={invoice}
				name="contact.general.street"
				label="invoice.edit.field.contact.street.label"
				disabled
				direction="horizontal"
			/>
			<Field
				as={Input}
				invoice={invoice}
				name="contact.general.street2"
				label="invoice.edit.field.contact.street2.label"
				disabled
				direction="horizontal"
			/>
			<Field
				as={Input}
				invoice={invoice}
				name="contact.general.zipcode"
				label="invoice.edit.field.contact.zipcode.label"
				disabled
				direction="horizontal"
				className="asteria--type-zip"
			/>
			<Field
				as={Input}
				invoice={invoice}
				name="contact.general.city"
				label="invoice.edit.field.contact.city.label"
				disabled
				direction="horizontal"
				className="asteria--type-city"
			/>
			<Field
				as={CountrySelect}
				invoice={invoice}
				name="contact.general.country"
				label="invoice.edit.field.contact.country.label"
				disabled
				direction="horizontal"
				className="asteria--type-country"
			/>
		</>
	);
};

Contact.displayName = 'Contact';

Contact.propTypes = { invoice: PropTypes.object };

export default Contact;
