import React from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';
import { TableCell, TableRow } from '@asteria/component-core/table';
import { Text } from '@asteria/component-core/typography';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

/**
 * @typedef Props
 * @property { unknown } invoice
 * @property { unknown } print
 * @property { { postfix?: unknown, data?: unknown } } translationOptions
 * @property { (action: string, data: unknown) => Promise<unknown> } onSubmit
 * @property { (action: string, data: unknown) => Promise<unknown> } onAction
 */

/** @type { React.FC<Props> } */
const Row = React.memo(function Row(props) {
	const { invoice, print, translationOptions, onAction, onSubmit } = props;

	const queryClient = useQueryClient();

	const printId = print?._id ?? print?.id;

	const invoiceId = invoice?._id ?? invoice?.id;
	const errors = invoice?.errors ?? [];
	const ignored = !!invoice?.discard?.length;

	const postfix = { status: invoice?.status };
	const data = { ...translationOptions?.data, invoice };

	const onInvoiceOpen = React.useCallback(
		() => onAction?.('invoice:open', invoiceId),
		[invoiceId, onAction],
	);

	const printValidateMutation = useMutation({
		mutationFn: async () => onSubmit?.('print:validate', { id: printId }),
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				predicate: ({ queryKey }) =>
					queryKey.includes(printId) || queryKey.includes('prints'),
			});
		},
	});

	const ignoreMutation = useMutation({
		mutationFn: async () => onSubmit?.('invoice:ignore', { id: invoiceId }),
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				predicate: ({ queryKey }) => queryKey.includes(invoiceId),
			});

			return printValidateMutation.mutateAsync();
		},
	});

	const revertMutation = useMutation({
		mutationFn: async () => onSubmit?.('invoice:revert', { id: invoiceId }),
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				predicate: ({ queryKey }) => queryKey.includes(invoiceId),
			});

			return printValidateMutation.mutateAsync();
		},
	});

	const isIgnoring =
		ignoreMutation.isLoading ||
		revertMutation.isLoading ||
		printValidateMutation.isLoading;

	return (
		<TableRow
			className={cn({
				'asteria--variant-error': !!errors.length,
			})}
		>
			<TableCell className="asteria--variant-id">
				<Button
					variant="link"
					label={TranslationService.getV2(
						['print.details.table.cell'],
						{
							postfix: {
								type: 'id',
								...postfix,
							},
							data: data,
						},
					)}
					onClick={onInvoiceOpen}
				/>
			</TableCell>
			<TableCell className="asteria--variant-created">
				<Translation
					translationKey="print.details.table.cell"
					translationOptions={{
						postfix: {
							type: 'createdAt',
							...postfix,
						},
						data: data,
					}}
					Component={Text}
				/>
			</TableCell>
			<TableCell className="asteria--variant-number">
				<Translation
					translationKey="print.details.table.cell"
					translationOptions={{
						postfix: {
							type: 'number',
							...postfix,
						},
						data: data,
					}}
					Component={Text}
				/>
			</TableCell>
			<TableCell className="asteria--variant-message">
				<Translation
					translationKey="print.details.table.cell"
					translationOptions={{
						postfix: {
							type: 'message',
							...postfix,
						},
						data: data,
					}}
					Component={Text}
				/>
			</TableCell>
			<TableCell className="asteria--variant-actions">
				{/* <Button icon="chevron-right" onClick={onInvoiceOpen} /> */}
				<Dropdown toggle={{ icon: 'more', size: 'sm' }}>
					<DropdownItem onClick={onInvoiceOpen}>View</DropdownItem>
					{!ignored ? (
						<DropdownItem
							onClick={ignoreMutation.mutate}
							disabled={isIgnoring}
						>
							Ignore
						</DropdownItem>
					) : (
						<DropdownItem
							onClick={revertMutation.mutate}
							disabled={isIgnoring}
						>
							Revert
						</DropdownItem>
					)}
				</Dropdown>
			</TableCell>
		</TableRow>
	);
});

Row.propTypes = {
	invoice: PropTypes.object,
	print: PropTypes.object,
	translationOptions: PropTypes.object,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default Row;
