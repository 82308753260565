import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Chip from '@asteria/component-chip';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';

/** @type { React.FC<{ id: string }> } */
const SearchClientChip = React.memo(function SearchClientChip(props) {
	const { id } = props;

	const dispatch = useDispatch();

	const client = useSelector(
		(store) => AppStore.selectors.client(store, id),
		isEqual,
	);

	const onDismiss = React.useCallback(() => {
		dispatch(InvoiceStore.filter({ type: 'CLIENT', value: id }));
	}, [dispatch, id]);

	return (
		<Chip
			size="sm"
			label={TranslationService.get(
				['invoices.search.quick.client.label'],
				undefined,
				client,
			)}
			dismiss
			onDismiss={onDismiss}
			active
		/>
	);
});

SearchClientChip.propTypes = { id: PropTypes.string };

const SearchFiltersClients = React.memo(function SearchFiltersClients() {
	const filters = useSelector(
		(store) => InvoiceStore.selectors.filters(store, { type: 'CLIENT' }),
		isEqual,
	);

	return filters.map((object, index) => (
		<SearchClientChip key={index} id={object?.value} />
	));
});

export default SearchFiltersClients;
