import React from 'react';

import { parseISO } from 'date-fns';
import { get } from 'lodash-es';
import PropTypes from 'prop-types';

import Icon from '@asteria/component-core/icon';
import Table, { TableHeader } from '@asteria/component-core/table';
import { Title } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { usePrintsFilters } from '../hooks';

import { COLUMN, COLUMNS, DIRECTION } from './constants';
import PrintsTableContent from './content';
import TableHeaderCell from './header-cell';
import { sort } from './utils';

import './styles.scss';

/**
 * @typedef Props
 * @property { string } className
 * @property { unknown[] } data
 * @property { { key: string, sortable?: boolean }[] } columns
 * @property { boolean } filterable
 * @property { 'sm' | 'md' | 'lg' } size
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 */

/** @type { React.FC<Props> } */
const PrintsTable = React.memo(function PrintsTable(props) {
	const {
		className,
		onAction,
		onSubmit,
		columns = COLUMNS,
		filterable,

		size,
	} = props;

	const data = usePrintsFilters(props.data, filterable);

	const [sorting, setSorting] = React.useState({
		key: 'createdAt',
		direction: DIRECTION.DESC,
	});

	const sortBy = React.useCallback((key) => {
		setSorting((current) => {
			if (!current || current?.key !== key) {
				return { key, direction: DIRECTION.DESC };
			}

			if (current.direction === DIRECTION.ASC) {
				return null;
			}

			return { key, direction: DIRECTION.ASC };
		});
	}, []);

	const prints = React.useMemo(() => {
		let response = [...(data ?? [])];

		if (sorting?.key) {
			response.sort((a, b) => {
				let source = get(a, sorting?.key);
				let target = get(b, sorting?.key);

				if (sorting?.key === 'createdAt') {
					source = parseISO(source).getTime();
					target = parseISO(target).getTime();
				}

				return sort(source, target, sorting?.direction);
			});
		}

		return response;
	}, [data, sorting?.direction, sorting?.key]);

	const ref = React.useRef(null);

	const style = React.useMemo(
		() => ({
			'grid-template-columns': columns
				.map(({ key }) => COLUMN[key] ?? 'var(--width-column-base)')
				.join(' '),
		}),
		[columns],
	);

	return (
		<Table
			className={cn('asteria-component__prints-table', className)}
			ref={ref}
			style={style}
		>
			<TableHeader>
				{columns.map(({ key, sortable }) => (
					<TableHeaderCell
						key={key}
						type={key}
						sorting={sorting}
						sortBy={sortBy}
						data={prints}
						sortable={sortable}
						onAction={onAction}
						onSubmit={onSubmit}
						size={size}
					/>
				))}
			</TableHeader>
			{prints.length ? (
				<PrintsTableContent
					data={prints}
					viewportRef={ref}
					onAction={onAction}
					onSubmit={onSubmit}
					columns={columns}
					size={size}
				/>
			) : (
				<div className="col-span-full p-8 bg-alert-success-normal-background flex flex-col justify-center items-center">
					<div className="flex gap-2 items-center">
						<div
							className={cn(
								'asteria-component__alert-icon',
								'asteria--status-success',
							)}
						>
							<Icon icon="printer" size={size} />
						</div>
						<Translation
							translationKey="prints.table.empty.title"
							Component={Title}
							className="text-content-page-title"
							size={size === 'sm' ? 'xs' : 'md'}
						/>
					</div>
				</div>
			)}
		</Table>
	);
});

PrintsTable.displayName = 'PrintsTable';

PrintsTable.propTypes = {
	className: PropTypes.string,

	data: PropTypes.arrayOf(PropTypes.object),
	columns: PropTypes.arrayOf(PropTypes.object),
	filterable: PropTypes.bool,
	size: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default PrintsTable;
