import React from 'react';

import { useSelector } from 'react-redux';

import { max } from 'date-fns';
import PropTypes from 'prop-types';

import Badge from '@asteria/component-core/badge';
import Button from '@asteria/component-core/button';
import { Title } from '@asteria/component-core/typography';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { useToggleState } from '@asteria/utils-funcs/state';

import { usePrintsQuery, usePrintsStatus } from '../hooks';
import PrintPages from '../pages';
import PrintStatus from '../status';
import PrintsTable from '../table';
import {
	findTimestampValue,
	getDialogCloseTimestamp,
	getPrintsTimestamp,
} from '../utils';

import { TABS } from './constants';
import RemovePrintDialog from './modal-remove';
import Switcher from './switcher';

/**
 * @typedef Props
 * @property { string } className
 * @property { unknown[] } data
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 * @property { boolean } open
 */

const SUCCESS_TABLE_COLUMNS = [
	{ key: 'id', sortable: true },
	{ key: 'createdAt', sortable: true },
	{ key: 'invoices', sortable: false },
	{ key: 'actions', sortable: false },
];

const ERROR_TABLE_COLUMNS = [
	{ key: 'id', sortable: true },
	{ key: 'createdAt', sortable: true },
	{ key: 'message', sortable: false },
	{ key: 'actions', sortable: false },
];

const STYLE = {
	'--width-column-id': 'minmax(100px, max-content)',
	'--width-column-message': 'minmax(100px, max-content)',
};

/**
 * @template T
 * @param { T[] } data
 * @returns { T[] }
 */
function getProcessedPrints(data) {
	return (data ?? []).filter((print) => print?.status === 'PROCESSED');
}

/**
 * @template T
 * @param { T[] } data
 * @returns { { error: T[], warning: T[] } }
 */
function getErrorPrints(data) {
	return (data ?? [])
		.filter((print) => print?.status === 'ERROR')
		.reduce(
			(acc, print) => {
				const invoices = print?.invoices ?? [];

				const isError = invoices.every(
					(invoice) => invoice?.errors?.length,
				);

				if (isError) {
					acc.error.push(print);
				} else {
					acc.warning.push(print);
				}

				return acc;
			},
			{ error: [], warning: [] },
		);
}

/** @type { React.FC<Props> } */
const PrintDialog = React.memo(function PrintDialog(props) {
	const { className, onAction, onSubmit, open = false } = props;

	const [type, setType] = React.useState(TABS.PENDING);

	const [collapseState, collapseActions] = useToggleState(open);
	const [removeModalState, removeModalActions] = useToggleState(false);

	const timestamp = useSelector((store) =>
		findTimestampValue(
			getDialogCloseTimestamp(store),
			getPrintsTimestamp(store),
			max,
		),
	);

	const query = usePrintsQuery({ onAction, onSubmit, timestamp });
	const data = query?.data;

	const status = usePrintsStatus(data);

	const onSettingsClick = React.useCallback(
		() => onAction?.('print:dialog:action', { type: 'cog' }),
		[onAction],
	);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'print:dialog:switch') {
				collapseActions.open();

				return setType(data);
			}

			return onAction?.(action, data);
		},
		[collapseActions, onAction],
	);

	const handleSubmit = React.useCallback(
		(action, data) => {
			if (action === 'print:status:done') {
				collapseActions.close();
			}

			return onSubmit?.(action, data);
		},
		[collapseActions, onSubmit],
	);

	const prints = React.useMemo(() => {
		const completed = getProcessedPrints(data);
		const { warning, error } = getErrorPrints(data);

		return { completed, warning, error };
	}, [data]);

	if (!data?.length) {
		return null;
	}

	return (
		<>
			<RemovePrintDialog
				{...props}
				onAction={handleAction}
				onSubmit={handleSubmit}
				open={removeModalState}
				onClose={removeModalActions?.close}
			/>
			<div
				className={cn(
					'rounded shadow divide-y bg-white',
					'asteria-component__print-dialog',
					className,
				)}
				style={STYLE}
			>
				<div
					className={cn(
						'flex items-center justify-between gap-2 p-4 border-border-normal',
						'asteria-component__print-dialog-header',
						{ [`asteria--type-${type}`]: type },
					)}
				>
					<div className="flex gap-2 items-center">
						<Translation
							translationKey="print.dialog.title"
							Component={Title}
							size="xxs"
							className="text-content-page-title"
						/>
						{prints.error.length ? (
							<Badge
								icon="warning"
								size="sm"
								tooltip={TranslationService.getV2([
									'print.dialog.title.badge.tooltip',
								])}
							/>
						) : null}
					</div>
					<div className="asteria-component__print-dialog-header-actions">
						<Button
							size="sm"
							icon="cog"
							onClick={onSettingsClick}
						/>
						<Button
							size="sm"
							icon={
								!collapseState ? 'chevron-up' : 'chevron-down'
							}
							onClick={collapseActions.toggle}
						/>
						<Button
							size="sm"
							icon="close"
							onClick={removeModalActions.open}
						/>
					</div>
				</div>
				{collapseState ? (
					<div
						className={cn(
							'border-border-normal divide-y',
							'asteria-component__print-dialog-content',
						)}
					>
						<Switcher
							type={type}
							onAction={handleAction}
							onSubmit={handleSubmit}
							flags={{
								completed: !!prints.completed.length,
								error: !!prints.error.length,
								warning: !!prints.warning.length,
							}}
						/>

						{type === TABS.PENDING ? (
							<PrintPages
								prints={data}
								className="border-border-normal"
								onAction={handleAction}
								onSubmit={handleSubmit}
							/>
						) : null}

						{type === TABS.SUCCESS ? (
							<PrintsTable
								className={cn('max-h-[250px]')}
								onAction={handleAction}
								onSubmit={handleSubmit}
								data={prints.completed}
								columns={SUCCESS_TABLE_COLUMNS}
								size="sm"
								filterable={false}
							/>
						) : null}

						{type === TABS.ERROR ? (
							<PrintsTable
								className={cn('max-h-[250px]')}
								onAction={handleAction}
								onSubmit={handleSubmit}
								data={prints.error}
								columns={ERROR_TABLE_COLUMNS}
								size="sm"
								filterable={false}
							/>
						) : null}

						{type === TABS.WARNING ? (
							<PrintsTable
								className={cn('max-h-[250px]')}
								onAction={handleAction}
								onSubmit={handleSubmit}
								data={prints.warning}
								columns={ERROR_TABLE_COLUMNS}
								size="sm"
								filterable={false}
							/>
						) : null}
					</div>
				) : null}
				<PrintStatus
					className="border-border-normal"
					status={status}
					onAction={handleAction}
					onSubmit={handleSubmit}
					from="dialog"
					done
				/>
			</div>
		</>
	);
});

PrintDialog.displayName = 'PrintDialog';

PrintDialog.propTypes = {
	className: PropTypes.string,

	open: PropTypes.bool,
	data: PropTypes.arrayOf(PropTypes.object),

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default PrintDialog;
