import React from 'react';

import PropTypes from 'prop-types';

import Icon from '@asteria/component-core/icon';
import Skeleton from '@asteria/component-core/skeleton';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

/**
 * @typedef Props
 * @property { string } className
 * @property { 'PENDING' | 'LOADING' | 'ERROR' | 'IGNORED' | 'PROCESSED' } status
 * @property { import('react').MouseEventHandler } onClick
 */

const STYLE = { height: 100, width: 71 };

/** @type { React.FC<Props> } */
const PrintPage = React.memo(function PrintPage(props) {
	const { className, status = 'PENDING', onClick } = props;

	const isWarning = ['WARNING'].includes(status);
	const isError = ['ERROR'].includes(status);
	const isSuccess = ['IGNORED', 'PROCESSED'].includes(status);

	return (
		<div
			className={cn(
				'shadow rounded flex flex-col items-center justify-center p-4 shrink-0 bg-white relative',
				{
					'border-2 border-solid border-alert-error-normal-border bg-alert-error-normal-background':
						isError,
					'border-2 border-solid border-alert-warning-normal-border bg-alert-warning-normal-background':
						isWarning,
					'border-2 border-solid border-alert-success-normal-border bg-alert-success-normal-background':
						isSuccess,
				},
				'asteria-component__print-page',
				{ [`asteria--status-${status}`]: status },
				className,
			)}
			style={STYLE}
			onClick={onClick}
		>
			{isError ? (
				<Icon icon="alert" />
			) : isWarning ? (
				<Icon icon="alert" />
			) : isSuccess ? (
				<Icon icon="check" />
			) : (
				<Skeleton count={3} size="sm" />
			)}
		</div>
	);
});

PrintPage.displayName = 'PrintPage';

PrintPage.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	onRemove: PropTypes.func,
	status: PropTypes.string,
	removable: PropTypes.bool,
};

export default PrintPage;
