import React from 'react';

import { Route, Routes, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';

import { OnboardingGatewayModal } from '@asteria/component-integrations-v2/gateway';

import { useBackgroundLocation } from '@asteria/utils-hooks/navigation';
import { AuthValidate, Redirect } from '@asteria/widget-base';

import LayoutContext from './layout/context';
import FAQPage from './pages/FAQ';
import FAQDetailsPage from './pages/FAQ/Details';
import FAQSectionPage from './pages/FAQ/Section';
import { VPrintPage } from './pages/Guide/Details';
import IntegrationsPage from './pages/Integrations';
import IntroductionPage from './pages/Introduction';
import Invoices from './pages/Invoices';
import Layouts from './pages/Layouts';
import NotFound from './pages/NotFound';
import Reports from './pages/Reports';
import Settings from './pages/Settings';
import SupportPage from './pages/Support';
import AuthPage from './pages/auth';

const routes = [
	{ index: true, page: <IntroductionPage /> },
	{ path: 'onboarding', page: <IntegrationsPage /> },
	{ path: 'faq', page: <FAQPage /> },
	{ path: 'faq/:name', page: <FAQSectionPage /> },
	{ path: 'faq/:name/:id', page: <FAQDetailsPage /> },
	{ path: 'guide/v-print', page: <VPrintPage /> },
	{ path: 'support', page: <SupportPage /> },
	{ path: 'settings', page: <Settings /> },
	{ path: 'layouts', page: <Layouts /> },
	{ path: 'invoices/*', page: <Invoices /> },
	{ path: 'reports/*', page: <Reports /> },
].map((options, index) => (
	<Route
		key={options?.path ?? index}
		index={options?.index}
		path={options?.path}
		element={<AuthValidate>{options?.page}</AuthValidate>}
	/>
));

const ModalGateway = React.memo(function ModalGateway() {
	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const location = useLocation();

	return (
		<>
			<OnboardingGatewayModal
				open={location?.pathname?.includes?.('/onboarding')}
				onAction={onAction}
				onSubmit={onSubmit}
			/>
		</>
	);
});

const Routing = React.memo(function Routing({ auth, partnerId }) {
	const location = useLocation();
	const backgroundLocation = useBackgroundLocation();

	return (
		<>
			<Routes location={backgroundLocation}>
				{routes}
				<Route
					path="welcome/*"
					element={<Redirect to="/auth/welcome" />}
				/>
				<Route
					path="recover/*"
					element={<Redirect to="/auth/recover" />}
				/>
				<Route
					path="auth/*"
					element={
						<AuthPage {...(auth ?? {})} partnerId={partnerId} />
					}
				/>
				<Route
					path="*"
					element={
						<AuthValidate>
							<NotFound />
						</AuthValidate>
					}
				/>
			</Routes>
			{location?.state?.backgroundLocation ? <ModalGateway /> : null}
		</>
	);
});

Routing.propTypes = {
	auth: PropTypes.object,
	partnerId: PropTypes.string,
};

export default Routing;
