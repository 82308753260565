import React from 'react';

import PropTypes from 'prop-types';

import InputReset from '../../../../InputReset';
import CountrySelect from '../inputs/country';

import Field from './field';

import '../styles.scss';

const Delivery = ({ invoice }) => {
	return (
		<>
			<Field
				as={InputReset}
				invoice={invoice}
				name="contact.shipping.name"
				label="invoice.edit.field.contact.name.label"
				direction="horizontal"
			/>
			<Field
				as={InputReset}
				invoice={invoice}
				name="contact.shipping.street"
				label="invoice.edit.field.contact.street.label"
				direction="horizontal"
			/>
			<Field
				as={InputReset}
				invoice={invoice}
				name="contact.shipping.street2"
				label="invoice.edit.field.contact.street2.label"
				direction="horizontal"
			/>
			<Field
				as={InputReset}
				invoice={invoice}
				name="contact.shipping.zipcode"
				label="invoice.edit.field.contact.zipcode.label"
				direction="horizontal"
				className="asteria--type-zip"
			/>
			<Field
				as={InputReset}
				invoice={invoice}
				name="contact.shipping.city"
				label="invoice.edit.field.contact.city.label"
				direction="horizontal"
				className="asteria--type-city"
			/>
			<Field
				as={CountrySelect}
				invoice={invoice}
				name="contact.shipping.country"
				label="invoice.edit.field.contact.country.label"
				direction="horizontal"
				className="asteria--type-country"
			/>
		</>
	);
};

Delivery.displayName = 'Delivery';

Delivery.propTypes = {
	invoice: PropTypes.object,
};

export default Delivery;
