import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const SEPARATOR_SIZE = 4;

const Pagination = React.memo(function Pagination(props) {
	const { length, size, page, onNext, onPrev, onPageClick } = props;

	const paginationItems = Math.ceil(length / size);

	if (paginationItems >= SEPARATOR_SIZE + 1) {
		if (page === 0) {
			return (
				<div
					className={cn(
						'flex flex-row gap-2',
						'asteria-component__actions-pagination',
					)}
				>
					<Button
						icon="chevron-left"
						variant="tertiary"
						onClick={onPrev}
						disabled={page <= 0}
					/>
					{new Array(SEPARATOR_SIZE).fill().map((_, index) => (
						<Button
							key={`pagination-${index + page}`}
							variant="tertiary"
							active={index + page === page}
							label={TranslationService.getV2(
								'actions.pagination.button',
								{
									data: {
										index: index + page + 1,
										length: paginationItems,
									},
								},
							)}
							onClick={() => onPageClick(index + page)}
						/>
					))}
					<Translation
						translationKey={'actions.pagination.separator'}
						translationOptions={{
							data: { length: paginationItems },
						}}
						Component={Text}
					/>
					<Button
						key={`pagination-${paginationItems}`}
						variant="tertiary"
						active={paginationItems === page}
						label={TranslationService.getV2(
							'actions.pagination.button',
							{
								data: {
									index: paginationItems,
									length: paginationItems,
								},
							},
						)}
						onClick={() => onPageClick(paginationItems - 1)}
					/>
					<Button
						icon="chevron-right"
						variant="tertiary"
						onClick={onNext}
						disabled={page >= paginationItems - 1}
					/>
				</div>
			);
		}

		if (page + SEPARATOR_SIZE >= paginationItems) {
			return (
				<div
					className={cn(
						'flex flex-row gap-2',
						'asteria-component__actions-pagination',
					)}
				>
					<Button
						icon="chevron-left"
						variant="tertiary"
						onClick={onPrev}
						disabled={page <= 0}
					/>

					<Button
						key={`pagination-0`}
						variant="tertiary"
						active={false}
						label={TranslationService.getV2(
							'actions.pagination.button',
							{
								data: {
									index: 1,
									length: paginationItems,
								},
							},
						)}
						onClick={() => onPageClick(0)}
					/>
					<Translation
						translationKey={'actions.pagination.separator'}
						translationOptions={{
							data: { length: paginationItems },
						}}
						Component={Text}
					/>
					{new Array(SEPARATOR_SIZE).fill().map((_, index) => (
						<Button
							key={`pagination-${
								index + paginationItems - SEPARATOR_SIZE
							}`}
							variant="tertiary"
							active={
								index + paginationItems - SEPARATOR_SIZE ===
								page
							}
							label={TranslationService.getV2(
								'actions.pagination.button',
								{
									data: {
										index:
											index +
											paginationItems -
											SEPARATOR_SIZE +
											1,
										length: paginationItems,
									},
								},
							)}
							onClick={() =>
								onPageClick(
									index + paginationItems - SEPARATOR_SIZE,
								)
							}
						/>
					))}
					<Button
						icon="chevron-right"
						variant="tertiary"
						onClick={onNext}
						disabled={page >= paginationItems - 1}
					/>
				</div>
			);
		}

		return (
			<div
				className={cn(
					'asteria-component__actions-pagination',
					'flex flex-row gap-2',
				)}
			>
				<Button
					icon="chevron-left"
					variant="tertiary"
					onClick={onPrev}
					disabled={page <= 0}
				/>
				{new Array(SEPARATOR_SIZE).fill().map((_, index) => (
					<Button
						key={`pagination-${index + page - 1}`}
						variant="tertiary"
						active={index + page - 1 === page}
						label={TranslationService.getV2(
							'actions.pagination.button',
							{
								data: {
									index: index + page,
									length: paginationItems,
								},
							},
						)}
						onClick={() => onPageClick(index + page - 1)}
					/>
				))}
				<Translation
					translationKey={'actions.pagination.separator'}
					translationOptions={{ data: { length: paginationItems } }}
					Component={Text}
				/>
				<Button
					key={`pagination-${paginationItems}`}
					variant="tertiary"
					active={paginationItems === page}
					label={TranslationService.getV2(
						'actions.pagination.button',
						{
							data: {
								index: paginationItems,
								length: paginationItems,
							},
						},
					)}
					onClick={() => onPageClick(paginationItems - 1)}
				/>
				<Button
					icon="chevron-right"
					variant="tertiary"
					onClick={onNext}
					disabled={page >= paginationItems - 1}
				/>
			</div>
		);
	}

	return (
		<div
			className={cn(
				'asteria-component__actions-pagination',
				'flex flex-row gap-2',
			)}
		>
			<Button
				icon="chevron-left"
				variant="tertiary"
				onClick={onPrev}
				disabled={page <= 0}
			/>
			{new Array(paginationItems).fill().map((_, index) => (
				<Button
					key={`pagination-${index}`}
					variant="tertiary"
					active={index === page}
					label={TranslationService.getV2(
						'actions.pagination.button',
						{
							data: {
								index: index + 1,
								length: paginationItems,
							},
						},
					)}
					onClick={() => onPageClick(index)}
				/>
			))}
			<Button
				icon="chevron-right"
				variant="tertiary"
				onClick={onNext}
				disabled={page >= paginationItems - 1}
			/>
		</div>
	);
});

Pagination.propTypes = {
	length: PropTypes.number,
	size: PropTypes.number,
	page: PropTypes.number,
	onNext: PropTypes.func,
	onPrev: PropTypes.func,
	onPageClick: PropTypes.func,
};

export default Pagination;
