import { min } from 'date-fns';

import * as AppStore from '@asteria/datalayer/stores/app';

import { parseDate } from '@asteria/utils-funcs/normalize';

/**
 * @param { string | Date } date1
 * @param { string | Date } date2
 * @param { (...args: Date[]) => Date } fn
 */
export function findTimestampValue(date1, date2, fn) {
	const source = parseDate(date1);
	const target = parseDate(date2);

	if (source && target) {
		return fn([source, target]).toISOString();
	}

	if (!source && !target) {
		return null;
	}

	return (source ?? target).toISOString();
}

/**
 * @param { string } key
 */
function getTimestamp(key) {
	/**
	 * @param { unknown } store
	 */
	return function (store) {
		return findTimestampValue(
			AppStore.selectors.user(store)?.settings?.flags?.[key],
			AppStore.selectors.company(store)?.settings?.flags?.[key],
			min,
		);
	};
}

export const getDialogCloseTimestamp = getTimestamp(
	'freja:prints:dialog:timestamp',
);

export const getPrintsTimestamp = getTimestamp('freja:prints:timestamp');
