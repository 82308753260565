import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { TableCell } from '@asteria/component-core/table';
import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

/**
 * @typedef Props
 * @property { unknown } value
 * @property { string } type
 * @property { 'sm' | 'md' | 'lg' } size
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 */

/** @type { React.FC<Props> } */
const Cell = React.memo(function Cell(props) {
	const { type, value, onAction, size } = props;

	const id = value?._id ?? value?.id;
	const status = value?.status;
	const invoices = value?.invoices ?? [];

	const numbers = invoices.map((invoice) => invoice?.meta?.invoiceNumber);
	const errors = invoices
		.filter((invoice) => !invoice?.discard?.length)
		.flatMap((invoice) => invoice?.errors ?? []);

	const errorCode = errors[0]?.code;

	const data = { data: value, numbers: numbers, errors: errors };
	const postfix = { type: type, status: status, code: errorCode };
	const translationOptions = { postfix: postfix, data: data };

	const onOpen = React.useCallback(
		() => onAction?.('print:open', { id }),
		[id, onAction],
	);

	if (type === 'actions') {
		return (
			<TableCell className={cn({ [`asteria--type-${type}`]: type })}>
				<Button icon="chevron-right" onClick={onOpen} size={size} />
			</TableCell>
		);
	}

	if (type === 'id') {
		return (
			<TableCell className={cn({ [`asteria--type-${type}`]: type })}>
				<Button
					variant="link"
					href={TranslationService.get(
						'page.invoices.pending.table.open',
						undefined,
						{ uri: value?.pdfUri },
					)}
					target="_blank"
					icon="document"
					label={TranslationService.getV2(
						['prints.table.cell'],
						translationOptions,
					)}
					tooltip={TranslationService.getV2(
						['prints.table.cell', 'prints.table.cell.tooltip'],
						translationOptions,
					)}
					size={size}
				/>
			</TableCell>
		);
	}

	return (
		<TableCell className={cn({ [`asteria--type-${type}`]: type })}>
			<TooltipWrapper
				tooltip={TranslationService.getV2(
					['prints.table.cell', 'prints.table.cell.tooltip'],
					translationOptions,
				)}
			>
				<Translation
					translationKey="prints.table.cell"
					translationOptions={translationOptions}
					Component={Text}
					className="truncate"
					size={size}
				/>
			</TooltipWrapper>
		</TableCell>
	);
});

Cell.propTypes = {
	type: PropTypes.string,
	value: PropTypes.object,
	size: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default Cell;
