import React from 'react';

import PropTypes from 'prop-types';

import { Text, Title } from '@asteria/component-core/typography';

import List, { ListCell, ListItem } from '@asteria/component-list';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import InvoiceDetailsCellInfo from './info';

import '../styles.scss';

const InvoiceDetailsModalSection = React.memo(
	function InvoiceDetailsModalSection(props) {
		const { invoice, fields, type, onAction } = props;

		return (
			<div
				className={cn('asteria-component__invoice-modal__section', {
					[`asteria--type-${type}`]: type,
				})}
			>
				<Title size="xxs">
					{TranslationService.get(
						[
							'invoice.modal.details.section.title',
							`invoice.modal.details.section.${type}.title`,
							`invoice.modal.details.${invoice?.type}.section.title`,
							`invoice.modal.details.${invoice?.type}.section.${type}.title`,
						],
						undefined,
						{ invoice: invoice },
					)}
				</Title>
				<List size="lg">
					{fields.map(({ key, value }) => {
						const hasError = (invoice?.errors ?? []).some(
							({ path }) => path === key,
						);

						return (
							<ListItem
								key={key}
								className={cn({
									'asteria--state-error': hasError,
								})}
							>
								<ListCell>
									<Text size="sm">
										{TranslationService.get(
											[
												'invoice.modal.details.section.item.label',
												`invoice.modal.details.section.item.${key}.label`,
												`invoice.modal.details.${invoice?.type}.section.item.label`,
												`invoice.modal.details.${invoice?.type}.section.item.${key}.label`,
											],
											undefined,
											{ invoice: invoice },
										)}
									</Text>
								</ListCell>
								<ListCell>
									<InvoiceDetailsCellInfo
										field={key}
										value={value}
										invoice={invoice}
										onAction={onAction}
									/>
								</ListCell>
							</ListItem>
						);
					})}
				</List>
			</div>
		);
	},
);

InvoiceDetailsModalSection.propTypes = {
	invoice: PropTypes.object,
	onAction: PropTypes.func,
	fields: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
		}),
	),
	type: PropTypes.string,
};

export default InvoiceDetailsModalSection;
