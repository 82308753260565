import React from 'react';

import PropTypes from 'prop-types';

import { Text, Title } from '@asteria/component-core/typography';

import { Action as BaseAction } from '@asteria/component-actionbar';

import { Translation } from '@asteria/language';

const ActionError = React.memo(function ActionError(props) {
	const { id, loading, icon, data, onClick, className } = props;

	return (
		<BaseAction
			variant="error"
			icon={icon}
			onClick={onClick}
			loading={loading}
			className={className}
			title={
				<Translation
					translationKey="actions.action.error.title"
					translationOptions={{
						postfix: {
							id: id,
							error: data?.errors?.[0]?.code,
							multi:
								data?.data?.errors?.length > 1 ||
								data?.errors?.length > 1,
						},
						data: data,
					}}
					Component={Title}
					size="md"
				/>
			}
			content={
				<Translation
					translationKey="actions.action.error.content"
					translationOptions={{
						postfix: {
							id: id,
							error: data?.errors?.[0]?.code,
							multi:
								data?.data?.errors?.length > 1 ||
								data?.errors?.length > 1,
						},
						data: data,
					}}
					Component={Text}
				/>
			}
		/>
	);
});

ActionError.propTypes = {
	id: PropTypes.string,
	loading: PropTypes.bool,
	icon: PropTypes.string,
	data: PropTypes.object,
	onClick: PropTypes.func,
	className: PropTypes.string,
};

export default ActionError;
