import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';
import Group from '@asteria/component-core/group';
import { isPossibleToClick } from '@asteria/component-core/utils';

import { Checkbox } from '@asteria/component-form';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

/**
 * @typedef PropsItem
 * @property { unknown } client
 * @property { (event: { value: string }) => void } onChange
 */

/** @type { React.FC<PropsItem> } */
const SearchClientItem = React.memo(function SearchClientItem(props) {
	const { client, onChange } = props;

	const dispatch = useDispatch();

	const ref = React.useRef(null);

	const clientId = client?._id ?? client?.id;

	const selected = useSelector(
		(store) =>
			!!InvoiceStore.selectors.filters(store, {
				type: 'CLIENT',
				value: clientId,
			}).length,
	);

	const onClick = React.useCallback(
		(event) => {
			if (!isPossibleToClick(event, ref.current)) {
				return;
			}

			return onChange?.({ value: clientId });
		},
		[clientId, onChange],
	);

	const onRedirect = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.ClientOverview,
				data: { _id: clientId },
			}),
		);
	}, [clientId, dispatch]);

	return (
		<DropdownItem
			prefix={
				<Group
					verticalAlign="center"
					horizontalAlign="center"
					className="pointer-events-none"
				>
					<Checkbox uncontrolled checked={selected} />
				</Group>
			}
			postfix={
				<Group verticalAlign="center" horizontalAlign="center">
					<Button
						icon="chevron-right"
						size="sm"
						onClick={onRedirect}
					/>
				</Group>
			}
			onClick={onClick}
			ref={ref}
		>
			{client?.name}
		</DropdownItem>
	);
});

SearchClientItem.propTypes = {
	client: PropTypes.object,
	onChange: PropTypes.func,
};

export function useAvailableIDs(type, data) {
	if (type === 'invoices') {
		return (data ?? []).map(
			(invoice) =>
				invoice?.clientId ??
				invoice?.client?._id ??
				invoice?.client?.id,
		);
	}

	if (type === 'prints') {
		return (data ?? [])
			.flatMap((print) => print?.invoices ?? [])
			.map(
				(invoice) =>
					invoice?.clientId ??
					invoice?.client?._id ??
					invoice?.client?.id,
			);
	}

	return [];
}

/** @type { React.FC<import('./types').BaseProps> } */
const SearchClients = React.memo(function SearchClients(props) {
	const { className, type, data } = props;

	const dispatch = useDispatch();

	const IDs = useAvailableIDs(type, data);

	const clients = useSelector(
		(store) => AppStore.selectors.clients(store, { id: IDs }),
		isEqual,
	);
	const filters = useSelector(
		(store) => InvoiceStore.selectors.filters(store, { type: 'CLIENT' }),
		isEqual,
	);

	const handleChange = React.useCallback(
		(event) => {
			dispatch(
				InvoiceStore.filter({ type: 'CLIENT', value: event.value }),
			);
		},
		[dispatch],
	);

	const openListModal = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: 'CLIENT_LIST',
				skipValidation: true,
			}),
		);
	}, [dispatch]);

	const ToggleProps = React.useMemo(
		() => ({
			variant: 'select',
			label: TranslationService.get(
				[
					'invoices.search.clients.placeholder',
					filters.length
						? 'invoices.search.clients.value.placeholder'
						: null,
				],
				undefined,
				{ filters: filters },
			),
			icon: 'chevron-down',
			iconPosition: 'last',
			className: 'asteria-component__invoice-search__clients',
		}),
		[filters],
	);

	if (clients.length < 2) {
		return null;
	}

	return (
		<div
			className={cn(
				'asteria-component__invoice-search-part',
				'asteria--type-clients',
				className,
			)}
		>
			<Dropdown toggle={ToggleProps} scroll>
				{clients.map((client) => (
					<SearchClientItem
						key={client?._id ?? client?.id}
						client={client}
						onChange={handleChange}
					/>
				))}

				<DropdownItem
					className="asteria--variant-link"
					onClick={openListModal}
				>
					<Button
						variant="link"
						label={TranslationService.get(
							'dropdown.clients.item.link.label',
						)}
					/>
				</DropdownItem>
			</Dropdown>
		</div>
	);
});

SearchClients.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	data: PropTypes.arrayOf(PropTypes.object),
};

export default SearchClients;
