import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Icon from '@asteria/component-core/icon';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Chip from '@asteria/component-chip';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import ServerError from '../../../server-error';
import { useInvoice } from '../../hooks';

import RemoveButton from './remove';
import InvoiceDetailsModalSection from './sections';

import '../styles.scss';

const InvoiceDetailsModalContent = React.memo(
	function InvoiceDetailsModalContent(props) {
		const { onClose, onAction, onSubmit, _id } = props;

		const dispatch = useDispatch();
		const queryClient = useQueryClient();

		const invoice = useInvoice({ id: _id, onSubmit, onAction });

		const clientId = invoice?.clientId;
		const clientName = useSelector(
			(store) => AppStore.selectors.client(store, clientId)?.name,
			(a, b) => isEqual(a, b),
		);

		const isExcluded = (invoice?.services ?? []).some(
			({ status }) => status === 'DISCARD',
		);

		const isSent = (invoice?.services ?? []).some(
			({ status }) => status === 'SENT',
		);

		const isCompleted = (invoice?.services ?? []).some(
			({ status }) => status === 'COMPLETED',
		);

		const isIgnored = (invoice?.services ?? []).some(
			({ status }) => status === 'DISCARD',
		);

		const onClientClick = React.useCallback(() => {
			dispatch(
				ModalStore.open({
					type: ModalStore.MODAL_WINDOWS.ClientOverview,
					data: { _id: clientId },
				}),
			);
		}, [clientId, dispatch]);

		const handleSubmit = React.useCallback(
			(action, data) => {
				if (action === 'invoice:remove') {
					onClose?.();
				}

				return onSubmit?.(action, data);
			},
			[onClose, onSubmit],
		);

		const onEditClick = React.useCallback(() => {
			dispatch(
				ModalStore.open({
					type: ModalStore.MODAL_WINDOWS.InvoiceEdit,
					data: { id: _id },
				}),
			);
		}, [_id, dispatch]);

		const detailsFields = React.useMemo(
			() => [
				{ key: 'number' },
				{ key: 'dates.sent' },
				{ key: 'dates.due' },
				{
					key: 'currency',
					value: (
						<Chip
							flag={invoice?.sums?.original?.currency}
							size="sm"
							variant="simple"
							label={invoice?.sums?.original?.currency}
						/>
					),
				},
				{
					key: 'client',
					value: (
						<Button
							variant="link"
							size="sm"
							label={clientName}
							onClick={onClientClick}
						/>
					),
				},
				{ key: 'message' },
			],
			[clientName, invoice?.sums?.original?.currency, onClientClick],
		);

		const handleAction = React.useCallback(
			(action, data) => {
				if (action === 'server:error:action') {
					onClose?.(null, { size: Infinity });
				}

				return onAction?.(action, data);
			},
			[onAction, onClose],
		);

		const printValidateMutation = useMutation({
			mutationFn: async () =>
				onSubmit?.('print:validate', {
					id:
						invoice?.invoiceLayout ??
						invoice?.invoiceLayoutDetails?._id ??
						invoice?.invoiceLayoutDetails?.id,
				}),
			onSuccess: async () => {
				await queryClient.invalidateQueries({
					predicate: ({ queryKey }) =>
						queryKey.includes(
							invoice?.invoiceLayout ??
								invoice?.invoiceLayoutDetails?._id ??
								invoice?.invoiceLayoutDetails?.id,
						) || queryKey.includes('prints'),
				});
			},
		});

		const ignoreMutation = useMutation({
			mutationFn: async () =>
				onSubmit?.('invoice:ignore', {
					id: invoice?._id ?? invoice?.id,
				}),
			onSuccess: async () => {
				await queryClient.invalidateQueries({
					predicate: ({ queryKey }) =>
						queryKey.includes(invoice?._id ?? invoice?.id),
				});

				return printValidateMutation.mutateAsync();
			},
		});

		const revertMutation = useMutation({
			mutationFn: async () =>
				onSubmit?.('invoice:revert', {
					id: invoice?._id ?? invoice?.id,
				}),
			onSuccess: async () => {
				await queryClient.invalidateQueries({
					predicate: ({ queryKey }) =>
						queryKey.includes(invoice?._id ?? invoice?.id),
				});

				return printValidateMutation.mutateAsync();
			},
		});

		return (
			<Wrapper scroll>
				<Header onClose={onClose}>
					{TranslationService.get(
						[
							'invoice.modal.details.title',
							`invoice.modal.details.${invoice?.type}.title`,
						],
						undefined,
						{ invoice: invoice },
					)}
				</Header>
				<Content scroll>
					{invoice?.errors?.length ? (
						<ServerError
							className="mb-6"
							type="invoice"
							errors={invoice?.errors}
							onAction={handleAction}
							onSubmit={handleSubmit}
							contact={(invoice?.errors ?? []).some(
								({ path }) => !path,
							)}
							extra={{ invoice }}
						/>
					) : null}

					<TextGroup
						className={cn('relative', {
							'asteria--state-error': invoice?.errors?.length,
						})}
					>
						<Translation
							translationKey="invoice.modal.details.placeholder.label"
							translationOptions={{
								postfix: { type: invoice?.type },
								data: { invoice: invoice },
							}}
							Component={Text}
							className="absolute top-2 left-2 p-2 bg-white border border-solid border-border-normal pointer-events-none"
						/>
						<Translation
							translationKey={[
								'invoice.modal.details.placeholder.before',
								`invoice.modal.details.${invoice?.type}.placeholder.before`,
							]}
							translationOptions={{
								postfix: { type: invoice?.type },
								data: { invoice: invoice },
							}}
							size="sm"
							Component={Text}
						/>
						<div
							className={cn(
								'flex flex-row gap-2 items-center',
								'asteria-component__invoice-modal-placeholder',
							)}
						>
							<Translation
								translationKey={[
									'invoice.modal.details.placeholder.title',
									`invoice.modal.details.${invoice?.type}.placeholder.title`,
								]}
								translationOptions={{
									postfix: { type: invoice?.type },
									data: { invoice: invoice },
								}}
								Component={Title}
							/>
							{invoice?.errors?.length ? (
								<Icon icon="warning" />
							) : null}
						</div>
						<Translation
							translationKey={[
								'invoice.modal.details.placeholder.after',
								`invoice.modal.details.${invoice?.type}.placeholder.after`,
							]}
							translationOptions={{
								postfix: { type: invoice?.type },
								data: { invoice: invoice },
							}}
							size="sm"
							Component={Text}
						/>

						{invoice?.invoiceLayoutDetails?.pdfUri ? (
							<Button
								variant="link"
								label={TranslationService.get(
									[
										'invoice.modal.details.placeholder.invoice.pdf.label',
									],
									undefined,
									{ invoice: invoice },
								)}
								icon="external"
								iconSize="md"
								href={TranslationService.get(
									'page.invoices.pending.table.open',
									undefined,
									{
										uri: invoice?.invoiceLayoutDetails
											?.pdfUri,
									},
								)}
								target="__blank"
							/>
						) : null}
					</TextGroup>
					<div className="asteria-component__invoice-modal__content">
						<InvoiceDetailsModalSection
							type="details"
							invoice={invoice}
							fields={detailsFields}
							onAction={handleAction}
						/>
					</div>

					{isExcluded ? (
						<RemoveButton
							onSubmit={handleSubmit}
							onAction={handleAction}
							id={_id}
						/>
					) : null}
				</Content>
				<Footer>
					<FooterSection position="first">
						<Button
							variant="tertiary"
							label={TranslationService.get([
								'button.close',
								'action.close',
								'invoice.modal.details.close',
							])}
							onClick={onClose}
						/>
						{isIgnored ? (
							<Button
								variant="link"
								label={TranslationService.getV2(
									['invoice.modal.details.action'],
									{ postfix: { type: 'revert' } },
								)}
								onClick={revertMutation.mutate}
								disabled={revertMutation.isLoading}
								loading={revertMutation.isLoading}
							/>
						) : (
							<Button
								variant="link"
								label={TranslationService.getV2(
									['invoice.modal.details.action'],
									{ postfix: { type: 'ignore' } },
								)}
								onClick={ignoreMutation.mutate}
								disabled={ignoreMutation.isLoading}
								loading={ignoreMutation.isLoading}
							/>
						)}
					</FooterSection>
					<FeatureFlag feature="invoice-details-edit">
						{!(isSent || isCompleted) ? (
							<FooterSection position="last">
								<Button
									variant="secondary"
									label={TranslationService.get([
										'button.edit',
										'action.edit',
										'invoice.modal.details.edit',
									])}
									onClick={onEditClick}
								/>
							</FooterSection>
						) : null}
					</FeatureFlag>
				</Footer>
			</Wrapper>
		);
	},
);

InvoiceDetailsModalContent.propTypes = {
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	_id: PropTypes.string,
};

export default InvoiceDetailsModalContent;
