import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { isEqual } from 'lodash-es';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { parseDate } from '@asteria/utils-funcs/normalize';

/**
 * @param { { status: string }[] } data
 */
export function usePrintsStatus($data) {
	const data = $data ?? [];

	if (data.every(({ status }) => ['PROCESSED', 'IGNORED'].includes(status))) {
		return 'SUCCESS';
	}

	if (data.some(({ status }) => status === 'ERROR')) {
		const hasValidError = data.some(({ invoices }) =>
			invoices.every((invoice) => invoice?.errors?.length > 0),
		);

		return hasValidError ? 'ERROR' : 'WARNING';
	}

	return 'PENDING';
}

/**
 * @template TValue
 * @param { TValue[] } data
 * @param { boolean } enabled
 * @returns { TValue[] }
 */
export function usePrintsFilters(data, enabled = true) {
	const filters = useSelector(
		(store) =>
			InvoiceStore.selectors.filters(store).reduce(
				(acc, { type, value }) => ({
					...acc,
					[type]: [].concat(acc?.[type] ?? []).concat(value),
				}),
				{},
			),
		isEqual,
	);

	const search = useSelector(InvoiceStore.selectors.search, isEqual);

	return useMemo(() => {
		if (!enabled) {
			return data;
		}

		return (data ?? []).filter((print) => {
			let valid = true;

			if (search) {
				valid = [
					print?.status,
					print?.createdAt,
					print?.id,
					...(print?.invoices ?? []).flatMap((invoice) => [
						invoice?.meta?.invoiceNumber,
						invoice?.sums?.original?.currency,
						invoice?.sums?.original?.total,
					]),
				].some((value) =>
					value
						?.toString?.()
						?.toLowerCase?.()
						?.includes?.(search?.toString?.()?.toLowerCase?.()),
				);
			}

			if (!valid) {
				return valid;
			}

			for (const type in filters) {
				const values = filters[type];

				if (type === 'SERVICE:STATUS') {
					valid = values.some((status) => {
						if (status === 'ERROR' || status === 'WARNING') {
							const invoices = print?.invoices ?? [];
							const isError = invoices.every(
								(invoice) => invoice?.errors?.length,
							);

							return (
								print.status === 'ERROR' &&
								((status === 'ERROR' && isError) ||
									(status === 'WARNING' && !isError))
							);
						}

						return print.status === status;
					});
				}

				if (type === 'CLIENT') {
					valid = values.some((id) =>
						(print?.invoices ?? []).some(
							(invoice) => invoice?.clientId === id,
						),
					);
				}

				if (type === 'CURRENCY') {
					valid = values.some((currency) =>
						(print?.invoices ?? []).some(
							(invoice) =>
								invoice?.sums?.original?.currency === currency,
						),
					);
				}

				if (type === 'DATE:SENT') {
					valid = values.some((value) => {
						const createdAt = parseDate(print?.createdAt);

						const startDate = parseDate(value?.startDate);
						const endDate = parseDate(value?.endDate);

						if (startDate && endDate) {
							return (
								(isSameDay(createdAt, startDate) ||
									isAfter(createdAt, startDate)) &&
								(isSameDay(createdAt, endDate) ||
									isBefore(createdAt, endDate))
							);
						}

						return (
							isSameDay(createdAt, startDate) ||
							isAfter(createdAt, startDate)
						);
					});
				}

				if (!valid) {
					break;
				}
			}

			return valid;
		});
	}, [data, enabled, filters, search]);
}

export function usePrintsQuery({ onSubmit, timestamp }) {
	return useQuery({
		queryKey: ['invoices', 'table', 'prints', { timestamp }],
		queryFn: async () =>
			onSubmit?.('invoice-layout:list', {
				skipDispatch: true,
				skipInvalidate: true,

				pageFilters: { first: 500 },
				fields: `
					_id id createdAt updatedAt status pdfUri
					invoices {
						_id id createdAt updatedAt
						meta { invoiceNumber clientNumber }
						sums { original { total currency } }
						errors { code message path }
						discard: services(filters: { status: ["DISCARD"] }) {
							id serviceId status createdAt updatedAt
							invoices { invoiceId status errors }
						}
					}
				`,
				filters: {
					status: [
						'ERROR',
						'IGNORED',
						'LOADING',
						'PENDING',
						'PROCESSED',
						'UNREAD',
					],
					startDate: timestamp,
				},
			}),

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		placeholderData: [],
	});
}
