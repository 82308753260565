import { DIRECTION } from './constants';

export function getSortingIcon(sorting) {
	if (sorting?.direction === DIRECTION.DESC) {
		return 'chevron-down';
	}

	return 'chevron-up';
}

export function sort(source, target, direction) {
	if (typeof source === 'number' && typeof target === 'number') {
		if (direction === DIRECTION.DESC) {
			return source - target;
		}

		return target - source;
	}

	if (direction === DIRECTION.DESC) {
		return source?.toString?.()?.localeCompare?.(target?.toString?.());
	}

	return target?.toString?.()?.localeCompare?.(source?.toString?.());
}
