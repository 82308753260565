import React from 'react';

import PropTypes from 'prop-types';

import { TableRow } from '@asteria/component-core/table';

import { cn } from '@asteria/utils-funcs/classes';

import Cell from './cell';

/**
 * @typedef Props
 * @property { unknown } value
 * @property { 'sm' | 'md' | 'lg' } size
 * @property { { key: string, sortable?: boolean }[] } columns
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 */

/** @type { React.FC<Props> } */
const Row = React.memo(function Row(props) {
	const { value, onAction, onSubmit, columns, size } = props;

	const status = value?.status;
	const invoices = value?.invoices ?? [];

	const isError =
		status === 'ERROR' &&
		invoices
			.filter((invoice) => !invoice?.discard?.length)
			.every((invoice) => invoice?.errors?.length);

	const isWarning =
		status === 'ERROR' &&
		invoices
			.filter((invoice) => !invoice?.discard?.length)
			.some((invoice) => invoice?.errors?.length);

	return (
		<TableRow
			key={value?._id ?? value?.id}
			className={cn({
				[`asteria--status-${status}`]: status,
				'asteria--status-WARNING': isWarning,
				'asteria--status-ERROR': isError,
			})}
		>
			{columns.map(({ key }) => (
				<Cell
					key={key}
					type={key}
					value={value}
					onAction={onAction}
					onSubmit={onSubmit}
					size={size}
				/>
			))}
		</TableRow>
	);
});

Row.propTypes = {
	value: PropTypes.object,
	columns: PropTypes.arrayOf(PropTypes.object),
	size: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default Row;
