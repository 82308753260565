import React from 'react';
import ReactDOM from 'react-dom';

import { Route } from 'react-router-dom';

import PropTypes from 'prop-types';

import store, { reset } from '@asteria/datalayer';

import BaseWidget, { AuthValidate, ErrorBoundary } from '@asteria/widget-base';

import * as DATALAYER from './datalayer';
import './groove.live';
import Layout from './layout';
import Routing from './routes';

const SiteTitles = {
	payex: {
		sv: 'Fakturaportalen',
		en: 'Invoice portal',
		da: 'Fakturaportaler',
		no: 'Fakturaportaler',
		fi: 'Laskuportaali',
	},
};

const AsteriaWidget = React.memo(function AsteriaWidget(props) {
	const { partnerId, auth } = props;

	React.useLayoutEffect(() => {
		const languageCode = localStorage.getItem('asteriaLanguage') ?? 'sv';
		document.title =
			SiteTitles?.['payex']?.[languageCode] ?? 'Fakturaportalen';
	}, [partnerId]);

	const routes = React.useMemo(
		() => (
			<Route
				path="/"
				element={
					<AuthValidate
						otherwise={
							<Layout
								debug={!!localStorage.getItem('AsteriaDebug')}
								auth
							/>
						}
					>
						<Layout
							debug={!!localStorage.getItem('AsteriaDebug')}
						/>
					</AuthValidate>
				}
				errorElement={<ErrorBoundary />}
			>
				<Route
					index
					element={<Routing auth={auth} partnerId={partnerId} />}
				/>
				<Route
					path="*"
					element={<Routing auth={auth} partnerId={partnerId} />}
				/>
			</Route>
		),
		[auth, partnerId],
	);

	const routing = React.useMemo(
		() => ({ type: 'browser', routes: routes }),
		[routes],
	);

	return <BaseWidget {...props} routing={routing} datalayer={DATALAYER} />;
});

AsteriaWidget.displayName = 'AsteriaWidget';

AsteriaWidget.propTypes = {
	accessToken: PropTypes.string,
	partnerId: PropTypes.string,
	languageCode: PropTypes.string,
	analytics: PropTypes.bool,
	demo: PropTypes.bool,
	theme: PropTypes.string,
	callback: PropTypes.func,
	children: PropTypes.node,

	datalayer: PropTypes.shape({
		fetchExtra: PropTypes.func,
		loader: PropTypes.bool,
	}),
	routing: PropTypes.shape({
		type: PropTypes.oneOf(['memory', 'browser', 'hash']),
	}),
	auth: PropTypes.shape({ logo: PropTypes.node, homepage: PropTypes.bool }),
};

const create = async (
	element,
	{
		accessToken,
		partnerId,
		languageCode = 'sv',
		demo = false,
		analytics = true,
		callback,
		theme,
		environment = 'production',
		routing,
		datalayer,
	} = {},
) => {
	ReactDOM.render(
		<AsteriaWidget
			accessToken={accessToken}
			languageCode={languageCode}
			demo={demo}
			analytics={analytics}
			partnerId={partnerId}
			callback={callback}
			theme={theme}
			environment={environment}
			routing={routing}
			datalayer={datalayer}
		/>,
		element,
	);

	return true;
};

const cleanup = (element) => {
	reset(store.dispatch);

	ReactDOM.unmountComponentAtNode(element);
};

export default AsteriaWidget;
export { AsteriaWidget, cleanup, create };
