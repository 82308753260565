import React from 'react';

import PropTypes from 'prop-types';

import { stateClasses } from '@asteria/component-core/utils';

import { cn } from '@asteria/utils-funcs/classes';

import ActionError from './error';
import ActionIntegration from './integration';
import ActionLink from './link';

const Action = React.memo(function Action(props) {
	const {
		className: $className,
		id,
		variant,
		type,
		disabled,
		data,
		path,
		external,
		onAction,
	} = props;

	const onClick = React.useCallback(
		(event, options) => {
			if (variant === 'link') {
				if (external) {
					window.open(path, '_blank').focus();

					return;
				}

				if (path) {
					return onAction?.('go', path);
				} else {
					return onAction?.(id);
				}
			}

			if (variant === 'integration') {
				return onAction?.('go', '/onboarding');
			}

			if (variant === 'error') {
				if (options?.type === 'fix') {
					return onAction?.('action:fix', data);
				}

				return onAction?.('action:error', data);
			}
		},
		[variant, external, path, onAction, id, data],
	);

	const className = cn(
		{ [`asteria-action--id-${id}`]: id },
		{ [`asteria-action--type-${type}`]: type },
		stateClasses({ disabled: disabled }),
		$className,
	);

	if (variant === 'integration') {
		return (
			<ActionIntegration
				{...props}
				className={className}
				onClick={onClick}
			/>
		);
	}

	if (variant === 'error') {
		return (
			<ActionError {...props} className={className} onClick={onClick} />
		);
	}

	return <ActionLink {...props} className={className} onClick={onClick} />;
});

Action.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	variant: PropTypes.oneOf(['link', 'integration']),
	type: PropTypes.string,
	disabled: PropTypes.bool,
	path: PropTypes.string,
	external: PropTypes.bool,
	data: PropTypes.object,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default Action;
