import React, { useCallback } from 'react';

import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';
import { Footer, FooterSection } from '@asteria/component-core/wrapper';

import Feedback from '@asteria/component-feedback';
import { Checkbox } from '@asteria/component-form';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { Action } from '../../../../components/Actions';
import { useCompanyVersionQuery } from '../../../../components/CompanyVersion';
import ReviewTable from '../../../../components/Invoices/Table/ReviewTable';

import DiscardButton from './discard';
import ResponseError from './error';

import '../styles.scss';

const PageContent = (props) => {
	const { onAction, loading, onFormSubmit, error } = props;

	const [agreed, setAgreement] = React.useState(false);

	const isPxR = useCompanyVersionQuery({ eq: 2 });
	const isLendingReceivable = useSelector(
		(store) =>
			!['not-receivable', 'Inte fakturabelåning'].includes(
				AppStore.selectors.company(store)?.service?.data?.lending,
			),
	);

	const invoices = useSelector(InvoiceStore.selectors.selected, (a, b) =>
		isEqual(a, b),
	);

	// const clients = useSelector((store) =>
	// 	AppStore.selectors.clients(store, {
	// 		id: Array.from(new Set(invoices.map(({ clientId }) => clientId))),
	// 		filters: [{ type: 'DELIVERY:MISSING' }],
	// 	}),
	// );

	const companyService = useSelector((store) =>
		AppStore.selectors
			.company(store)
			?.service?.data?.service?.toUpperCase?.(),
	);

	const { getValues, setValue } = useFormContext();

	const goToInvoices = useCallback(() => {
		onAction?.('go', '/invoices');
	}, [onAction]);

	const onAbort = useCallback(() => {
		onAction?.('invoices:cancel');
		onAction?.('go', '/');
	}, [onAction]);

	const handleAction = useCallback(
		(action, data) => {
			if (action === 'invoice-review-submit') {
				return onFormSubmit(getValues());
			}

			if (action === 'invoice-set-sell') {
				const form = getValues();

				for (const invoiceId in form) {
					if (form[invoiceId]?.factoringOperation) {
						setValue(
							[invoiceId, 'factoringOperation'].join('.'),
							data,
						);
					}
				}

				return;
			}

			return onAction?.(action, data);
		},
		[onAction, onFormSubmit, getValues, setValue],
	);

	const onSupportRequest = React.useCallback(
		() => onAction?.('go', '/support'),
		[onAction],
	);

	const fields = React.useMemo(
		() =>
			[
				{ name: 'invoice-number', tooltip: true },
				{ name: 'client-number', tooltip: true },
				{ name: 'client-name' },
				!isPxR && isLendingReceivable
					? { name: 'invoice-sell', sortable: false }
					: null,
				companyService === 'INVOICE'
					? { name: 'client-delivery' }
					: null,
				{ name: 'invoice-date-sent' },
				{ name: 'invoice-date-due' },
				{ name: 'currency' },
				{ name: 'total' },
				{ name: 'tax' },
				{
					name: 'custom-action',
					render: (props) => <DiscardButton {...props} />,
				},
				{ name: 'details' },
			].filter(Boolean),
		[companyService, isLendingReceivable, isPxR],
	);

	const onAgreementChange = React.useCallback(({ checked }) => {
		setAgreement(checked);
	}, []);

	return (
		<>
			<div className="asteria-component__actions">
				<Action
					id="invoice-review-submit"
					variant="link"
					onAction={handleAction}
					loading={loading}
					disabled={!agreed || loading}
				/>
			</div>
			<Feedback
				className="asteria-view__introduction__feedback"
				onAction={onAction}
				feedbackKey="invoice-select-feedback"
				description={TranslationService.get(
					'invoice.select.feedback.description',
				)}
				minText={TranslationService.get('invoice.select.feedback.min')}
				maxText={TranslationService.get('invoice.select.feedback.max')}
				values={[1, 2, 3, 4, 5]}
				size="lg"
				validate
				type="invoice-select"
				onSupportRequest={onSupportRequest}
				nodismiss
			/>
			<TextGroup className="mb-4">
				<Title size="page-title">
					{TranslationService.get([
						`page.invoices.title`,
						`page.invoices.review.title`,
					])}
				</Title>
				<Text size="lg">
					{TranslationService.get([
						`page.invoices.content`,
						`page.invoices.review.content`,
					])}
				</Text>
			</TextGroup>

			<div key="content" className="asteria-page__wrapper">
				<div className="asteria-page__placeholder">
					<Group
						direction="horizontal"
						verticalAlign="center"
						horizontalAlign="center"
					>
						<Icon icon="document" />
						<Title size="sm" align="center">
							{TranslationService.get(
								[
									'page.invoices.placeholder.title',
									'page.invoices.review.placeholder.title',
									`page.invoices.review.page.placeholder.title`,
								],
								null,
								{ invoices: invoices },
							)}
						</Title>
					</Group>
					<Text align="center">
						{TranslationService.get(
							[
								'page.invoices.placeholder.subtext',
								'page.invoices.review.placeholder.subtext',
								`page.invoices.review.page.placeholder.subtext`,
							],
							null,
							{ invoices: invoices },
						)}
					</Text>
					<Text align="center">
						{TranslationService.get(
							[
								'page.invoices.placeholder.content',
								'page.invoices.review.placeholder.content',
								`page.invoices.review.page.placeholder.content`,
							],
							null,
							{ invoices: invoices },
						)}
					</Text>
					{/* <Button
						variant="link"
						label={TranslationService.get(
							[
								'page.invoices.placeholder.link',
								'page.invoices.review.placeholder.link',
								`page.invoices.review.page.placeholder.link`,
							],
							null,
							{ invoices: invoices },
						)}
					/> */}
				</div>

				<ResponseError error={error} />

				<div
					className={cn(
						'p-8',
						'asteria-page__invoices_review__content',
					)}
				>
					<Title size="xxs" align="left">
						{TranslationService.get(
							[
								'page.invoices.review.title',
								'page.invoices.review.page.title',
							],
							null,
							{ invoices: invoices },
						)}
					</Title>
					<ReviewTable
						invoices={invoices}
						onAction={handleAction}
						fields={fields}
					/>

					<Footer className="asteria-page__invoices_review__actions">
						<FooterSection position="first">
							<Checkbox
								uncontrolled
								label={TranslationService.get([
									'invoices.review.agreement.label',
								])}
								analyticsKey="invoices.review.agreement"
								checked={agreed}
								onChange={onAgreementChange}
							/>
						</FooterSection>
						<FooterSection position="last">
							<Button
								variant="tertiary"
								label={TranslationService.get([
									'button.cancel',
									'action.cancel',
									'invoices.review.button.cancel',
								])}
								disabled={loading}
								onClick={onAbort}
								analyticsKey="invoices.review.button.cancel"
							/>
							<Button
								variant="secondary"
								label={TranslationService.get([
									'button.edit',
									'action.edit',
									'invoices.review.button.edit',
								])}
								disabled={loading}
								onClick={goToInvoices}
								analyticsKey="invoices.review.button.discard"
							/>
							<Button
								variant="primary"
								label={TranslationService.get([
									'button.approve',
									'action.approve',
									'invoices.review.button.approve',
								])}
								disabled={!agreed || loading}
								// disabled={
								// 	loading ||
								// 	(clients.length &&
								// 		companyService === 'INVOICE')
								// }
								loading={loading}
								type="submit"
								analyticsKey="invoices.review.button.approve"
							/>
						</FooterSection>
					</Footer>
				</div>
			</div>
		</>
	);
};

PageContent.displayName = 'PageContent';

PageContent.propTypes = {
	onAction: PropTypes.func,
	loading: PropTypes.bool,
	onFormSubmit: PropTypes.func,

	error: PropTypes.object,
};

export default PageContent;
