import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useQueries } from '@tanstack/react-query';
import { compareAsc, parseISO } from 'date-fns';
import { merge } from 'lodash-es';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { useQueryDoneCallback } from '@asteria/utils-hooks/useQueryDoneCallback';

import * as ClientsAPI from './api/clients';
import * as InvoiceLayoutAPI from './api/invoice-layouts';
import * as InvoiceAPI from './api/invoices';

function getPostfixes(bank) {
	const banks = [].concat(bank).filter(Boolean);

	if (!banks.length) {
		return [];
	}

	const postfixes = [];

	for (const bank of banks) {
		if (typeof bank === 'string') {
			postfixes.push(bank.toLowerCase());
		}

		if (typeof bank === 'object') {
			const value = bank?.value;
			const parent = bank?.parent;

			postfixes.push(...getPostfixes(parent), ...getPostfixes(value));
		}
	}

	return postfixes;
}

export function onUserLoad(user) {
	TranslationService.updateGlobals({ user });
}

export function onCompanyLoad(company) {
	TranslationService.updateGlobals({ company });

	const service = Array.from(company?.services ?? [])
		.sort(({ updatedAt: a }, { updatedAt: b }) =>
			compareAsc(
				a ? parseISO(a) : new Date(),
				b ? parseISO(b) : new Date(),
			),
		)
		.reduce(
			(acc, object) => ({
				...acc,
				...object,
				data: merge({}, acc?.data, object?.data),
			}),
			{},
		);

	if (service?.data?.bank) {
		TranslationService.postfix.reset();

		const postfixes = getPostfixes(service.data.bank);

		const last = postfixes.slice(-1)[0];

		TranslationService.updateGlobals({
			bank: TranslationService.get([last, `bank.${last}`], last),
		});

		for (let index = 0; index < postfixes.length; index += 1) {
			const key = postfixes.slice(0, index + 1).join('.');

			TranslationService.postfix.set(`provider.${key}`, true);
		}
	}

	TranslationService.postfix.set(
		`service.version.v${service?.data?.version ?? 1}`,
		true,
	);
}

export function useExtraRequest({ partnerId, accessToken }) {
	const dispatch = useDispatch();

	const loggedIn = useSelector(
		(store) => AppStore.selectors.auth(store)?.loggedIn,
	);

	const [batchQuery, layoutQuery, clientQuery, countryQuery] = useQueries({
		queries: [
			{
				queryKey: [
					'widget',
					'extra',
					partnerId ?? 'NONE',
					accessToken ?? 'NONE',
					{ loggedIn },
					'batches',
				],
				queryFn: async () =>
					InvoiceAPI.batches({
						accessToken: accessToken,
						dispatch: dispatch,
						serviceId: partnerId,
					}),
				enabled: !!loggedIn,
			},
			{
				queryKey: [
					'widget',
					'extra',
					partnerId ?? 'NONE',
					accessToken ?? 'NONE',
					{ loggedIn },
					'layouts',
				],
				queryFn: async () =>
					InvoiceLayoutAPI.fetch({
						accessToken: accessToken,
						dispatch: dispatch,
						filters: { status: ['PENDING', 'LOADING', 'ERROR'] },
					}),
				enabled: !!loggedIn,
			},
			{
				queryKey: [
					'widget',
					'extra',
					partnerId ?? 'NONE',
					accessToken ?? 'NONE',
					{ loggedIn },
					'clients',
				],
				queryFn: async () =>
					ClientsAPI.fetch({
						accessToken: accessToken,
						dispatch: dispatch,
					}),
				enabled: !!loggedIn,
			},
			{
				queryKey: [
					'widget',
					'extra',
					partnerId ?? 'NONE',
					accessToken ?? 'NONE',
					{ loggedIn },
					'countries',
				],
				queryFn: async () =>
					InvoiceAPI.invoiceCountries({
						accessToken: accessToken,
						dispatch: dispatch,
						serviceId: partnerId,
					}),
				enabled: !!loggedIn,
			},
		],
	});

	const isBatchQueryDone = useQueryDoneCallback(batchQuery, null, !loggedIn);

	const isLayoutQueryDone = useQueryDoneCallback(
		layoutQuery,
		null,
		!loggedIn,
	);

	const isClientQueryDone = useQueryDoneCallback(
		clientQuery,
		null,
		!loggedIn,
	);

	const isCountryQueryDone = useQueryDoneCallback(
		countryQuery,
		null,
		!loggedIn,
	);

	const isReady =
		isBatchQueryDone &&
		isLayoutQueryDone &&
		isClientQueryDone &&
		isCountryQueryDone;

	return React.useMemo(() => isReady, [isReady]);
}

export const loadingOnRefresh = true;
