export const DIRECTION = {
	DESC: 'DESC',
	ASC: 'ASC',
};

export const COLUMN = {
	id: 'var(--width-column-id, var(--width-column-base))',
	createdAt: 'var(--width-column-createdAt, var(--width-column-base))',
	status: 'var(--width-column-status, var(--width-column-base))',
	invoices: 'var(--width-column-invoices, var(--width-column-base))',
	message: 'var(--width-column-message, var(--width-column-flexible))',
	actions: 'var(--width-column-actions, var(--width-column-base))',
};

export const COLUMNS = [
	{ key: 'id', sortable: true },
	{ key: 'createdAt', sortable: true },
	{ key: 'status', sortable: true },
	{ key: 'invoices', sortable: false },
	{ key: 'message', sortable: false },
	{ key: 'actions', sortable: false },
];
