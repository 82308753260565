import React, { useRef } from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import Select, { Option } from '@asteria/component-form/select';
import Settings from '@asteria/component-settings';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { findScrollingParent } from '@asteria/utils-funcs/node';

import { ImportantActions } from '../../components/Actions';
import CompanyVersion, {
	useCompanyVersionQuery,
} from '../../components/CompanyVersion';
import Support from '../../components/Support';
import LayoutContext from '../../layout/context';

import './styles.scss';

const PageSettingsExtra = () => {
	const hasCompanyVersionTwo = useCompanyVersionQuery({ gt: 2 });

	const isLendingReceivable = useSelector(
		(store) =>
			!['not-receivable', 'Inte fakturabelåning'].includes(
				AppStore.selectors.company(store)?.service?.data?.lending,
			),
	);

	return (
		<>
			{isLendingReceivable || !hasCompanyVersionTwo ? (
				<CompanyVersion eq={1}>
					<Select
						name="user.service.data.invoice-sell"
						label={TranslationService.get([
							`invoices.table.header.cell.invoice-sell`,
							`settings.invoice-cell.label`,
						])}
					>
						<Option value="SELL">
							{TranslationService.get([
								'invoice.sell.option1',
								'settings.invoice-sell.option.option1',
							])}
						</Option>
						<Option value="NOSELL_1">
							{TranslationService.get([
								'invoice.sell.option2',
								'settings.invoice-sell.option.option2',
							])}
						</Option>
						<Option value="NOSELL_2">
							{TranslationService.get([
								'invoice.sell.option3',
								'settings.invoice-sell.option.option3',
							])}
						</Option>
						<Option value="NOSELL_3">
							{TranslationService.get([
								'invoice.sell.option4',
								'settings.invoice-sell.option.option4',
							])}
						</Option>
						<Option value="NOSELL_4">
							{TranslationService.get([
								'invoice.sell.option5',
								'settings.invoice-sell.option.option5',
							])}
						</Option>
						<Option value="NOSELL_5">
							{TranslationService.get([
								'invoice.sell.option6',
								'settings.invoice-sell.option.option6',
							])}
						</Option>
						<Option value="NOSELL_6">
							{TranslationService.get([
								'invoice.sell.option7',
								'settings.invoice-sell.option.option7',
							])}
						</Option>
					</Select>
				</CompanyVersion>
			) : null}
		</>
	);
};

const EXTRA = {
	user: <PageSettingsExtra />,
};

const PageSettings = (props) => {
	const { className } = props;

	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const service = useSelector(
		(store) => AppStore.selectors.company(store)?.service,
		(a, b) => isEqual(a, b),
	);

	const serviceId = service?._id ?? service?.id;
	const invoiceSell = service?.data?.['invoice-sell'];

	const ref = useRef(null);

	React.useLayoutEffect(() => {
		const node = findScrollingParent(ref.current);

		if (node) {
			node.scroll({ behavior: 'smooth', top: 0 });
		}
	}, []);

	const defaultValues = React.useMemo(
		() => ({
			user: {
				service: {
					serviceId: serviceId,
					data: {
						settingsOpenedAt: new Date().toISOString(),
						'invoice-sell': invoiceSell,
					},
				},
			},
		}),
		[invoiceSell, serviceId],
	);

	return (
		<div
			ref={ref}
			className={cn('asteria-page', 'asteria-page__settings', className)}
		>
			<div className="mb-4">
				<ImportantActions onAction={onAction} onSubmit={onSubmit} />
			</div>

			<div className="asteria-page__wrapper">
				<TextGroup>
					<Title size="page-title">
						{TranslationService.get('page.settings.title')}
					</Title>
					<Text size="lg">
						{TranslationService.get(['page.settings.content'])}
					</Text>
				</TextGroup>

				<Settings
					mode="user"
					onAction={onAction}
					onSubmit={onSubmit}
					extra={EXTRA}
					defaultValues={defaultValues}
				/>
			</div>
			<Support onAction={onAction} />
		</div>
	);
};

PageSettings.displayName = 'PageSettings';

PageSettings.propTypes = { className: PropTypes.string };

export default PageSettings;
