import React from 'react';

import PropTypes from 'prop-types';

import Chip from '@asteria/component-chip';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { TABS } from './constants';

/**
 * @typedef Props
 * @property { string } className
 * @property { string } type
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 * @property { { completed?: boolean, error?: boolean, warning?: boolean } } flags
 */

/** @type { React.FC<Props> } */
const Switcher = React.memo(function Switcher(props) {
	const { className, type, onAction, flags } = props;

	const onPendingClick = React.useCallback(
		() => onAction?.('print:dialog:switch', TABS.PENDING),
		[onAction],
	);

	const onCompletedClick = React.useCallback(
		() => onAction?.('print:dialog:switch', TABS.SUCCESS),
		[onAction],
	);

	const onErrorClick = React.useCallback(
		() => onAction?.('print:dialog:switch', TABS.ERROR),
		[onAction],
	);

	const onWarningClick = React.useCallback(
		() => onAction?.('print:dialog:switch', TABS.WARNING),
		[onAction],
	);

	return (
		<div
			className={cn(
				'border-border-normal flex gap-2 p-2 bg-white overflow-x-auto',
				'asteria-component__chip-group',
				className,
			)}
		>
			<Chip
				size="sm"
				active={type === TABS.PENDING}
				label={TranslationService.getV2(['print.dialog.switch.label'], {
					postfix: { type: TABS.PENDING },
				})}
				onClick={onPendingClick}
			/>
			{flags?.completed ? (
				<Chip
					size="sm"
					active={type === TABS.SUCCESS}
					label={TranslationService.getV2(
						['print.dialog.switch.label'],
						{
							postfix: { type: TABS.SUCCESS },
						},
					)}
					onClick={onCompletedClick}
				/>
			) : null}

			{flags?.error ? (
				<Chip
					size="sm"
					active={type === TABS.ERROR}
					label={TranslationService.getV2(
						['print.dialog.switch.label'],
						{
							postfix: { type: TABS.ERROR },
						},
					)}
					onClick={onErrorClick}
				/>
			) : null}
			{flags?.warning ? (
				<Chip
					size="sm"
					active={type === TABS.WARNING}
					label={TranslationService.getV2(
						['print.dialog.switch.label'],
						{
							postfix: { type: TABS.WARNING },
						},
					)}
					onClick={onWarningClick}
				/>
			) : null}
		</div>
	);
});

Switcher.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	flags: PropTypes.object,
};

export default Switcher;
